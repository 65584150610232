<template>
	<div class="section">
		<div class="header">
			<span class="title">桌位详情</span>
			<div @click="handleCloseOrder" class="close">
				<i class="el-icon-close"></i>
			</div>
		</div>
		<div class="main" v-if="sideBarType == 'detail-1'">
			<div class="head" v-if="selectedTables.length == 1">
				<div class="table-list-item" v-for="t in selectedTables" :key="t.id">
					<div
						class="arrow-left"
						:style="{ borderRightColor: statusColor.backgroundColor }"
					></div>
					<div
						class="arrow-middle flex"
						:style="{ background: statusColor.backgroundColor }"
					>
						<div class="flex flex-1 flex-center">
							<div
								class="dot mr-10"
								:style="{ background: statusColor.color }"
							></div>
							<div
								:style="{ color: statusColor.color }"
								class="font-bold font-size-14 table-name"
							>
								{{ t.table.areaName ? t.table.areaName + '-' : ''
								}}{{ t.table.tableName }}
							</div>
							<div
								class="dot ml-10"
								:style="{ background: statusColor.color }"
							></div>
							<div :style="{ color: statusColor.color }" class="ml-15">
								{{ statusName }}
							</div>
						</div>
						<div class="flex flex-align-center">
							<span @click="closeSelectTable(t)" class="close-table">
								<i
									class="el-icon-close"
									:style="{ color: statusColor.color }"
								></i>
							</span>
						</div>
					</div>
					<div
						class="arrow-right"
						:style="{ borderLeftColor: statusColor.backgroundColor }"
					></div>
				</div>
			</div>
			<div class="page-sub-title mt-20" v-if="selectedTables.length > 1">
				连桌信息
			</div>
			<div
				class="head flex flex-wrap pl-20 pr-20"
				v-if="selectedTables.length > 1"
			>
				<div
					v-for="t in orderData.orderTables"
					:key="t.tableId"
					class="flex50 mb-10"
				>
					<el-checkbox
						:label="
							t.tableAreaName
								? t.tableAreaName + '-' + t.tableName
								: t.tableName
						"
						:disabled="tableData.orderStatus != t.orderStatus"
						@change="handleChooseTable(t.tableId)"
						:value="selectedTablesIdList.includes(t.tableId)"
					></el-checkbox>
				</div>
			</div>
			<div>
				<div class="minConsume" v-if="minConsumeStr">
					<span class="icon-minConsume"></span>
					<div
						class="flex-1 flex"
						id="consume-container"
						:class="{ 'consume-up': arrow, 'consume-down': !arrow }"
					>
						<span id="consume-txt"> {{ '最低消费:' + minConsumeStr }}</span>
					</div>

					<i
						v-if="showArrow"
						:class="{ 'el-icon-arrow-up': arrow, 'el-icon-arrow-down': !arrow }"
						class="mr-10"
						style="margin-top: 3px"
						@click="arrow = !arrow"
					></i>
				</div>
			</div>
			<div class="info">
				<div class="flex flex-between">
					<div class="page-sub-title">客户信息</div>
					<div
						@click="handleEdit()"
						class="cursor-point edit"
						v-if="!isLastDate"
					>
						<i class="el-icon el-icon-edit"></i>
						编辑
					</div>
				</div>
				<div class="form-item mb-5" v-if="orderData.customerPhone">
					<span class="form-label ml-15">客户号码:</span>
					<span class="form-value">{{ orderData.customerPhone }}</span>
				</div>
				<div class="form-item mb-5">
					<span class="form-label ml-15">客户姓名:</span>
					<span class="form-value"
						>{{ orderData.customerName }}
						<span v-if="orderData.customerGender > -1">{{
							orderData.customerGender == 1 ? '先生' : '女士'
						}}</span>
					</span>
				</div>
				<div class="page-sub-title mt-20">订单信息</div>

				<div class="flex flex-between">
					<div class="form-item flex-1">
						<span class="form-label ml-15">到店时间:</span>

						<span class="form-value"
							>{{ tableData.arriveTime
							}}<el-tooltip
								class="ml-5"
								effect="dark"
								style="color: #555"
								v-if="selectedTables.length > 1"
								placement="bottom"
							>
								<span slot="content">
									此处仅显示最后勾选桌位的到店时间信息；修改时，不会同步修改批次下其他桌位到店时间信息
								</span>
								<i class="el-icon-warning-outline"></i> </el-tooltip
						></span>
					</div>
					<div class="form-item flex-1">
						<span class="form-label"> 预订类型: </span>
						<span class="form-value">{{ tableData.resvTypeName }}</span>
					</div>
				</div>
				<div class="flex flex-between">
					<div class="form-item flex-1">
						<span class="form-label ml-15">用餐人数:</span>
						<span class="form-value">
							<span v-if="selectedTables.length == 1">{{
								tableData.actualNum > 0
									? tableData.actualNum
									: tableData.resvNum
							}}</span>
							<el-tooltip class="item" effect="dark" placement="bottom" v-else>
								<span slot="content">
									<div
										v-for="table in orderData.orderTables"
										:key="table.tableId"
									>
										{{ table.tableName }}：{{ table.resvNum }}人
									</div>
								</span>
								<span>{{ tableData.resvNum }}</span>
							</el-tooltip>
						</span>
					</div>
					<div class="form-item flex-1">
						<span class="form-label">发送消息:</span>
						<span class="form-value">{{ smsMap[orderData.isSendSms] }}</span>
					</div>
				</div>
				<div class="flex flex-between">
					<div class="form-item flex-1" v-if="orderData.depositAmount">
						<span class="form-label ml-15">定金金额:</span>
						<span class="form-value">{{
							orderData.depositAmount ? orderData.depositAmount + '元' : ''
						}}</span>
					</div>
					<div class="form-item flex-1" v-if="orderData.payTypeName">
						<span class="form-label">支付方式:</span>
						<span class="form-value">{{ orderData.payTypeName }}</span>
					</div>
				</div>

				<div class="form-item" v-if="orderData.depositNo">
					<span class="form-label ml-15">收据编号:</span>
					<span class="form-value">{{ orderData.depositNo }}</span>
				</div>

				<div class="form-item" v-if="tableData.salerFollowName">
					<span class="form-label ml-15">销售跟进人:</span>
					<span class="form-value">{{ tableData.salerFollowName }}</span>
				</div>
				<div class="form-item" v-if="tableData.remark">
					<span class="form-label ml-15">预订备注:</span>
					<div class="form-value">
						{{ tableData.remark }}
					</div>
				</div>

				<div class="form-item" slot="container" v-if="relatedInfo.length > 0">
					<span class="form-label ml-8 mr-10" style="width: 80px"
						>关联宴会厅:</span
					>
					<div class="form-value">
						<div v-for="(item, index) in relatedInfo" :key="index">
							【{{ item.relatedMeetingMealName }}】{{
								item.relatedMeetingTableName
							}}
						</div>
					</div>
				</div>

				<div class="page-sub-title mt-20">其他信息</div>
				<div class="form-item" v-if="tableData.createTime">
					<span class="form-label ml-15">创建时间:</span>
					<span class="form-value">{{ tableData.createTime }}</span>
				</div>
				<div class="form-item" v-if="tableData.creatorName">
					<span class="form-label ml-15">创建人:</span>
					<span class="form-value">
						{{ tableData.creatorName }}
					</span>
				</div>
			</div>
		</div>
		<div class="main" v-if="sideBarType == 'detail-2'">
			<div class="head1">
				<div class="date">
					{{ resvDate }}
				</div>

				<div class="table">
					<el-tag v-for="tag in selectedTables" :key="tag.id" class="mr-10 tag">
						{{ tag.table.areaName ? tag.table.areaName + '-' : '' }}
						{{ tag.table.tableName }}
					</el-tag>
				</div>
			</div>
			<div>
				<div class="minConsume" v-if="minConsumeStr">
					<span class="icon-minConsume"></span>
					<div
						class="flex-1 flex"
						id="consume-container"
						:class="{ 'consume-up': arrow, 'consume-down': !arrow }"
					>
						<span id="consume-txt"> {{ '最低消费:' + minConsumeStr }}</span>
					</div>

					<i
						v-if="showArrow"
						:class="{ 'el-icon-arrow-up': arrow, 'el-icon-arrow-down': !arrow }"
						class="mr-10"
						style="margin-top: 3px"
						@click="arrow = !arrow"
					></i>
				</div>
			</div>
			<div class="info">
				<div class="page-sub-title">客户信息</div>
				<div class="form-item mb-16 ml-5">
					<span class="form-label"
						><span class="red mr-5">*</span>客户号码
					</span>
					<el-autocomplete
						v-model.trim="form.customerPhone"
						class="form-content flex-1"
						size="small"
						:trigger-on-focus="false"
						:fetch-suggestions="mohuSelectPhone"
						@select="handleSelectPhone"
						@input="filterInput('customerPhone', 'num')"
					>
						<template #suffix>{{
							form.customerPhone.length + '/' + 11
						}}</template>
					</el-autocomplete>
				</div>
				<div class="form-item mb-16 ml-5">
					<span class="form-label">
						<span class="red mr-5">*</span>客户姓名
					</span>
					<el-input
						class="form-content flex-1"
						v-model="form.customerName"
						size="small"
						:maxlength="6"
						@input="filterInput('customerName')"
						show-word-limit
					></el-input>
				</div>
				<div class="form-item mb-16 ml-5">
					<span class="form-label">
						<span class="red mr-5">*</span>客户性别
					</span>
					<div class="form-content flex-1">
						<el-radio v-model="form.customerGender" :label="1">先生</el-radio>
						<el-radio v-model="form.customerGender" :label="0">女士</el-radio>
					</div>
				</div>
				<div class="page-sub-title mt-30">其他信息</div>

				<div class="form-item mb-16 ml-5">
					<span class="form-label">
						<span class="red mr-5" v-if="mustArriveTime">*</span>到店时间
					</span>
					<el-select
						class="form-content flex-1"
						size="small"
						placeholder="请选择到店时间"
						v-model="form.arriveTime"
						:clearable="!mustArriveTime"
					>
						<el-option
							v-for="o in arriveTimeList"
							:key="o.timeDate"
							:label="o.time"
							:value="o.time"
						></el-option>
					</el-select>
				</div>
				<div class="form-item mb-16 ml-5">
					<span class="form-label">
						<span class="red mr-5">*</span>用餐人数
					</span>
					<div class="form-content flex-1">
						<el-input
							v-model="form.peopleNum"
							size="small"
							:maxlength="4"
							@input="filterInput('peopleNum', 'num')"
							placeholder="请输入用餐人数"
						></el-input>
					</div>
				</div>
				<div class="form-item mb-16 ml-5">
					<span class="form-label">
						<span class="red mr-5">*</span>预订类型
					</span>
					<el-select
						class="form-content flex-1"
						clearable=""
						size="small"
						placeholder="请选择预订类型"
						v-model="form.resvTypeId"
					>
						<el-option
							v-for="o in resvTypeList"
							:key="o.resvTypeId"
							:label="o.resvTypeName"
							:value="o.resvTypeId"
						></el-option>
					</el-select>
				</div>
				<div class="form-item mb-16 ml-15">
					<span class="form-label">定金</span>
					<div class="form-content flex-1 flex-end width-100 flex mr-10">
						<el-switch v-model="form.depositFlag"> </el-switch>
					</div>
				</div>
				<div v-if="form.depositFlag">
					<div class="form-item mb-16 ml-5">
						<span class="form-label">
							<span class="red">*</span> 定金金额
						</span>
						<el-input
							class="form-content flex-1"
							v-model="form.depositAmount"
							size="small"
							type="number"
							onKeypress="return(/[\d.]/.test(String.fromCharCode(event.keyCode)))"
							@input="filterInput('depositAmount', 'num')"
						></el-input>
					</div>
					<div class="form-item mb-16 ml-5">
						<span class="form-label">
							<span class="red">*</span>
							支付方式
						</span>
						<el-select
							class="form-content flex-1"
							size="small"
							placeholder="请选择支付方式"
							v-model="form.payTypeId"
						>
							<el-option
								v-for="o in payTypeList"
								:key="o.id"
								:label="o.payTypeName"
								:value="o.id"
							></el-option>
						</el-select>
					</div>
					<div class="form-item mb-16 ml-5">
						<span class="form-label">
							<span class="red">*</span> 收据编号
						</span>
						<el-input
							class="form-content flex-1"
							v-model="form.depositNo"
							size="small"
							@input="filterInput('depositNo', 'No')"
						></el-input>
					</div>
				</div>
				<div class="form-item mb-16 ml-15">
					<span class="form-label"> 销售跟进人 </span>
					<el-select
						class="form-content flex-1"
						v-model="form.salerFollowId"
						filterable
						placeholder="请选择"
						size="small"
					>
						<el-option
							v-for="item in userListFilter"
							:key="item.id"
							:label="item.realName"
							v-show="!item.hide"
							:value="item.id"
						>
						</el-option>
					</el-select>
				</div>
				<div class="form-item mb-16 ml-15">
					<span class="form-label"> 预订备注 </span>
					<div class="form-content flex-1">
						<el-input
							type="textarea"
							:rows="3"
							placeholder="请输入内容"
							v-model="form.remark"
							:maxlength="255"
							resize="none"
							show-word-limit
						>
						</el-input>
					</div>
				</div>
			</div>
		</div>
		<div class="footer" v-if="sideBarType == 'detail-2'">
			<div class="content">
				<el-button size="small" class="btn" @click="handleCloseOrder"
					>取消</el-button
				>
				<el-button size="small" class="btn" type="primary" @click="updateOrder"
					>确定</el-button
				>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex'
import { tableSub } from '@/config/table'
import dateFormat from '@/utils/dateFormat'
import { get_userInfoList, get_allPayTypeList } from '@/api/config'
import { update_order, mohu_search_phone } from '@/api/resv'
import relatedTable from '@/components/relatedTable'
const form = {
	orderId: '',
	orderNo: '',
	hotelId: '',
	customerName: '',
	customerSurname: '',
	customerPhone: '',
	customerGender: 1,
	peopleNum: 1,
	arriveTime: '',
	resvTypeId: '',
	remark: '',
	salerFollowId: '',
	createClient: '',
	creatorId: '',
	isSendSms: 1,
	salerFollowName: '',
	depositFlag: false,
	depositAmount: '',
	depositNo: '',
	payTypeId: '',
}
import { getFirstName } from '@/utils/getFirstName'
import { validateNull, isValidateMobile } from '@/utils/validate'
import { statusColor } from '../../../../const'
export default {
	name: 'detail',
	components: {
		relatedTable,
	},
	data() {
		return {
			form: this.$deepClone(form),
			timeout: null,
			userList: [],
			smsMap: {
				1: '系统短信',
				2: '微信',
				0: '不发送',
			},
			relatedList: [],
			payTypeList: [],
			arrow: false,
			showArrow: false,
		}
	},
	mounted() {
		this.init()
	},
	computed: {
		...mapState(['userInfo', 'createClient']),
		...mapState('resvInfo', [
			'selectedTables',
			'mealTypeDate',
			'resvTypeList',
			'mealTypeId',
			'sideBarType',
			'currentTime',
		]),
		...mapGetters('resvInfo', [
			'mealIdMap',
			'orderData',
			'tableData',
			'zeroHotelOtherConfigMap',
		]),
		mustArriveTime() {
			return this.zeroHotelOtherConfigMap['ZERO_ARRIVE_TIME_CONFIG'] == 1
				? true
				: false
		},
		selectedTablesIdList() {
			return this.selectedTables.map((item) => {
				return item.id
			})
		},
		minConsumeStr() {
			let str = []
			this.selectedTables.map((o) => {
				if (o.table.minConsume) {
					str.push(`【${o.table.tableName}】${o.table.minConsume}元`)
				}
			})

			return str.join(';')
		},
		userListFilter() {
			if (this.form.salerFollowId && this.form.salerFollowId != -1) {
				console.log('sss', this.form)
				const index = this.userList.findIndex(
					(item) => item.id == this.form.salerFollowId
				)
				const list = this.userList.concat()
				if (index < 0) {
					list.push({
						hide: true,
						id: this.form.salerFollowId,
						realName: this.form.salerFollowName,
					})
				}
				return list
			} else {
				return this.userList
			}
		},
		statusColor() {
			console.log('this.tableData', this.tableData, this.orderData)
			switch (this.tableData.orderStatus) {
				case 1:
					return statusColor['book']
				case 2:
					return statusColor['sitting']
				case 5:
					return statusColor['lock']
				default:
					return statusColor['default']
			}
		},
		statusName() {
			switch (this.tableData.orderStatus) {
				case 1:
					return '已预订'
				case 2:
					return '已入座'
				case 5:
					return '已锁台'
				default:
					return '空闲'
			}
		},
		resvDate() {
			return dateFormat.formatBookDate(this.mealTypeDate)
		},
		isLastDate() {
			const mealTypeDate = this.$moment(this.mealTypeDate).format('YYYYMMDD')
			const twoDaysAgo = this.$moment(this.currentTime)
				.subtract(2, 'd')
				.format('YYYYMMDD')
			console.log(mealTypeDate, twoDaysAgo)
			return mealTypeDate <= twoDaysAgo
		},
		arriveTimeList() {
			const list = this.mealIdMap[this.mealTypeId]
				? this.mealIdMap[this.mealTypeId].timeList
				: []
			return list.filter((v) => {
				const nowTime = this.$moment(this.currentTime).format(
					'YYYY-MM-DD HH:mm'
				)
				const mealTypeDate = this.$moment(this.mealTypeDate).format(
					'YYYY-MM-DD HH:mm'
				)
				if (this.$moment(mealTypeDate).isAfter(nowTime)) {
					return v
				} else if (this.$moment(v.timeDate).isAfter(nowTime)) {
					return v
				}
			})
		},
		relatedInfo() {
			let list = []
			if (this.orderData && this.orderData.orderTables) {
				this.orderData.orderTables.map((item) => {
					if (
						item.relatedMeetingMeal != '-1' &&
						item.relatedMeetingTable != '-1'
					) {
						const idx = list.findIndex(
							(o) =>
								o.relatedMeetingMeal == item.relatedMeetingMeal &&
								o.relatedMeetingTable == item.relatedMeetingTable
						)
						if (idx == -1) {
							list.push({
								relatedMeetingMeal: item.relatedMeetingMeal,
								relatedMeetingMealName: item.relatedMeetingMealName,
								relatedMeetingTable: item.relatedMeetingTable,
								relatedMeetingTableName: item.relatedMeetingTableName,
								tableId: item.tableId,
							})
						}
					}
				})
			}

			let relatedMap = {}
			list.map((item) => {
				if (
					!relatedMap[
						item['relatedMeetingMeal'] + '_' + item['relatedMeetingMealName']
					]
				) {
					relatedMap[
						item['relatedMeetingMeal'] + '_' + item['relatedMeetingMealName']
					] = item.relatedMeetingTableName
				} else {
					const ele =
						relatedMap[
							item['relatedMeetingMeal'] + '_' + item['relatedMeetingMealName']
						].split('，')

					if (!ele.includes(item.relatedMeetingTableName)) {
						relatedMap[
							item['relatedMeetingMeal'] + '_' + item['relatedMeetingMealName']
						] += '，' + item.relatedMeetingTableName
					}
				}
			})

			let res = []
			Object.keys(relatedMap).map((item) => {
				const keyList = item.split('_')
				const relatedMeetingMealName = keyList[1]
				res.push({
					relatedMeetingMealName: relatedMeetingMealName,
					relatedMeetingTableName: relatedMap[item],
				})
			})

			return res
		},
	},
	watch: {
		arriveTimeList: {
			handler(newValue) {
				if (newValue && newValue.length > 0) {
					this.form.mealTypeId = this.mealTypeId
					if (this.mustArriveTime) {
						this.form.arriveTime = newValue[0].time
					}
				}
			},
			immediate: true,
		},
		minConsumeStr: {
			handler(newValue) {
				if (newValue) {
					this.$nextTick(() => {
						const dom = document
							.getElementById('consume-txt')
							.getBoundingClientRect()
						if (dom && dom.width) {
							if (this.minScreen && dom.width >= 258) {
								this.showArrow = true
							} else if (!this.minScreen && dom.width >= 331) {
								this.showArrow = true
							} else {
								this.showArrow = false
							}
						} else {
							this.showArrow = false
						}
					})
				}
			},
			immediate: true,
		},
	},
	methods: {
		...mapMutations('resvInfo', ['SET_SIDEBAR_TYPE']),
		init() {
			get_allPayTypeList({
				hotelId: this.userInfo.fkOrgId,
				searchKey: '',
			}).then((res) => {
				this.payTypeList = res.startList || []
				if (this.payTypeList.length > 0) {
					this.form.payTypeId = this.payTypeList[0].id
				}
			})

			get_userInfoList({
				hotelId: this.userInfo.fkOrgId,
				searchKey: '',
			}).then((res) => {
				this.userList = res || []
				// this.form.salerFollowId = this.$store.state.userInfo.id
				// this.form.salerFollowName = this.$store.state.userInfo.realName
			})
		},
		handleCloseOrder() {
			if (this.sideBarType == 'detail-1') {
				tableSub.selectTable([])
				this.SET_SIDEBAR_TYPE('idle')
			} else if (this.sideBarType == 'detail-2') {
				this.SET_SIDEBAR_TYPE('detail-1')
			}
		},
		handleChooseTable(id) {
			console.log(id)
			if (id) {
				tableSub.selectTable(id)
			}
		},
		handleEdit() {
			if (this.selectedTables.length > 1) {
				return this.$message.warning('仅支持单桌编辑，请取消勾选多余桌位')
			}

			console.log(':data', this.orderData)
			this.form.customerName = this.orderData.customerName
			this.form.customerPhone = this.orderData.customerPhone
			this.form.customerGender = this.orderData.customerGender
			this.form.isSendSms = this.orderData.isSendSms
			this.form.peopleNum =
				this.tableData.actualNum > 0
					? this.tableData.actualNum
					: this.tableData.resvNum
			this.form.arriveTime = this.tableData.arriveTime
			this.form.resvTypeId = this.tableData.resvTypeId
			this.form.remark = this.tableData.remark
			this.form.salerFollowId =
				this.tableData.salerFollowId == -1 ? '' : this.tableData.salerFollowId
			this.form.createClient = this.tableData.createClient
			this.form.creatorId = this.tableData.creatorId
			this.form.hotelId = this.orderData.hotelId
			this.form.orderId = this.tableData.orderId
			this.form.orderNo = this.tableData.orderNo
			this.form.salerFollowName = this.tableData.salerFollowName
			this.form.depositFlag = this.orderData.depositFlag
			this.form.depositAmount = this.orderData.depositAmount
			this.form.depositNo = this.orderData.depositNo
			this.form.payTypeId = this.orderData.payTypeId
			console.log('this.form.payTypeId', this.form.payTypeId)
			if (this.payTypeList.length > 0 && !this.form.payTypeId) {
				this.form.payTypeId = this.payTypeList[0].id
			}
			if (this.form.payTypeId) {
				const d = this.payTypeList.find(
					(item) => item.id == this.form.payTypeId
				)
				if (!d) {
					this.form.payTypeId = ''
				}
			}
			this.SET_SIDEBAR_TYPE('detail-2')
		},
		filterInput(val, type, cb) {
			console.log(val, type)
			if (this.form[val]) {
				this.form[val] = this.form[val].trim()
				if (type == 'num') {
					this.form[val] = this.$requireDecimalDigit(this.form[val])
				}
				if (val == 'customerPhone') {
					this.form[val] = this.form[val].replace(/\D/g, '')
					this.form[val] = this.form[val].slice(0, 11)
				} else if (type == 'num') {
					this.form[val] = this.form[val].replace(/[^?\d.]/g, '')
				}
			}
			if (cb) {
				try {
					this[cb](this.form[val])
				} catch (error) {}
			}
		},
		mohuSelectPhone(queryString, cb) {
			let results = []
			clearTimeout(this.timeout)
			if (!this.form['customerPhone']) {
				if (cb) {
					cb([])
				}
			} else if (this.form['customerPhone'].length >= 6) {
				this.timeout = setTimeout(() => {
					mohu_search_phone({
						hotelId: this.userInfo.fkOrgId,
						phone: queryString,
					})
						.then((res) => {
							results = res || []
							if (cb) {
								cb(results)
							}
						})
						.catch((err) => {
							if (cb) {
								cb([])
							}
						})
				}, 300)
			} else {
				if (cb) {
					cb([])
				}
			}
		},
		handleSelectPhone(val) {
			console.log(val)
			this.form.customerPhone = val.phone || ''
			this.form.customerName = val.name || ''
			this.form.customerSurname = getFirstName(val.name) || ''
			this.form.customerGender = val.gender
		},
		closeSelectTable(t) {
			tableSub.selectTable(t.id)
		},
		updateOrder() {
			let form = this.$deepClone(this.form)
			console.log(isValidateMobile(form.customerPhone))
			if (!isValidateMobile(form.customerPhone).result) {
				return this.$message.warning(isValidateMobile(form.customerPhone).msg)
			} else if (validateNull(form.customerName)) {
				return this.$message.warning('请输入正确的客户姓名')
			} else if (validateNull(form.customerGender) || form.customerGender < 0) {
				return this.$message.warning('请选择正确的客户性别')
			} else if (validateNull(form.customerGender)) {
				return this.$message.warning('请选择客户性别')
			} else if (validateNull(form.arriveTime) && this.mustArriveTime) {
				return this.$message.warning('请选择到店时间')
			} else if (validateNull(form.peopleNum)) {
				return this.$message.warning('请输入用餐人数')
			} else if (validateNull(form.resvTypeId)) {
				return this.$message.warning('请选择预订类型')
			} else if (form.depositFlag) {
				if (validateNull(form.depositAmount) && !form.depositAmount) {
					return this.$message.warning('定金信息未填写完整')
				}
				if (validateNull(form.depositNo)) {
					return this.$message.warning('定金信息未填写完整')
				}
				if (validateNull(form.payTypeId)) {
					return this.$message.warning('定金信息未填写完整')
				}
			}

			if (form.depositFlag == 0) {
				form.depositAmount = '0'
				form.depositNo = ''
				form.payTypeId = ''
			}

			if (validateNull(form.salerFollowId)) {
				form.salerFollowId = -1
			}
			form.createClient = this.createClient
			form.customerSurname = getFirstName(form.customerName)
			console.log(form)

			update_order(form)
				.then((res) => {
					this.$message.success(res)

					tableSub.selectTable([])
					this.SET_SIDEBAR_TYPE('idle')
				})
				.catch((err) => {
					this.SET_SIDEBAR_TYPE('idle')
					tableSub.selectTable([])
					console.log(err)
				})
		},
	},
}
</script>

<style scoped lang="scss">
.section {
	display: flex;
	flex-direction: column;
	overflow: auto;
	height: 100%;
	.close,
	.close-table {
		position: absolute;
		height: 40px;
		width: 40px;
		top: 0;
		cursor: pointer;
		right: 0;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.header {
		height: 40px;
		border-bottom: 1px solid rgba(51, 51, 51, 0.1);
		flex: 0 0 40px;
		padding: 0 18px;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		position: relative;
		font-size: 16px;
		color: #000;

		.title {
			color: #333;
		}
	}
	.main {
		width: 100%;
		flex: 1 1 auto;
		position: relative;
		padding: 0 10px;
		box-sizing: border-box;
		display: flex;
		overflow: auto;
		flex-direction: column;
		.head {
			.table-list-item {
				height: 40px;
				width: 100%;
				border-radius: 4px;
				position: relative;
				line-height: 40px;
				color: #333;
				font-size: 13px;
				display: flex;
				margin: 12px 0;
				.arrow-left {
					width: 0px;
					height: 0px;
					border-top: 20px solid transparent;
					border-right: 13px solid rgba(51, 51, 51, 0.05);
					border-bottom: 20px solid transparent;
					border-left: 20px solid transparent;
					position: relative;
					/* 利用滤镜filter，添加投影 */
					filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.05));
				}

				.arrow-middle {
					width: 100%;

					background: rgba(51, 51, 51, 0.05);
					position: relative;
					z-index: 1;
					color: #666666;
					text-align: center;
					padding-left: 5px;
					padding-right: 5px;
					.span {
						margin-right: 5px;
					}
					.table-name {
						max-width: 120px;
						line-height: 16px;
						text-align: left;
					}
				}

				.arrow-right {
					width: 0px;
					height: 0px;
					/* margin: 50px auto; */
					border-top: 20px solid transparent;
					border-right: 20px solid transparent;
					border-bottom: 20px solid transparent;
					border-left: 13px solid rgba(51, 51, 51, 0.05);
					/* 利用滤镜filter，添加投影 */
					filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.05));
				}
			}
		}
		.head1 {
			width: 100%;
			background: rgb(244, 249, 255);
			border-radius: 8px;
			.date {
				width: 65%;
				font-size: 16px;
				color: #fff;
				text-align: center;
				background: #409eff;
				margin: 0 auto;
				padding: 10px 20px;
				border-bottom-left-radius: 6px;
				border-bottom-right-radius: 6px;
			}
			.table {
				margin: 10px;
				display: flex;
				justify-content: center;
				.tag {
					margin-bottom: 5px;
					background: #e6edf6;
					border-color: #e6edf6;
					color: #4077b4;
				}
				:deep(.el-tag .el-tag__close:hover) {
					color: #4077b4;
					background: rgba($color: #4077b4, $alpha: 0.1);
				}
			}
		}
		.info {
			margin-top: 20px;
			padding-left: 5px;
			padding-right: 5px;
			.edit {
				color: #409eff;
			}
			.form-item {
				display: flex;
				justify-content: center;
				align-items: flex-start;
				width: 100%;
				.form-label {
					line-height: 32px;
					text-align: right;
					margin-right: 20px;
				}
				.form-value {
					flex: 1;
					line-height: 32px;
				}
				.form-content {
					min-height: 32px;
					line-height: 32px;
					::v-deep .el-input__suffix-inner {
						background: #fff;
						font-size: 12px;
						color: #909399;
						padding: 0 5px;
					}
				}
			}
		}
	}
	.footer {
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 20px 0;
		.content {
			width: 72%;
			display: flex;
			justify-content: space-between;
			align-items: center;
			.btn {
				width: 120px;
			}
		}
	}
}
:deep(.el-textarea__inner) {
	border-radius: 0;
	padding-bottom: 20px;
	&:hover {
		border: 1px solid #409eff;
	}
}
:deep(.el-textarea .el-input__count) {
	width: 92%;
	text-align: right;
	bottom: 1px;
	padding-bottom: 7px;
}

.minConsume {
	display: flex;
	align-items: flex-start;
	background: #fff;
	color: #888;
	padding-left: 12px;
	padding-top: 5px;
	padding-bottom: 5px;
	background: #f7f7f7;
	margin-top: 10px;
}

.consume-down {
	flex-wrap: nowrap;
	height: 20px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	display: inline-block;
}
.consume-up {
	flex-wrap: wrap;
}
#consume-txt {
	font-size: 12px;
}
.icon-minConsume {
	margin-right: 5px;
	margin-top: 2px;
	display: inline-block;
	background: url('../../../../assets/icons/icon-minConsume.png') 100% 100%
		no-repeat;
	background-size: contain;
	width: 16px;
	height: 16px;
}
</style>
