<template>
	<div class="detail-main">
		<div class="flex-1 overflow-hidden">
			<idle-detail v-if="sideBarType == 'idle'"></idle-detail>
			<book v-if="sideBarType == 'book'" :minScreen="minScreen"></book>
			<order-detail v-if="sideBarType.includes('detail')"></order-detail>
			<order-list v-if="sideBarType == 'order'"></order-list>
			<lock v-if="sideBarType == 'lock'"></lock>
			<table-change v-if="sideBarType.includes('table')"></table-change>
			<message v-if="sideBarType == 'message'"></message>
		</div>

		<operation-btn
			:minScreen="minScreen"
			v-if="showOperationBtn"
		></operation-btn>
	</div>
</template>

<script>
import { mapState } from 'vuex'

import IdleDetail from './idle-detail'
import OperationBtn from './operationBtn'
import book from './book'
import OrderDetail from './order-detail.vue'
import OrderList from './order-list.vue'
import lock from './lock.vue'
import tableChange from './table-change.vue'
import message from './message.vue'
export default {
	name: 'detail-main',
	components: {
		IdleDetail,
		OperationBtn,
		OrderList,
		lock,
		book,
		tableChange,
		OrderDetail,
		message
	},
	props: {
		minScreen: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {}
	},
	computed: {
		...mapState('resvInfo', ['sideBarType']),
		showOperationBtn() {
			const notShowList = ['book','order','lock','table-add','table-change','message']
			if (notShowList.includes(this.sideBarType)) {
				return false
			}
			return true
		},
	},
	watch: {},
	methods: {},
	mounted() {},
	beforeDestroy() {},
}
</script>

<style scoped lang="scss">
.detail-main {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
}
</style>
