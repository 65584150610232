import { ajax } from "@/api/request";


export const checkConfigKey = function(data) {
  return ajax({
    method: 'post',
    url: '/sys/v1/checkConfigKey',
    data: data
  });
}



export const  getSocketToken = function() {
  return ajax({
    url: `https://httpws.zhidianfan.com/auth/token`,
    method: 'post',
    params: {
      type: 'get',
      username: 'zeropad',
      password: 'zeropad321'
    }
  })
}

//获取万年历 返回数组
export const get_calendar = function(params) {
  return ajax({
    method: 'get',
    url: '/config/v1/getPerpetualCalendar',
    params
  });
}

export const  setData = function(data) {
  return ajax({
    url: `https://yidingre.zhidianfan.com/api/v1/redis/save`,
    method: 'post',
    data
  })
}
