<template>
	<div>
		<el-dialog
			:visible="show"
			:width="width"
			@close="handleClose"
			:close-on-click-modal="false"
			top="0vh"
			custom-class="center-dialog"
		>
			<div slot="title">
				<span class="font-size-16">{{ title }}</span>
				<span class="select">(已选{{ selectedTables.length || 0 }}桌)</span>
			</div>

			<div class="pay-tips" v-if="type == 'pay'">
				<div>连桌结账时，请输入合计金额</div>
				<div>第一桌结账金额为总金额，其他桌结账金额为0</div>
			</div>

			<div class="content">
				<div class="sit" v-if="type == 'sit' || type == 'sksit'">
					<div class="form-item">
						<span class="form-label" style="width: 105px">
							<span class="red mr-5">*</span> 实际用餐人数:
						</span>
						<div class="form-content flex-1">
							<el-input
								v-model="form.actualNum"
								size="small"
								:maxlength="4"
								:class="{
									highlight: highLight,
								}"
								@input="filterInput('actualNum', 'num', 'handleChangeResvNum')"
								placeholder="请输入用餐人数"
							>
								<template slot="append">人</template>
							</el-input>
						</div>
					</div>
					<div class="form-item">
						<span class="form-label" style="width: 105px"> 当前区域桌位: </span>
						<div class="form-content flex-1">
							<div v-if="selectedTables.length > 1">
								<el-input
									class="mb-10"
									v-model="table.resvNum"
									:maxlength="4"
									size="small"
									@change="reCalcTotalResvNum('actualNum')"
									v-for="table in form.tables"
									:key="table.orderNo"
									:class="{
										highlight1:
											parseInt(table.resvNum) >
											parseInt(tableIdMap[table.tableId].maxPeopleNum),
									}"
								>
									<template slot="prepend"
										>{{
											table.tableAreaName ? table.tableAreaName + ' | ' : ''
										}}
										{{ table.tableName }}</template
									>
									<template slot="append">人</template>
								</el-input>
							</div>
							<div v-else class="singleTable">
								{{ tableStr }}
							</div>
						</div>
					</div>
					<related-table :tableIds="tableIds" v-if="type == 'sksit'">
						<div class="form-item" slot="container" slot-scope="scope">
							<span class="form-label" style="width: 80px">关联宴会厅:</span>
							<div class="form-content flex-1">
								<div v-for="(item, index) in scope.list" :key="index">
									【{{ item.relatedMeetingMealName }}】{{
										item.relatedMeetingTableName
									}}
								</div>
							</div>
						</div>
					</related-table>
				</div>
				<div class="unorder" v-if="type == 'unorder'">
					<div class="form-item">
						<span class="form-label" style="width: 80px">
							<span class="red mr-5">*</span> 退订原因:
						</span>
						<el-select
							class="form-content flex-1"
							size="small"
							placeholder="请选择退订原因"
							v-model="form.unorderReason"
						>
							<el-option
								v-for="(o, index) in unorderList"
								:key="index"
								:label="o"
								:value="o"
							></el-option>
						</el-select>
					</div>
					<div class="form-item" v-if="form.unorderReason == '其他原因'">
						<span class="form-label" style="width: 80px"> </span>
						<div class="form-content flex-1">
							<el-input
								type="textarea"
								:rows="3"
								placeholder="请输入内容"
								v-model="form.unorderOtherReason"
								:maxlength="30"
								show-word-limit
								resize="none"
							>
							</el-input>
						</div>
					</div>
					<div class="sendSms" v-if="orderData.customerPhone">
						<div>给客户发送退订短信:</div>
						<div>
							<el-switch
								v-model="form.isSendSms"
								:active-value="1"
								:inactive-value="0"
							>
							</el-switch>
						</div>
					</div>
				</div>

				<div class="pay" v-if="type == 'pay'">
					<div class="form-item">
						<span class="form-label" style="width: 80px"> 结账桌位: </span>
						<div class="form-content flex-1">
							<div class="singleTable">
								{{ tableStr }}
							</div>
						</div>
					</div>
					<div class="form-item">
						<span class="form-label" style="width: 80px">
							<span class="red mr-5">*</span> 消费金额:
						</span>
						<div class="form-content flex-1">
							<el-input
								v-model="form.payAmount"
								size="small"
								@input="filterInput('payAmount', 'num')"
								placeholder="请输入消费金额"
							>
								<template slot="append">元</template>
							</el-input>
						</div>
					</div>
				</div>
				<div class="lock" v-if="type == 'lock'">
					<div class="form-item">
						<span class="form-label" style="width: 80px"> 已选桌位: </span>
						<div class="form-content flex-1">
							<div class="singleTable">
								{{ tableStr }}
							</div>
						</div>
					</div>
					<div class="form-item">
						<span class="form-label" style="width: 80px">锁台原因:</span>
						<div class="form-content flex-1">
							<el-input
								type="textarea"
								:rows="3"
								placeholder="请输入内容"
								v-model="form.remark"
								:maxlength="20"
								show-word-limit
								resize="none"
							>
							</el-input>
						</div>
					</div>

					<related-table :tableIds="tableIds">
						<div class="form-item" slot="container" slot-scope="scope">
							<span class="form-label" style="width: 80px">关联宴会厅:</span>
							<div class="form-content flex-1">
								<div v-for="(item, index) in scope.list" :key="index">
									【{{ item.relatedMeetingMealName }}】{{
										item.relatedMeetingTableName
									}}
								</div>
							</div>
						</div>
					</related-table>
				</div>
			</div>

			<div slot="footer" class="dialog-footer">
				<el-button @click="handleClose">取 消</el-button>
				<el-button type="primary" @click="handleConfrim">确 定</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex'
import { change_order_status, confirm_order } from '@/api/resv.js'
import { tableSub } from '../../../../config/table'
import relatedTable from '@/components/relatedTable'
import { splitMeal } from '@/utils/splitMeal'
export default {
	components: {
		relatedTable,
	},
	props: {
		title: {
			type: String,
			default: '',
		},
		type: {
			type: String,
			default: '',
		},
		width: {
			type: String,
			default: '400px',
		},
		show: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			form: {},
			originForm: {},
			relatedList: [],
		}
	},
	watch: {
		show(val) {
			if (val) {
				this.init()
			}
		},
	},
	computed: {
		...mapState('resvInfo', [
			'selectedTables',
			'mealTypeId',
			'mealTypeDate',
			'resvTypeList',
		]),
		...mapState(['userInfo', 'createClient']),
		...mapGetters('resvInfo', [
			'mealIdMap',
			'mealTypeNameMap',
			'orderData',
			'tableData',
			'tableIdMap',
		]),
		unorderList() {
			return [
				'不想来了',
				'天气原因影响',
				'预订时间变更',
				'疫情影响',
				'其他原因',
			]
		},
		tableStr() {
			return this.selectedTables
				.map((o) => {
					return (
						(o.table.areaName ? o.table.areaName + '-' : '') + o.table.tableName
					)
				})
				.join()
		},
		highLight() {
			let flag = false
			if (this.form.tables.length == 1) {
				flag =
					this.form.actualNum &&
					parseInt(this.form.actualNum) >
						parseInt(
							this.tableIdMap[this.selectedTables[0].table.id].maxPeopleNum
						)
			} else {
				for (let i = 0; i < this.form.tables.length; i++) {
					const table = this.form.tables[i]
					if (
						parseInt(table.resvNum) >
						parseInt(this.tableIdMap[table.tableId].maxPeopleNum)
					) {
						flag = true
						break
					}
				}
			}

			return flag
		},
		tableIds() {
			return this.selectedTables.map((item) => {
				return item.table.id + ''
			})
		},
	},
	methods: {
		...mapMutations('resvInfo', ['SET_SIDEBAR_TYPE']),
		init() {
			console.log(this.selectedTables)
			const ids = this.selectedTables.map((o) => {
				return o.table.id
			})

			const mealType = splitMeal(this.mealTypeId)
			if (this.type == 'sit') {
				this.form = {
					actualNum:
						this.selectedTables.length > 1
							? this.orderData.resvNum
							: this.tableData.resvNum,
					orderStatus: 2,
					isSendSms: this.orderData.isSendSms,
					tables: this.orderData.orderTables.filter((o) =>
						ids.includes(o.tableId)
					),
				}
			} else if (this.type == 'unorder') {
				this.form = {
					unorderOtherReason: '',
					isSendSms: this.orderData.isSendSms,
					unorderReason: this.unorderList[0],
					orderStatus: 4,
					tables: this.orderData.orderTables.filter((o) =>
						ids.includes(o.tableId)
					),
				}
			} else if (this.type == 'pay') {
				this.form = {
					payAmount: '',
					isSendSms: 0,
					orderStatus: 3,
					tables: this.orderData.orderTables.filter((o) =>
						ids.includes(o.tableId)
					),
				}
			} else if (this.type == 'lock') {
				this.form = {
					customerName: '',
					customerSurname: '',
					customerPhone: '',
					customerGender: -1,
					resvNum: -1,
					resvTypeId: -1,
					remark: '',
					isSendSms: 0,
					orderStatus: 5,
					salerFollowId: -1,

					resvDate: this.mealTypeDate,
					tables: this.selectedTables.map((o) => {
						return {
							tableAreaId: o.table.tableAreaId,
							tableAreaName: o.table.areaName,
							tableId: o.table.id,
							tableName: o.table.tableName,
							resvNum: o.table.resvNum,
						}
					}),
					...mealType,
				}
			} else if (this.type == 'sksit') {
				this.form = {
					customerName: '',
					customerSurname: '',
					customerPhone: '',
					customerGender: -1,
					resvNum: -1,
					resvTypeId:
						this.resvTypeList.length > 0 ? this.resvTypeList[0].resvTypeId : '',
					remark: '',
					isSendSms: 0,
					orderStatus: 2,
					salerFollowId: -1,
					arriveTime: this.$moment().format('HH:mm'),

					resvDate: this.mealTypeDate,
					tables: this.selectedTables.map((o) => {
						return {
							tableAreaId: o.table.tableAreaId,
							tableAreaName: o.table.areaName,
							tableId: o.table.id,
							tableName: o.table.tableName,
							resvNum: o.table.resvNum,
						}
					}),
					...mealType,
				}
			}
			this.form.hotelId = this.userInfo.fkOrgId
			this.form.createClient = this.createClient
			if (!['lock', 'sksit'].includes(this.type)) {
				this.form.orderId = this.tableData.orderId
				this.form.creatorId = this.tableData.creatorId
				this.form.creatorName = this.tableData.creatorName
			} else {
				this.form.creatorId = this.userInfo.id
			}

			this.originForm = this.$deepClone(this.form)
		},
		filterInput(val, type, cb) {
			console.log(val, type)
			if (this.form[val]) {
				this.form[val] = this.form[val].trim()
				if (type == 'num') {
					this.form[val] = this.form[val].replace(/[^\d.]/g, '')
				}
			}
			if (cb) {
				try {
					this[cb](this.form[val])
				} catch (error) {}
			}
		},
		reCalcTotalResvNum(val) {
			this.form[val] = 0
			this.form.tables.map((table) => {
				this.form[val] += parseInt(table.resvNum || 0)
			})
		},
		handleChangeResvNum() {
			const num = Number(this.form.actualNum)
			const tablesNum = this.form.tables.length
			const per = Math.floor(num / tablesNum)
			const yu = num - per * tablesNum
			for (var i = 0; i < tablesNum; i++) {
				if (i < yu) {
					this.$set(this.form.tables, i, {
						...this.form.tables[i],
						resvNum: per + 1,
					})
				} else {
					this.$set(this.form.tables, i, {
						...this.form.tables[i],
						resvNum: per,
					})
				}
			}
		},
		handleClose() {
			if (this.show) {
				this.$emit('update:show', false)
				this.form = this.$deepClone(this.originForm)
			}
			if (this.type) {
				this.$emit('update:type', '')
				this.form = this.$deepClone(this.originForm)
			}
		},
		handleConfrim() {
			let form = this.$deepClone(this.form)
			if (this.type == 'sit') {
				let resvNumSum = 0
				if (this.selectedTables.length == 1) {
					form.tables = this.form.tables.map((item) => {
						resvNumSum += parseInt(this.form.actualNum || 0)
						return {
							orderNo: item.orderNo,
							actualNum: this.form.actualNum,
							tableName: item.tableName,
						}
					})
					if (!form.actualNum) {
						this.$message.warning('请输入实际用餐人数')
						return
					}
					if (parseInt(form.actualNum) < 1) {
						this.$message.warning('请输入实际用餐人数')
						return
					}
				} else {
					form.tables = this.form.tables.map((item) => {
						resvNumSum += parseInt(item.resvNum || 0)
						return {
							orderNo: item.orderNo,
							actualNum: item.resvNum,
							tableName: item.tableName,
						}
					})

					let flag = null
					for (let i = 0; i < form.tables.length; i++) {
						const element = form.tables[i]
						if (!element.actualNum) {
							flag = element.tableName
							break
						}
					}

					if (flag) {
						return this.$message.warning('请输入' + flag + '的用餐人数')
					}
					if (parseInt(form.actualNum) != resvNumSum) {
						this.$message.warning('用餐人数必须等于各桌数人数相加')
						return
					}
				}
			} else if (this.type == 'unorder') {
				if (form.unorderReason == '其他原因') {
					form.unorderReason = form.unorderOtherReason
					delete form.unorderOtherReason
				}
				form.tables = this.form.tables.map((item) => {
					return {
						orderNo: item.orderNo,
					}
				})
				if (!form.unorderReason) {
					this.$message.warning('请输入退订原因')
					return
				}
				if (form.unorderReason == '其他原因' && !form.unorderOtherReason) {
					this.$message.warning('请输入退订原因')
					return
				}
			} else if (this.type == 'pay') {
				form.tables = this.form.tables.map((item) => {
					return {
						orderNo: item.orderNo,
					}
				})
				if (!form.payAmount || parseFloat(form.payAmount) == 0) {
					this.$message.warning('请输入消费金额')
					return
				}
				if (form.payAmount && parseFloat(form.payAmount) > 1000000) {
					this.$message.warning('消费金额超出限制')
					return
				}
				console.log(parseFloat(form.payAmount))
				if (
					parseFloat(form.payAmount) <= 0 ||
					isNaN(parseFloat(form.payAmount))
				) {
					this.$message.warning('请输入正确消费金额')
					return
				}
			} else if (this.type == 'sksit') {
				if (!form.actualNum) {
					this.$message.warning('请输入实际用餐人数')
					return
				}
				if (parseInt(form.actualNum) < 1) {
					this.$message.warning('请输入实际用餐人数')
					return
				}
				form.depositFlag = false
				form.tables = this.form.tables.map((item) => {
					console.log(item)
					return {
						actualNum: item.resvNum,
						resvNum: item.resvNum,
						tableName: item.tableName,
						tableAreaId: item.tableAreaId,
						tableAreaName: item.tableAreaName,
						tableId: item.tableId,
					}
				})
			} else if (this.type == 'lock') {
				if (!this.form.remark) {
					return this.$message.error('请输入锁台原因')
				}
				form.depositFlag = false
			}
			console.log(form)
			if (!['lock', 'sksit'].includes(this.type)) {
				change_order_status(form)
					.then((res) => {
						this.$message.success(res)
						this.SET_SIDEBAR_TYPE('idle')
						tableSub.selectTable([])
				    this.$Bus.$emit('refresh')
						this.handleClose()
					})
					.catch((err) => {
						// this.SET_SIDEBAR_TYPE('idle')
						// tableSub.selectTable([])
						console.log(err)
					})
			} else {
				confirm_order(form)
					.then((res) => {
						this.$message.success('操作成功')

						tableSub.selectTable([])
						this.SET_SIDEBAR_TYPE('idle')
						this.handleClose()
					})
					.catch((err) => {
						this.SET_SIDEBAR_TYPE('idle')
						tableSub.selectTable([])
						console.log(err)
					})
			}
		},
	},
}
</script>

<style lang="scss" >
.center-dialog {
	top: 50% !important;
	transform: translateY(-50%) !important;
}
</style>
<style lang="scss" scoped>
.select {
	color: #a4a4a4;
	margin-left: 5px;
}
.pay-tips {
	text-align: center;
	font-size: 13px;
	color: #828f9a;
	background: #eff5fa;
	padding: 10px 0;
	margin-top: 10px;
}
.content {
	padding: 30px 20px;
	.singleTable {
		background: #f6f6f6;
		margin-bottom: 5px;
		pointer-events: none;
		cursor: not-allowed;
		display: flex;
		align-items: center;
		padding: 0 15px;
		border: 1px solid #e4e4e4;
	}
	.form-item {
		margin-bottom: 16px;
		display: flex;
		justify-content: center;
		align-items: flex-start;
		.form-label {
			line-height: 32px;
			text-align: right;
			margin-right: 20px;
		}
		.form-content {
			min-height: 32px;
			line-height: 32px;
		}
		:deep(.el-input-group__prepend) {
			width: 130px;
			max-width: 130px;
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow: hidden;
			padding: 0px 8px;
			background: #f5f6fb;
			border-color: #ebeef5;
			border-radius: 2px;
			color: #526177;
		}
		:deep(.el-input-group__append) {
			padding: 0 10px;
			background: transparent;
		}
	}
	.sendSms {
		margin-top: 25px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding-left: 25px;
	}
}

:deep(.el-textarea__inner) {
	border-radius: 0;
	padding-bottom: 20px;
	&:hover {
		border: 1px solid #409eff;
	}
}
:deep(.el-textarea .el-input__count) {
	width: 92%;
	text-align: right;
	bottom: 1px;
	padding-bottom: 7px;
}
:deep(.el-dialog__body) {
	padding: 0;
}
:deep(.highlight) {
	position: relative;
	input {
		color: red;
	}
	&::after {
		content: '\e6c9超出容纳人数';
		position: absolute;
		font-family: element-icons;
		color: red;
		right: 40px;
		top: 50%;
		font-size: 14px;
		margin-top: -10px;
	}
}
:deep(.highlight1) {
	input {
		color: red;
	}
}
</style>
