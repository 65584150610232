<template>
	<div>
		<slot
			name="container"
			v-if="relatedInfo.length > 0"
			:list="relatedInfo"
		></slot>
	</div>
</template>

<script>
import { mapState } from 'vuex'
import { get_related_room } from '@/api/resv.js'
import { splitMeal } from '@/utils/splitMeal'
export default {
	data() {
		return {
			relatedList: [],
			relatedInfo: [],
		}
	},
	props: ['tableIds'],
	watch: {
		tableIds: {
			handler(newValue) {
				if (newValue.length > 0) {
					this.getRelatedTable()
				} else {
					this.relatedInfo = []
					this.relatedList = []
				}
			},
			immediate: true,
		},
	},
	computed: {
		...mapState('resvInfo', ['mealTypeDate', 'mealTypeId']),
		...mapState(['userInfo']),
	},
	methods: {
		getRelatedTable() {
			console.log(
				'获取宴会厅信息',
				this.mealTypeDate,
				this.mealTypeId,
				this.tableIds
			)
			let tableIds = this.tableIds
			if (!Array.isArray(this.tableIds)) {
				tableIds = [this.tableIds]
			}
			this.relatedInfo = []
			this.relatedList = []
			const mealType = splitMeal(this.mealTypeId)
			get_related_room({
				hotelId: this.userInfo.fkOrgId,
				resvDate: this.mealTypeDate,
				tableIdList: tableIds,
				...mealType,
			}).then((res) => {
				this.relatedList = res || []
				this.relatedInfo = this.getRelatedInfo()
			})
		},
		getRelatedInfo() {
			let relatedMap = {}
			this.relatedList.map((item) => {
				if (
					!relatedMap[
						item['relatedMeetingMeal'] + '_' + item['relatedMeetingMealName']
					]
				) {
					relatedMap[
						item['relatedMeetingMeal'] + '_' + item['relatedMeetingMealName']
					] = item.relatedMeetingTableName
				} else {
					const ele =
						relatedMap[
							item['relatedMeetingMeal'] + '_' + item['relatedMeetingMealName']
						].split('，')

					if (!ele.includes(item.relatedMeetingTableName)) {
						relatedMap[
							item['relatedMeetingMeal'] + '_' + item['relatedMeetingMealName']
						] += '，' + item.relatedMeetingTableName
					}
				}
			})

			let res = []
			Object.keys(relatedMap).map((item) => {
				const keyList = item.split('_')
				const relatedMeetingMealName = keyList[1]
				res.push({
					relatedMeetingMealName: relatedMeetingMealName,
					relatedMeetingTableName: relatedMap[item],
				})
			})

			return res
		},
	},
}
</script>

<style lang="scss" scoped>
</style>