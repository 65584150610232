import {ajax} from "@/api/request";

// 获取操作日志
export const get_common_operate_logs = function (data) {
    return ajax({
        url: '/log/v1/getCommonOperateLogs',
        method: 'post',
        data: data
    })
}

// 获取登录日志
export const get_login_logs = function (data) {
    return ajax({
        url: '/log/v1/getLoginLogs',
        method: 'post',
        data: data
    })
}

// 记录登录日志
export const save_login_logs = function (data) {
    return ajax({
        url: '/log/v1/saveLoginLog',
        method: 'post',
        data: data
    })
}
