import { ajax } from "@/api/request";

export const get_user_info = function(data) {
  return ajax({
    url: '/user/v1/getUserInfo',
    params: data
  });
}

export const post_updatePwd = function(data) {
  return ajax({
    url: '/user/v1/updatePwd',
    method: 'post',
    params: data
  });
}

//�޸��û�������Ϣ
export const post_updateUserInfo = function(data) {
  return ajax({
    method: 'post',
    url: '/user/v1/updateUserInfo',
    data: data
  });
}

//�������޸�����
export const post_updatePwdByOldPwd = function(data) {
  return ajax({
    method: 'post',
    url: '/user/v1/updatePwdByOldPwd',
    params: data
  });
}

//��ȡʣ���������
export const get_hotelSmsRemain = function(data) {
  return ajax({
    method: 'get',
    url: '/hotel/v1/getHotelSmsRemain',
    params: data
  });
}

//��֤���޸�����
export const post_updatePwdByCode = function(data) {
  return ajax({
    method: 'post',
    url: '/user/v1/updatePwdByCode',
    params: data
  });
}

//��ȡ������֤��
export const get_smsCode = function(data) {
  return ajax({
    method: 'get',
    url: '/sys/v1/getSmsCode',
    params: data
  });
}

//��ȡͼ����֤��
export const get_Captcha = function(data) {
  return ajax({
    method: 'get',
    url: '/sys/v1/getCaptcha',
    params: data
  });
}

//�����б�
export const get_brandAndHotelList = function(params) {
  return ajax({
    method: 'get',
    url: '/brand/v1/getBrandAndHotelList',
    params
  });
}

// ���л������б�
export const get_canLoginHotelList = function(params) {
  return ajax({
    method: 'get',
    url: '/user/v1/getCanLoginHotelList',
    params
  });
}

// ���л������б��Լ���Ӧ��ɫ
export const get_CanLoginHotelListAndRoleId = function(params) {
  return ajax({
    method: 'get',
    url: '/user/v1/getCanLoginHotelListAndRoleId',
    params
  });
}