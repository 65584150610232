<template>
	<div ref="main" class="table-container" @scroll="onScroll">
		<div
			class="area-div"
			v-for="area in tableAreaList"
			:key="area.id"
			v-show="
				(tableAreaId.length > 0 ? tableAreaId.includes(area.id) : true) &&
				areaOrderMap[area.id] &&
				areaOrderMap[area.id].length > 0
			"
			:class="{ 'border-0': showEmpty == area.id }"
		>
			<div
				class="area-title page-sub-title"
				:id="'area-' + area.id"
				ref="area"
				v-if="
					(tableAreaId.length > 0 ? tableAreaId.includes(area.id) : true) &&
					areaOrderMap[area.id] &&
					areaOrderMap[area.id].length > 0
				"
			>
				<span>{{ area.areaName }}</span>
			</div>
			<div
				class="table-list"
				v-show="areaOrderMap[area.id] && areaOrderMap[area.id].length > 0"
			>
				<div
					v-for="table in tableAreaMap[area.id]"
					:key="table.id"
					:id="'table-' + table.id"
					v-show="
						tableStatus
							? Array.isArray(getTableAreaList(area.id)) &&
							  getTableAreaList(area.id).includes(table.id)
							: true
					"
				>
					<table-item
						:id="table.id"
						:table="table"
						:scale="scale"
						:style="{
							width: `${scale * tableWidth}px`,
							height: `${scale * tableWidth}px`,
							margin: `${marginScale}px`,
						}"
					></table-item>
				</div>
			</div>
		</div>
		<div class="empty" v-if="!showEmpty">
			<img src="../../../../assets/img/empty.png" alt=""  />
		</div>
	</div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import TableItem from './table'
export default {
	name: 'tableArea',
	components: {
		TableItem,
	},
	props: {
		clickArea: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			domInfo: {
				width: document.body.offsetWidth,
			},

			areaHeightList: [],
			recordScroll: 0,
		}
	},
	watch: {
		clickArea(newValue) {
			//桌位只有一排甚至更少，滚动不触发scroll事件，适配tab页
			setTimeout(() => {
				console.log('触发')
				if (newValue) {
					this.$emit('updateAreaPosition', newValue)
				}
			}, 200)
		},
	},
	computed: {
		...mapState('resvInfo', ['tableAreaId', 'tableStatus']),
		...mapGetters('resvInfo', [
			'tableAreaMap',
			'tableAreaList',
			'areaOrderMap',
		]),
		tableWidth() {
			return 160
		},
		tableMainWidth() {
			return this.domInfo.width
		},

		marginScale() {
			let width = this.tableMainWidth
			const minMargin = 8 * this.scale
			const tableWidth = this.tableWidth

			let count = Math.floor(width / (this.scale * tableWidth))

			let result = (width - count * this.scale * tableWidth) / (count * 2)

			if (result < minMargin) {
				count -= 1
				result = (width - count * this.scale * tableWidth) / (count * 2)
			}
			return result
		},
		scale() {
			let r = 1
			if (this.tableMainWidth < 900) {
				r = 0.9
			}
		
                        return r
		},
		showEmpty() {
			let empty = ''
			for (let i = 0; i < this.tableAreaList.length; i++) {
				const area = this.tableAreaList[i]
				if (
					(this.tableAreaId.length > 0
						? this.tableAreaId.includes(area.id)
						: true) &&
					this.areaOrderMap[area.id] &&
					this.areaOrderMap[area.id].length > 0
				) {
					empty = area.id
				}
			}
			return empty
		},
	},
	methods: {
		getTableAreaList(areaId) {
			let tableList = []
			if (this.tableStatus && this.areaOrderMap[areaId]) {
				this.areaOrderMap[areaId].map((table) => {
					if (
						this.tableStatus &&
						parseInt(this.tableStatus) == parseInt(table.orderStatus)
					) {
						tableList.push(table.tableId)
					}
					if (this.tableStatus == -1) {
						tableList.push(table.id)
					}
				})
			}
			return tableList
			// return this.tableAreaMap[areaId].filter((table) => {
			// 	return this.tableStatus ? tableList.includes(table.id) : true
			// })
		},

		getAreaDom() {
			this.$nextTick(() => {
				this.areaHeightList = []
				const selectorItems = this.$refs.area
				if (selectorItems.length < 1) {
					return
				}
				for (let i = 0; i < selectorItems.length; i++) {
					const item = selectorItems[i]
					this.areaHeightList.push({
						top: item.offsetTop,
						id: (item.id || '').replace('area-', ''),
					})
				}
				this.areaHeightList.sort((a, b) => {
					return a.top - b.top
				})
			})
		},
		onScroll(e) {
			this.$nextTick(() => {
				let index = -1
				const currentScrollDistance = e.target.scrollTop
				const len = this.areaHeightList.length
				if (len < 1) {
					return
				}
				const firstItem = this.areaHeightList[0]
				const lastItem = this.areaHeightList[len - 1]
				const middleItem = this.areaHeightList[parseInt((len - 1) / 2)]
				if (currentScrollDistance <= firstItem.top) {
					index = 0
				} else if (
					currentScrollDistance > firstItem.top &&
					currentScrollDistance <= middleItem.top
				) {
					for (let i = 0; i <= parseInt((len - 1) / 2); i++) {
						const height = this.areaHeightList[i].top
						if (height >= currentScrollDistance) {
							index = i
							break
						}
					}
				} else if (
					currentScrollDistance > middleItem.top &&
					currentScrollDistance <= lastItem.top
				) {
					for (let i = parseInt((len - 1) / 2); i < len; i++) {
						const height = this.areaHeightList[i].top
						if (height >= currentScrollDistance) {
							index = i
							break
						}
					}
				}

				if (index == -1) {
					console.log(currentScrollDistance, index)
				}

				if (this.clickArea) {
					const idx = this.areaHeightList.findIndex(
						(area) => area.id == this.clickArea
					)
					if (idx > -1) {
						const h = this.areaHeightList[idx].top
						console.log('h', h, this.recordScroll, currentScrollDistance)
						//this.recordScroll >= currentScrollDistance 上一次距离顶部距离比这次大  代表向上滚动
						//h <= currentScrollDistance 超出滚动范围 并且趋势是向上滚动  恢复双向同步
						if (
							h <= currentScrollDistance ||
							(h > currentScrollDistance &&
								this.recordScroll >= currentScrollDistance)
						) {
							this.$emit('updateClickArea', '')
						} else {
							index = idx
						}
					}
				}

				this.recordScroll = currentScrollDistance
				this.$emit('updateAreaPosition', this.areaHeightList[index].id)
			})
		},
	},
	mounted() {
		const elementResizeDetectorMaker = require('element-resize-detector')
		let erd = elementResizeDetectorMaker()
		erd.listenTo(this.$refs.main, () => {
			this.$nextTick(function () {
				this.domInfo.width = this.$refs.main.offsetWidth
			})
		})
	},
	updated() {
		this.getAreaDom()
	},
}
</script>

<style scoped lang="scss">
.table-container {
	height: 100%;
	width: 100%;
	overflow-x: hidden;
	overflow-y: auto;
	display: flex;
	flex-direction: column;
	scroll-behavior: smooth;
	.area-div {
		background: #fff;
		border-top-left-radius: 5px;
		border-bottom-left-radius: 5px;
		margin: 5px 0;
		padding-bottom: 15px;
		padding-top: 5px;
		&:last-child {
			border-bottom: 0;
		}
	}
	.border-0 {
		border: 0 !important;
	}
	.area-title {
		padding: 16px;
		padding-bottom: 8px;
		font-weight: bold;
		color: #333333;
	}
	.area-remark {
		margin-top: 6px;
	}
	.table-list {
		display: flex;
		flex-wrap: wrap;
		box-sizing: border-box;
		// padding: 0 15px;
		flex: 0 0 auto;
		width: 100%;
	}
	.empty {
		flex: 1 1 auto;
		margin-top: 100px;
		padding: 0 15px;
		text-align: center;
		img{
			width: 600px;
		}
	}
}
</style>
