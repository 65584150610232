import Vue from 'vue'
import App from './App.vue'
import { router } from "@/router";
import { store } from "@/store";
import './assets/common.scss';
import './assets/element-variables.scss';
import './assets/index.scss';
import './assets/tailwindcss/index.css'
import ElementUI from 'element-ui';
import moment from 'moment'



import { deepClone, debounce, dataURLtoFile,requireDecimalDigit } from '@/utils'

import { Bus } from '@/config/bus';
Vue.prototype.$deepClone = deepClone
Vue.prototype.$debounce = debounce
Vue.prototype.$dataURLtoFile = dataURLtoFile
Vue.prototype.$Bus = Bus
Vue.prototype.$moment = moment
Vue.prototype.$requireDecimalDigit = requireDecimalDigit
moment.locale('zh-cn')
Vue.use(ElementUI);
Vue.config.productionTip = false
export const myApp = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
