var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"main",staticClass:"table-container",on:{"scroll":_vm.onScroll}},[_vm._l((_vm.tableAreaList),function(area){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(
			(_vm.tableAreaId.length > 0 ? _vm.tableAreaId.includes(area.id) : true) &&
			_vm.areaOrderMap[area.id] &&
			_vm.areaOrderMap[area.id].length > 0
		),expression:"\n\t\t\t(tableAreaId.length > 0 ? tableAreaId.includes(area.id) : true) &&\n\t\t\tareaOrderMap[area.id] &&\n\t\t\tareaOrderMap[area.id].length > 0\n\t\t"}],key:area.id,staticClass:"area-div",class:{ 'border-0': _vm.showEmpty == area.id }},[(
				(_vm.tableAreaId.length > 0 ? _vm.tableAreaId.includes(area.id) : true) &&
				_vm.areaOrderMap[area.id] &&
				_vm.areaOrderMap[area.id].length > 0
			)?_c('div',{ref:"area",refInFor:true,staticClass:"area-title page-sub-title",attrs:{"id":'area-' + area.id}},[_c('span',[_vm._v(_vm._s(area.areaName))])]):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.areaOrderMap[area.id] && _vm.areaOrderMap[area.id].length > 0),expression:"areaOrderMap[area.id] && areaOrderMap[area.id].length > 0"}],staticClass:"table-list"},_vm._l((_vm.tableAreaMap[area.id]),function(table){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(
					_vm.tableStatus
						? Array.isArray(_vm.getTableAreaList(area.id)) &&
						  _vm.getTableAreaList(area.id).includes(table.id)
						: true
				),expression:"\n\t\t\t\t\ttableStatus\n\t\t\t\t\t\t? Array.isArray(getTableAreaList(area.id)) &&\n\t\t\t\t\t\t  getTableAreaList(area.id).includes(table.id)\n\t\t\t\t\t\t: true\n\t\t\t\t"}],key:table.id,attrs:{"id":'table-' + table.id}},[_c('table-item',{style:({
						width: `${_vm.scale * _vm.tableWidth}px`,
						height: `${_vm.scale * _vm.tableWidth}px`,
						margin: `${_vm.marginScale}px`,
					}),attrs:{"id":table.id,"table":table,"scale":_vm.scale}})],1)}),0)])}),(!_vm.showEmpty)?_c('div',{staticClass:"empty"},[_c('img',{attrs:{"src":require("../../../../assets/img/empty.png"),"alt":""}})]):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }