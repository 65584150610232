<template>
	<div class="statistic">
		<div v-for="item in statisticList" :key="item.label" class="statistic-item">
			<div
				class="icon"
				:style="{
					background: ordersNumMap[item.type].color,
					border: '1px solid',
					borderColor:
						item.type == 'all' ? '#409EFF' : ordersNumMap[item.type].color,
				}"
			></div>
			<span>{{ item.label }}:</span>
			<span class="num">{{ ordersNumMap[item.type].num }}</span>
			<span
				v-if="item.type != 'all'"
				class="per"
				:style="{
					color: ordersNumMap[item.type].color,
					background: ordersNumMap[item.type].backgroundColor,
				}"
				>{{ ordersNumMap[item.type].per }}%</span
			>
		</div>
	</div>
</template>

<script>
import { statusColor, orderStatusCode } from '../../../../const'
import { mapState } from 'vuex'

export default {
	name: 'table-num-statistics',
	data() {
		return {
			ordersNumMap: {
				all: {
					num: 0,
					per: 0,
				},
				book: {
					num: 0,
					per: 0,
				},
				sitting: {
					num: 0,
					per: 0,
				},
				lock: {
					num: 0,
					per: 0,
				},
				idle: {
					num: 0,
					per: 0,
				},
			},
		}
	},
	computed: {
		...mapState('resvInfo', ['tableList', 'ordersNum']),
		statisticList() {
			return [
				{
					label: '总桌数',
					type: 'all',
				},
				{
					label: '已预订',
					type: 'book',
				},
				{
					label: '已入座',
					type: 'sitting',
				},
				{
					label: '已锁台',
					type: 'lock',
				},
				{
					label: '空闲',
					type: 'idle',
				},
			]
		},
	},
	watch: {
		ordersNum: {
			handler() {
               
				this.statisticList.map((item) => {
					const res = this.getOrderNum(item.type)
					const color = this.statusColor(item.type)
					this.ordersNumMap[item.type] = { ...res, ...color }
				})
			},
			immediate: true,
		},
	},
	methods: {
		statusColor(status) {
			switch (status) {
				case 'book':
					return statusColor['book']
				case 'sitting':
					return statusColor['sitting']
				case 'lock':
					return statusColor['lock']
				case 'idle':
					return statusColor['default']
				default:
					return {
						backgroundColor: '#fff',
						color: '#fff',
                        darkColor:'#fff'
					}
			}
		},
		getOrderNum(status) {
			const book = this.ordersNum[orderStatusCode.RESERVE]
				? this.ordersNum[orderStatusCode.RESERVE].length
				: 0
			const sitting = this.ordersNum[orderStatusCode.SET_IN]
				? this.ordersNum[orderStatusCode.SET_IN].length
				: 0
			const lock = this.ordersNum[orderStatusCode.LOCK]
				? this.ordersNum[orderStatusCode.LOCK].length
				: 0
			const tableLen = this.tableList ? this.tableList.length : 0
            
			switch (status) {
				case 'book':
					return {
						num: book,
						per:tableLen? parseInt((book / tableLen) * 100):0,
					}
				case 'sitting':
					return {
						num: sitting,
						per:tableLen? parseInt((sitting / tableLen) * 100):0,
					}
				case 'lock':
					return {
						num: lock,
						per: tableLen?parseInt((lock / tableLen) * 100):0,
					}
				case 'all':
					return {
						num: tableLen,
						per: 0,
					}
				case 'idle':
					return {
						num: tableLen - book - sitting - lock,
						per: tableLen?parseInt(
							((tableLen - book - sitting - lock) / tableLen) * 100
						):0,
					}
				default:
					return { num: 0, per: 0 }
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.statistic {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	padding-bottom: 5px;
	
}

.statistic-item {
	width: 180px;
	height: 40px;
	background: #fff;
	display: flex;
	flex-wrap: nowrap;
	align-items: center;
	justify-content: center;
	border-radius: 4px;
	margin-right: 10px;
	margin-bottom: 5px;
	.icon {
		width: 8px;
		height: 8px;
		border-radius: 2px;
		margin-right: 10px;
		margin-top: 2px;
	}
	.num {
		padding: 0 10px;
	}
	.per {
		padding: 2px 7px;
	}
}
</style>