
import { store } from "../store";
export const splitMeal = function (id) {

    if (id) {
        const mealIdMap = store.getters['resvInfo/mealIdMap']
        if (mealIdMap[id]) {
            let obj ={}
            obj.mealTypeId = mealIdMap[id].parentMealTypeId
           if(id == mealIdMap[id].mealTypeIdA){
            obj.mealTypeIdA = id
           }
           if(id == mealIdMap[id].mealTypeIdB){
            obj.mealTypeIdB = id
           }
       

            return obj
        }else{
            return {
                mealTypeId:mealIdMap[id].mealTypeId
            }
        }


    } else {
        return {
            mealTypeId:''
        }
    }

}


