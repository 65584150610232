import { ajax } from "@/api/request";
// import qs from 'qs';

export const user_login = function (data) {
  return ajax({
    method: 'post',
    url: '/token/v1/getTokenWithCode',
    data
  });
}

export const update_pwd_by_token = function (params, token) {
  return ajax({
    method: 'post',
    url: '/user/v1/updatePwdByToken',
    params,
    headers: {
      token: token,
      clientType: 'ZERO_PAD',
    }
  });
}

export const refresh_token = function (data) {
  return ajax({
    method: 'post',
    url: '/token/v1/refreshToken',
    data
  });
}

export const login_with_hotelId = function (data) {
  return ajax({
    method: 'post',
    url: '/token/v1/getTokenWithHotelId',
    data
  });
}