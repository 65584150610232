import Vue from 'vue'
import Vuex from 'vuex'
import {getStorage, setStorage} from "@/utils/storage";
import page from './modules/page'
import resvInfo from './modules/resvInfo'
import websocket from './modules/websocket'
import moment from 'moment'
Vue.use(Vuex)

export const store = new Vuex.Store({
  modules:{
    page,
     websocket,
     resvInfo
  },
  state: {
    token: getStorage('token') || '',
    oHotelId: getStorage('oHotelId') || '', // 登录的酒店id
    refreshToken: getStorage('refreshToken') || '',
    expTime: getStorage('expTime') || '',
    userInfo: getStorage('userInfo') || {},
    createClient:'ZERO_PAD',
    orgType: 1,
    permissions: [],
    fullscreenLoading:false,
    iframeUrl:''
  },
  mutations: {
    SET_TOKEN(state, token) {
      setStorage('token', token)
      state.token = token;
    },
    SET_OHOTELID(state, oHotelId) {
      setStorage('oHotelId', oHotelId)
      state.oHotelId = oHotelId;
    },
    SET_REFRESH_TOKEN(state, refreshToken) {
      setStorage('refreshToken', refreshToken)
      state.refreshToken = refreshToken;
    },
    SET_EXPTIME(state, expTime) {
      setStorage('expTime', expTime)
      state.expTime = expTime;
    },
    SET_USERINFO(state, userInfo) {
      setStorage('userInfo', userInfo)
      state.userInfo = userInfo;
    },
    SET_FULLSCREEN_LOADING(state, bool) {
      state.fullscreenLoading = bool;
    },
    SET_IFRAME_URL(state, url) {
      state.iframeUrl = url;
    },

  }
})