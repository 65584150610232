export const orderStatusCode = {
  // 预订成功
  RESERVE: 1,
  // 入座
  SET_IN: 2,
  // 结账
  CHECK_OUT: 3,
  // 退订
  DEBOOK: 4,
  //锁台
  LOCK: 5,
  //解锁
  UNLOCK: 6
}

export const nWeekMap = {
  '1': '周一',
  '2': '周二',
  '3': '周三',
  '4': '周四',
  '5': '周五',
  '6': '周六',
  '7': '周日',
  '0': '周日'
}

export const numberMap = {
	1: '一',
	2: '二',
	3: '三',
	4: '四',
	5: '五',
	6: '六',
	7: '七',
	8: '八',
	9: '九',
	10: '十',
	11: '十一',
	12: '十二',
	13: '十三',
	14: '十四',
	15: '十五',
	16: '十六',
	17: '十七',
	18: '十八',
	19: '十九',
	20: '二十',
}

export const statusColor = {
  'book': {
    color: '#627AF4',
    backgroundColor: '#F6F7FE',
    darkColor:'#626889',
    selectColor:'#627AF4'
  },
  'sitting': {
    color: '#29B092',
    backgroundColor: '#F2FAF9',
    darkColor:'#5A8680',
    selectColor:'#29B092'
  },
  'lock': {
    color: '#CA4755',
    backgroundColor: '#FCF4F5',
    darkColor:'#9C6B71',
    selectColor:'#CA4755'
  },
  'default': {
    color: '#A4A4A4',
    backgroundColor: '#F7F7F7',
    darkColor:'#888888',
    selectColor:'#409EFF'
  }
}









