import {ajax} from "@/api/request";

// 预订
export const confirm_order = function (data) {
    return ajax({
        url: '/zero/order/v1/createZeroOrder',
        method: 'post',
        data: data
    })
}

//入座
export const change_order_status = function (data) {
    return ajax({
        url: 'zero/order/v1/changeZeroOrderStatus',
        method: 'post',
        data: data
    })
}

//换桌
export const change_table = function (data) {
    return ajax({
        url: 'zero/order/v1/changeZeroOrder',
        method: 'post',
        data: data
    })
}

//加桌
export const add_table = function (data) {
    return ajax({
        url: 'zero/order/v1/addZeroOrder',
        method: 'post',
        data: data
    })
}

// 修改订单详情
export const update_order = function (data) {
    return ajax({
        url: '/zero/order/v1/updateZeroOrderDetail',
        method: 'post',
        data: data
    })
}

// 预订单列表
export const get_order_list = function (params) {
    return ajax({
        url: '/zero/order/v1/getTableOrders',
        method: 'get',
        params
    })
}

// 模糊查找手机号
export const mohu_search_phone = function (params) {
    return ajax({
        url: '/customerManage/v1/getDimCustomerList',
        method: 'get',
        params
    })
}


// 补发预订短信
export const resend_sms = function (params) {
    return ajax({
        url: '/zero/order/v1/reSendSms',
        method: 'post',
        params
    })
}


// 查询关联宴会厅
export const get_related_room = function (data) {
    return ajax({
        method: 'post',
        url: '/zero/order/v1/getRelateMeetingRoom',
        data
    });
}
