<template>
	<div class="section">
		<div class="header">
			<span class="title">桌位详情</span>
			<i class="el-icon-arrow-right title"></i>
			<span class="ml-5 sub-title"
				>桌位{{ sideBarType == 'table-add' ? '加' : '换' }}桌</span
			>
			<div @click="handleCloseTableChange" class="close">
				<i class="el-icon-close"></i>
			</div>
		</div>
		<div class="main">
			<div class="page-sub-title">客户信息</div>
			<div class="form-item mb-5" v-if="orderData.customerPhone">
				<span class="form-label ml-15">客户号码:</span>
				<span class="form-value">{{ orderData.customerPhone }}</span>
			</div>
			<div class="form-item mb-5">
				<span class="form-label ml-15">客户姓名:</span>
				<span class="form-value"
					>{{ orderData.customerName }}
					<span v-if="orderData.customerGender > -1">{{
						orderData.customerGender == 1 ? '先生' : '女士'
					}}</span>
				</span>
			</div>
			<div v-if="sideBarType == 'table-add'">
				<div class="page-sub-title mt-20">新增桌位</div>
				<div v-if="tableList.length == 0" class="tip">
					<span>请勾选空闲桌位进行加桌操作</span>
				</div>
				<div v-else>
					<el-tag
						v-for="t in tableList"
						:key="t.tableId"
						closable
						class="tag"
						@close="closeSelectTable(t)"
					>
						{{ t.tableName }}
					</el-tag>
				</div>
			</div>
			<div v-if="sideBarType == 'table-change'">
				<div class="page-sub-title mt-20">当前桌位</div>
				<div v-if="orderChangeTable.length == 0" class="tip">
					<span class="c-theme ml-15">请勾选空闲桌位进行换桌操作</span>
				</div>
				<div v-else>
					<el-tag
						v-for="t in orderChangeTable"
						:key="t.tableId"
						class="tag tag-default"
					>
						{{ t.tableName }}
						{{ t.resvDate ? ' (' + t.resvDate + t.mealTypeName + ')' : '' }}
					</el-tag>
				</div>
			</div>

			<div class="page-sub-title mt-20">修改后桌位</div>
			<div class="table-content" v-if="sideBarType == 'table-add'">
				<div v-if="orderData.orderTables && orderData.orderTables.length > 0">
					<el-input
						placeholder="用餐人数"
						class="mt-10"
						:value="table.resvNum"
						disabled
						:maxlength="4"
						size="small"
						v-for="table in orderData.orderTables"
						:key="table.id"
					>
						<template slot="prepend">{{ table.tableName }}</template>
						<template slot="append">人</template>
					</el-input>
				</div>

				<div v-if="form.tables.length > 0">
					<el-input
						placeholder="用餐人数"
						class="mt-10"
						v-model="table.resvNum"
						oninput="value=value.replace(/[^\d]/g,'')"
						:maxlength="4"
						size="small"
						v-for="table in form.tables"
						:key="table.tableId"
						:class="{
							highlight:
								parseInt(table.resvNum) >
								parseInt(tableIdMap[table.tableId].maxPeopleNum),
						}"
					>
						<template slot="prepend">(加) {{ table.tableName }}</template>
						<template slot="append">人</template>
					</el-input>
				</div>

				<div
					class="c-theme ml-15"
					v-if="
						form.tables.length == 0 &&
						orderData.orderTables &&
						orderData.orderTables.length == 0
					"
				>
					请勾选空闲桌位进行换桌操作
				</div>
			</div>
			<div v-if="sideBarType == 'table-change'">
				<el-tag
					v-for="t in tableList"
					:key="t.tableId"
					class="tag"
					@close="closeSelectTable(t)"
				>
					{{ t.tableName
					}}{{
						mealTypeDate
							? ' (' + mealTypeDate + mealTypeNameMap[mealTypeId] + ')'
							: ''
					}}
				</el-tag>

				<div v-if="tableList.length == 0" class="c-theme ml-15">
					请勾选空闲桌位进行换桌操作
				</div>
			</div>
			<related-table :tableIds="tableIds" >
				<div class="form-item mb-16 ml-15" slot="container" slot-scope="scope">
					<span class="form-label"> 关联宴会厅: </span>
					<div class="form-content flex-1">
						<div v-for="(item, index) in scope.list" :key="index">
							【{{ item.relatedMeetingMealName }}】{{ item.relatedMeetingTableName }}
						</div>
					</div>
				</div>
			</related-table>

			<div class="flex flex-between mt-20" v-if="orderData.customerPhone">
				<div class="page-sub-title">
					给客户发送{{ sideBarType == 'table-add' ? '加' : '换' }}桌短信
				</div>
				<el-switch
					class="mb-16 mt-5"
					v-model="form.isSendSms"
					:active-value="1"
					:inactive-value="0"
				>
				</el-switch>
			</div>
		</div>
		<div class="footer">
			<div class="content">
				<el-button size="small" class="btn" @click="handleCloseTableChange"
					>取消</el-button
				>
				<el-button size="small" class="btn" type="primary" @click="changeTable"
					>确定</el-button
				>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex'
import { tableSub } from '@/config/table'
import { change_table, add_table } from '@/api/resv'
import { orderStatusCode } from '../../../../const'
import relatedTable from '@/components/relatedTable'
import { splitMeal } from '@/utils/splitMeal'
export default {
	components: {
		relatedTable,
	},
	data() {
		return {
			form: {},
		}
	},
	mounted() {
		this.init()
	},
	watch: {
		tableList: {
			handler(newValue) {
				console.log('newValue', newValue)
				if (this.sideBarType == 'table-add') {
					this.form.tables = newValue.map((o) => {
						return {
							tableAreaId: o.tableAreaId,
							tableAreaName: o.areaName,
							tableId: o.id,
							tableName: o.tableName,
							resvNum: '',
						}
					})
				}
			},
			immediate: true,
		},
	},
	computed: {
		...mapState('resvInfo', [
			'selectedTables',
			'sideBarType',
			'mealTypeId',
			'mealTypeDate',
			'ordersNum',
		]),
		...mapState(['userInfo', 'createClient']),
		...mapGetters('resvInfo', ['mealIdMap', 'mealTypeNameMap', 'tableIdMap']),

		tableList() {
			return this.selectedTables.map((v) => v.table)
		},
		orderChangeTable() {
			return tableSub.orderChangeTable
		},
		// orderAllTable() {
		// 	return tableSub.orderAllTable
		// },
		orderChangeTableIds() {
			return this.orderChangeTable.map((item) => item.tableId)
		},
		tableData() {
			const len = this.orderChangeTable.length
			if (len == 0) {
				return {}
			}
			const data = this.orderChangeTable[len - 1] || {}

			return data
		},
		orderData() {
			const len = this.orderChangeTableIds.length
			if (len == 0) {
				return {}
			}
			const id = this.orderChangeTableIds[len - 1]
			const data = id ? tableSub.tableMap[id]('getSelectTable') : {}
			const order = data.order ? data.order : {}
			console.log('data', data)
			return order
		},
		tableIds() {
			if (this.sideBarType == 'table-add') {
				return this.form.tables.map((item) => {
					return item.tableId + ''
				})
			} else {
				return this.tableList.map((item) => {
					return item.id + ''
				})
			}
		}
	},
	methods: {
		...mapMutations('resvInfo', ['SET_SIDEBAR_TYPE']),
		init() {
			if (this.sideBarType == 'table-add') {
				console.log(this.orderChangeTable, this.selectedTables, 'sss')
				const order = this.orderChangeTable.find((o) => o.current)
				this.form = {
					orderNo: order.orderNo,
					hotelId: this.userInfo.fkOrgId,
					createClient: this.createClient,
					creatorId: this.userInfo.id,
					isSendSms: 1,
					tables: [],
				}
			} else if (this.sideBarType == 'table-change') {
				console.log(this.tableData)
                const mealType  = splitMeal(this.mealTypeId)
				this.form = {
					orderNo: this.tableData.orderNo,
					hotelId: this.userInfo.fkOrgId,
					createClient: this.createClient,
					creatorId: this.userInfo.id,
					isSendSms: 1,
					tableAreaId: '',
					tableAreaName: '',
					tableId: '',
					tableName: '',

					resvDate: this.mealTypeDate,
					...mealType
				}
				console.log('换桌', this.form)
			}
		},
		handleCloseTableChange() {
			tableSub.cancelChangeTable()
			this.SET_SIDEBAR_TYPE('idle')
		},
		closeSelectTable(t) {
			tableSub.selectTable(t.id)
		},
		changeTable() {
			if (this.sideBarType == 'table-add') {
				const order = this.orderChangeTable.find((o) => o.current)
				if (
					!(
						this.mealTypeDate == order.resvDate &&
						order.mealTypeId == this.mealTypeId
					)
				) {
					return this.$message.warning('所选日期有误，不可加桌到非当前餐段')
				}
				let flag = null
				for (let i = 0; i < this.form.tables.length; i++) {
					const element = this.form.tables[i]
					if (!element.resvNum || parseInt(element.resvNum) < 1) {
						flag = element.tableName
						break
					}
				}

				if (flag) {
					return this.$message.warning('请输入' + flag + '的用餐人数')
				}

				add_table(this.form).then((res) => {
					this.$message.success(res)
					this.handleCloseTableChange()
				})
			} else if (this.sideBarType == 'table-change') {
				if (this.tableList.length > 0) {
					const mealType  = splitMeal(this.mealTypeId)
					const table = this.tableList[0]
					this.form.tableAreaId = table.tableAreaId
					this.form.tableAreaName = table.areaName
					this.form.tableName = table.tableName
					this.form.tableId = table.id
					// this.form.mealTypeId = this.mealTypeId
					this.form.resvDate = this.mealTypeDate
					this.form ={
						...this.form,
						...mealType
					}
					console.log(this.form)
					const mealTypeDate = this.$moment(this.mealTypeDate).format(
						'YYYYMMDD'
					)
					const now = this.$moment().format('YYYYMMDD')
					if (mealTypeDate < now) {
						return this.$message.error('所选日期有误，不可换桌到过去日期')
					} else if (mealTypeDate == now) {
						const chooseMealType = this.mealIdMap[this.mealTypeId]
						console.log('chooseMealType', chooseMealType)
						const endTime = this.$moment(chooseMealType.endTime).format(
							'YYYYMMDDHHmm'
						)

						const nowTime = this.$moment().format('YYYYMMDDHHmm')
						if (endTime < nowTime) {
							return this.$message.error('所选餐别有误，不可换桌到过去餐段')
						} else if (
							this.orderChangeTable &&
							this.orderChangeTable.length > 0 &&
							this.orderChangeTable[0].mealTypeId != this.mealTypeId &&
							this.tableData.orderStatus == orderStatusCode.SET_IN
						) {
							return this.$message.error('入座订单不可换桌到非当前餐别')
						}
					} else {
						console.log('table', this.tableData)
						if (this.tableData.orderStatus == orderStatusCode.SET_IN) {
							return this.$message.error('入座订单不可换桌到未来日期')
						}
					}
					change_table(this.form).then((res) => {
						this.$message.success(res)
						this.handleCloseTableChange()
					})
				}
			}
		},
	},
}
</script>

<style scoped lang="scss">
.section {
	display: flex;
	flex-direction: column;
	overflow: hidden;
	height: 100%;
	.close,
	.close-table {
		position: absolute;
		height: 40px;
		width: 40px;
		top: 0;
		cursor: pointer;
		right: 0;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.header {
		height: 40px;
		border-bottom: 1px solid rgba(51, 51, 51, 0.1);
		flex: 0 0 40px;
		padding: 0 18px;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		position: relative;
		color: #333;
		font-size: 16px;
		.title {
			color: #a4a4a4;
		}
	}
	.main {
		flex: 1 1 auto;
		position: relative;
		padding: 14px 10px;
		box-sizing: border-box;
		display: flex;
		overflow: auto;
		flex-direction: column;

		.form-item {
			display: flex;
			justify-content: center;
			align-items: flex-start;
			.form-label {
				line-height: 32px;
				text-align: right;
				margin-right: 20px;
			}
			.form-value {
				flex: 1;
				line-height: 32px;
			}
			.form-content {
				min-height: 32px;
				line-height: 32px;
			}
		}
		.tip {
			color: #409eff;
			margin-left: 12px;
		}
		.tag {
			margin-bottom: 5px;
			margin-left: 12px;
			background: #f4f9ff;
			border-color: #f4f9ff;
			color: #409eff;
			transition: none;
		}
		.tag-default {
			background: #f5f5f5 !important;
			color: #333 !important;
		}
		.table-content {
			margin: 0 12px;
		}
		:deep(.el-tag .el-tag__close:hover) {
			color: #409eff;
			background: rgba($color: #409eff, $alpha: 0.1);
		}
		:deep(.el-input-group__prepend) {
			width: 85px;
			max-width: 85px;
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow: hidden;
			padding: 0px 8px;
			background: #f5f6fb;
			border-color: #ebeef5;
			border-radius: 2px;
			color: #526177;
		}
		:deep(.el-input-group__append) {
			padding: 0 10px;
			background: transparent;
		}
	}
	.footer {
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 20px 0;
		.content {
			width: 72%;
			display: flex;
			justify-content: space-between;
			align-items: center;
			.btn {
				width: 120px;
			}
		}
	}
	:deep(.highlight) {
		position: relative;
		input {
			color: red;
		}
		&::after {
			content: '\e6c9超出容纳人数';
			position: absolute;
			font-family: element-icons;
			color: red;
			right: 40px;
			top: 50%;
			font-size: 14px;
			margin-top: -10px;
		}
	}
}
</style>
