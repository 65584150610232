const env = process.env.NODE_ENV;
const urlMap = {
  // development: 'https://ed.zhidianfan.com',
  // development: 'http://192.168.3.129:9998',
  development: 'https://ed.zhidianfan.com',
  production: 'https://ed.zhidianfan.com',
  // production: 'https://edtest.zhidianfan.com',
  test: 'http://edtest.c39eb1826bc29416a82e276880425b0e0.cn-hangzhou.alicontainer.com/',
  local: 'http://lr.vaiwan.com/'
};
export const ip_url = urlMap[env];
export const base_url = ip_url + '/api';

const appUrlMap = {
  development: 'https://yhj.zhidianfan.com',
  // development: 'http://192.168.3.135:8081',
  production: 'https://yhj.zhidianfan.com',
  test: 'https://edtest.c39eb1826bc29416a82e276880425b0e0.cn-hangzhou.alicontainer.com',
  local: 'http://lr.vaiwan.com/'
};
export const app_url = appUrlMap[env];
