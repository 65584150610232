import Vue from 'vue';
import VueRouter from "vue-router";
import Home from '../pages/home';
import Login from '../pages/login';


import { Bus } from "@/config/bus";
import { getStorage } from "@/utils/storage";

Vue.use(VueRouter)
// 避免到当前位置的冗余导航
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
// 避免到当前位置的冗余导航
const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch(err => err)
}
import { get_user_info } from "@/api/user";
import { checkConfigKey } from "@/api/common";

export const router = new VueRouter({
  routes: [{
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '',
    name:'home',
    component: Home
  },
  {
    path: '*',
    redirect: {
      name: 'home'
    }
  }
  ]
})

router.beforeEach(async (to, from, next) => {
  const token = getStorage('token');
  // console.log('to', to);
  if ((!token) && (to.name !== 'login')) {
    return next({
      name: 'login'
    })
  }
  if (token) {
    if (to.meta.permission) {
      const oHotelId = getStorage('oHotelId');
      if (!oHotelId) {
        // console.log(getStorage('hotelId'),'getStorage11')
        const user = await get_user_info({
          hotelId: getStorage('hotelId'),
        })
        const res = await checkConfigKey({
          configKeyList: [to.meta.permission],
          hotelId: user.fkOrgId
        })
        const auth = res && res.filter(o => { return !!o.hasConfigKey }).map(v => { return v.configKey })
        if (user.hotelInfo.orgType === 2) { //集团
          // console.log('404')
        } else { //门店
          if (auth.indexOf(to.meta.permission) < 0) {
            return next({
              name: '404'
            })
          }
        }
      } else {
        next();
      }
    }
  }

  const $content = document.querySelector('#pageContent');
  const scrollTop = $content ? $content.scrollTop : 0;
  from.meta.scrollTop = scrollTop;
  console.log('scrollTop', scrollTop)

  next();
})

router.afterEach((to) => {
  // console.log('bread', to.meta.bread);
  to.meta.path && Bus.$emit('path', to.meta.path);
  to.meta.bread && Bus.$emit('bread', to.meta.bread);
})
