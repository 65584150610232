<template>
	<div class="section">
		<div class="header">
			<span class="title">今日预订订单</span>
			<div @click="handleCloseOrder" class="close">
				<i class="el-icon-close"></i>
			</div>
		</div>
		<el-tabs v-model="tabIndex" class="tab">
			<el-tab-pane label="已预订" name="book"></el-tab-pane>
			<el-tab-pane label="已入座" name="sit"></el-tab-pane>
		</el-tabs>
		<div class="statistic">
			<span
				>共{{ statisticNum.ordersNum }}单({{ statisticNum.tableNum }}桌/{{
					statisticNum.peopleNum
				}}人)</span
			>
		</div>
		<div class="main" v-if="orderList.length > 0">
			<div
				class="order-item"
				@click="chooseTable(order.table.tableId)"
				v-for="order in orderList"
				:key="order.orderNo"
				:style="{
					background: statusColor(order.table.orderStatus).backgroundColor,
				}"
			>
				<div class="content">
					<div class="left">
						<div
							class="status"
							:style="{
								background: statusColor(order.table.orderStatus).color,
							}"
						></div>
					</div>
					<div class="right">
						<div
							class="customer-name"
							:style="{
								color: statusColor(order.table.orderStatus).color,
							}"
						>
							{{ customerName(order.customerName, order.customerGender) }}
						</div>
						<div v-if="order.orderTables.length > 1">
							<div
								class="multi-table"
								:style="{
									background: statusColor(order.table.orderStatus).color,
								}"
							></div>
							<el-tooltip class="item" effect="dark" placement="bottom-end">
								<div slot="content">
									<div class="flex flex-align-center">
										<img
											src="../../../../assets/icons/multi-table.png"
											alt=""
											class="mr-5"
											width="14"
										/>
										连桌桌位:
									</div>

									<div
										v-for="table in order.orderTables"
										:key="table.orderNo"
										class="mt-10"
									>
										{{ table.tableAreaName ? table.tableAreaName + '-' : ''
										}}{{ table.tableName }}
									</div>
								</div>
								<img
									src="../../../../assets/icons/multi-table.png"
									alt=""
									class="multi-table-icon"
									width="14"
								/>
							</el-tooltip>
						</div>
						<div class="flex flex-wrap mt-16 mb-5">
							<div
								class="
									customer-info customer-phone
									flex50
									flex flex-align-center
									mb-5
								"
							>
								<img
									src="../../../../assets/icons/icon-phone.png"
									alt=""
									width="16"
									class="mr-10"
								/>
								{{ order.customerPhone }}
							</div>
							<div
								class="
									customer-info customer-people
									flex50
									flex flex-align-center
									mb-5
								"
							>
								<img
									src="../../../../assets/icons/icon-people.png"
									alt=""
									width="16"
									class="mr-10"
								/>
								{{ order.table.resvNum || 0 }}人
							</div>
							<div
								class="
									customer-info customer-area
									flex50
									flex flex-align-center
									mb-5
								"
							>
								<img
									src="../../../../assets/icons/icon-area.png"
									alt=""
									width="16"
									class="mr-10"
								/>
								{{
									order.table.tableAreaName
										? order.table.tableAreaName + '-'
										: ''
								}}{{ order.table.tableName }}
							</div>
							<div
								class="
									customer-info customer-time
									flex50
									flex flex-align-center
									mb-5
								"
							>
								<img
									src="../../../../assets/icons/icon-time.png"
									alt=""
									width="16"
									class="mr-10"
								/>
								{{ order.table.resvDate }} {{ order.table.mealTypeName }}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-else class="empty">
			<img src="../../../../assets/img/empty.png" alt="" width="100%" />
			<p class="mt-16">暂无订单</p>
		</div>
	</div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex'
import { tableSub } from '@/config/table'
import { statusColor, orderStatusCode } from '../../../../const'
import { getFirstName } from '@/utils/getFirstName'
export default {
	name: 'idle-detail',
	data() {
		return {
			tabIndex: 'book',
		}
	},
	computed: {
		...mapState('resvInfo', ['selectedTables', 'ordersNum']),
		...mapGetters('resvInfo', ['zeroHotelOtherConfigMap']),
		hiddenCustomerName() {
			return this.zeroHotelOtherConfigMap[
				'ZERO_OTHER_CONFIG_TABLE_CUSTOMER_NAME'
			] == 1
				? 0
				: 1
		},

		orderList() {
			if (this.tabIndex == 'book') {
				return this.ordersNum[orderStatusCode.RESERVE] || []
			} else {
				return this.ordersNum[orderStatusCode.SET_IN] || []
			}
		},
		statisticNum() {
			const tableNum = this.orderList.length
			let peopleNum = 0
			let orderNumMap = {}
			console.log('orderList', this.orderList)
			this.orderList.map((o) => {
				if (!orderNumMap[o.id]) {
					orderNumMap[o.id] = 0
				}
				orderNumMap[o.id] += 1
				peopleNum += o.table.resvNum
			})
			const orderId = Object.keys(orderNumMap)
			return {
				tableNum: tableNum,
				peopleNum: peopleNum,
				orderNumMap: orderNumMap,
				ordersNum: orderId.length || 0,
			}
		},
		tableList() {
			return this.selectedTables.map((v) => v.table)
		},
	},
	methods: {
		...mapMutations('resvInfo', ['SET_SIDEBAR_TYPE']),
		handleCloseOrder() {
			tableSub.selectTable([])
			this.SET_SIDEBAR_TYPE('idle')
		},
		customerName(name, gender) {
			return this.hiddenCustomerName
				? getFirstName(name) + (gender == 1 ? '先生' : '女士')
				: name
		},
		statusColor(status) {
			switch (status) {
				case 1:
					return statusColor['book']
				case 2:
					return statusColor['sitting']
				case 5:
					return statusColor['lock']
				default:
					return statusColor['default']
			}
		},
		chooseTable(id) {
			this.$nextTick(() => {
				tableSub.selectTable(id)
				const selector = '#table-' + id
				document.querySelector(selector).scrollIntoView({
              behavior: "smooth",
              block: 'nearest', 
              inline: 'start'
				})
			})
		},
	},
}
</script>

<style scoped lang="scss">
.section {
	display: flex;
	flex-direction: column;
	overflow: hidden;
	height: 100%;
	.close,
	.close-table {
		position: absolute;
		height: 40px;
		width: 40px;
		top: 0;
		cursor: pointer;
		right: 0;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.header {
		height: 40px;
		border-bottom: 1px solid rgba(51, 51, 51, 0.1);
		flex: 0 0 40px;
		padding: 0 18px;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		position: relative;
		color: #333;
		font-size: 16px;
	}

	.tab {
		:deep(.el-tabs__nav-scroll) {
			display: flex;
			justify-content: center;
		}
		:deep(.el-tabs__item) {
			width: 120px;
			text-align: center;
		}
		:deep(.el-tabs__nav-wrap::after) {
			background: rgba(51, 51, 51, 0.1);
			height: 1px;
		}
	}
	.main {
		flex: 1 1 auto;
		position: relative;
		padding: 0px 0;
		box-sizing: border-box;
		display: flex;
		overflow: auto;
		flex-direction: column;
		padding: 0 14px;

		.order-item {
			border-radius: 8px;
			margin: 4px 0;
			cursor: pointer;
			.content {
				display: flex;
				padding: 15px 5px;
				overflow: hidden;
				flex-wrap: nowrap;
				position: relative;

				.left {
					flex: 0 0 3px;
					display: flex;
					.status {
						margin-top: 3px;
						height: 16px;
						width: 3px;
						border-radius: 0px 2px 2px 0px;
					}
				}
				.right {
					flex: 1;
					padding-left: 5px;
					.customer-name {
						font-size: 16px;
						font-weight: bold;
					}
					.multi-table {
						position: absolute;
						top: -23px;
						right: -23px;
						width: 50px;
						height: 50px;
						border-radius: 50%;
					}
					.multi-table-icon {
						position: absolute;
						top: 7px;
						right: 4px;
					}
					.customer-info {
						color: #212121;
						font-size: 14px;
					}
				}
			}
		}
	}
	.empty {
		flex: 1 1 auto;
		margin-top: 100px;
		padding: 0 15px;
		text-align: center;
	}
	.statistic {
		padding: 0px 14px 10px 14px;

		font-size: 13px;
		color: #666666;
	}
}
</style>
