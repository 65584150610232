<template>
	<div class="section">
		<div class="header">
			<span class="title">桌位详情</span>
			<div @click="handleCloseLock" class="close">
				<i class="el-icon-close"></i>
			</div>
		</div>
		<div class="main">
			<div class="head">
				<div v-for="t in selectedTables" :key="t.id" class="tw-my-2">
					<div class="table-list-item">
						<div
							class="arrow-left"
							:style="{ borderRightColor: statusColor.backgroundColor }"
						></div>
						<div
							class="arrow-middle flex"
							:style="{ background: statusColor.backgroundColor }"
						>
							<div class="flex flex-1 flex-center">
								<div
									class="dot mr-10"
									:style="{ background: statusColor.color }"
								></div>
								<div
									:style="{ color: statusColor.color }"
									class="font-bold font-size-14 table-name"
								>
									{{ t.table.areaName ? t.table.areaName + '-' : ''
									}}{{ t.table.tableName }}
								</div>
								<div
									class="dot ml-10"
									:style="{ background: statusColor.color }"
								></div>
								<div :style="{ color: statusColor.color }" class="ml-15">
									{{ statusName }}
								</div>
							</div>
							<div class="flex flex-align-center">
								<span @click="closeSelectTable(t)" class="close-table">
									<i
										class="el-icon-close"
										:style="{ color: statusColor.color }"
									></i>
								</span>
							</div>
						</div>
						<div
							class="arrow-right"
							:style="{ borderLeftColor: statusColor.backgroundColor }"
						></div>
					</div>
					<related-table :tableIds="t.id">

						<div class="ml-30 mt-20 mr-30 flex" slot="container" slot-scope="scope">
							<div >
								关联宴会厅:
							</div>
							<div class="flex-1">
								<div v-for="(item, index) in scope.list" :key="index">
									【{{ item.relatedMeetingMealName }}】{{ item.relatedMeetingTableName }}
								</div>
							</div>

						</div>
					</related-table>
				</div>
				<div class="lock-reason tw-py-3 tw-px-5">锁台原因：{{ tableData.remark }}</div>
        <div class="operation-log tw-text-grey tw-px-5 tw-py-2">
          <div>操作时间：{{ tableData.createTime }}</div>
          <div>操作人：{{ tableData.creatorName }}</div>
        </div>
			</div>
		</div>
		<div class="footer">
			<el-button
				style="width: 290px"
				plain
				icon="el-icon-unlock"
				type="primary"
				@click="unlockTable"
				>解锁已选桌位（{{ selectedTables.length }}）</el-button
			>
		</div>
	</div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex'
import { tableSub } from '@/config/table'
import { change_order_status } from '@/api/resv'
const form = {
	orderId: '',
	hotelId: '',
	createClient: '',
	creatorId: '',
	creatorName: '',
	isSendSms: 0,
	orderStatus: 6,
	tables: [],
}
import { statusColor } from '../../../../const'
import relatedTable from '@/components/relatedTable'
export default {
	name: 'lock',
	data() {
		return {
			form: this.$deepClone(form),
		}
	},
	components: {
		relatedTable,
	},
	computed: {
		...mapState(['userInfo', 'createClient']),
		...mapState('resvInfo', ['selectedTables']),
		...mapGetters('resvInfo', ['mealIdMap', 'mealTypeNameMap','tableData','orderData']),
		statusColor() {
			console.log('this.tableData', this.tableData, this.orderData)
			switch (this.tableData.orderStatus) {
				case 1:
					return statusColor['book']
				case 2:
					return statusColor['sitting']
				case 5:
					return statusColor['lock']
				default:
					return statusColor['default']
			}
		},
		statusName() {
			switch (this.tableData.orderStatus) {
				case 1:
					return '已预订'
				case 2:
					return '已入座'
				case 5:
					return '已锁台'
				default:
					return '空闲'
			}
		}
	},
	methods: {
		...mapMutations('resvInfo', ['SET_SIDEBAR_TYPE']),
		handleCloseLock() {
			this.SET_SIDEBAR_TYPE('idle')
		},

		closeSelectTable(t) {
			tableSub.selectTable(t.id)
		},
		unlockTable() {
			if (this.selectedTables.length == 0) {
				return this.$message.warning('请先选择桌位')
			}
			const ids = this.selectedTables.map((o) => {
				return o.table.id
			})
			this.form.orderId = this.tableData.orderId
			this.form.hotelId = this.userInfo.fkOrgId
			this.form.createClient = this.createClient
			this.form.creatorId = this.tableData.creatorId
			this.form.creatorName = this.tableData.creatorName
			this.form.tables = []
			this.orderData.orderTables.map((item) => {
				if (ids.includes(item.tableId)) {
					this.form.tables.push({
						orderNo: item.orderNo,
					})
				}
			})
			console.log(this.form)
			change_order_status(this.form)
				.then((res) => {
					this.$message.success('桌位解锁成功')

					tableSub.selectTable([])
					this.SET_SIDEBAR_TYPE('idle')
          this.$Bus.$emit('refresh')
				})
				.catch((err) => {
					this.SET_SIDEBAR_TYPE('idle')
					tableSub.selectTable([])
					console.log(err)
				})
		},
	},
}
</script>

<style scoped lang="scss">
.section {
	display: flex;
	flex-direction: column;
	overflow: auto;
	height: 100%;
	.close,
	.close-table {
		position: absolute;
		height: 40px;
		width: 40px;
		top: 0;
		cursor: pointer;
		right: 0;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.header {
		height: 40px;
		border-bottom: 1px solid rgba(51, 51, 51, 0.1);
		flex: 0 0 40px;
		padding: 0 18px;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		position: relative;
		font-size: 16px;
		color: #000;

		.title {
			color: #333;
		}
	}
	.main {
		width: 100%;
		flex: 1 1 auto;
		position: relative;

		box-sizing: border-box;
		display: flex;
		overflow: auto;
		flex-direction: column;
		.head {
			.table-list-item {
				padding: 0 5px;
				height: 40px;
				border-radius: 4px;
				position: relative;
				line-height: 40px;
				color: #333;
				font-size: 13px;
				display: flex;
				.arrow-left {
					width: 0px;
					height: 0px;
					border-top: 20px solid transparent;
					border-right: 13px solid rgba(51, 51, 51, 0.05);
					border-bottom: 20px solid transparent;
					border-left: 20px solid transparent;
					position: relative;
					/* 利用滤镜filter，添加投影 */
					filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.05));
				}

				.arrow-middle {
					width: 100%;

					background: rgba(51, 51, 51, 0.05);
					position: relative;
					z-index: 1;
					color: #666666;
					text-align: center;
					padding-left: 5px;
					padding-right: 5px;
					.span {
						margin-right: 5px;
					}
					.table-name {
						max-width: 120px;
						line-height: 16px;
						text-align: left;
					}
				}

				.arrow-right {
					width: 0px;
					height: 0px;
					/* margin: 50px auto; */
					border-top: 20px solid transparent;
					border-right: 20px solid transparent;
					border-bottom: 20px solid transparent;
					border-left: 13px solid rgba(51, 51, 51, 0.05);
					/* 利用滤镜filter，添加投影 */
					filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.05));
				}
			}
      .lock-reason {
        border-bottom: 4px solid #f6f6f6;
      }
		}
	}
	.footer {
		height: 170px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}
</style>
