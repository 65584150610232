import { store } from '@/store'
class TableSub {
  constructor() {
    this.mealId = null;
    this.tableMap = {};
    this.selectedTable = [];
    this.orders = [];
    // 桌位订单对应map
    this.tableOrderMap = {};
    // 换桌 加桌
    this.inTableChange = '';
    this.tableChangeNum = 0;
    this.orderChangeTable = [];
    // this.orderAllTable = [];
  }
  // 桌位订单交互
  orderConnectToTable(orders, mealId) {

    if (orders && orders.length > 0) {
      this.orders = orders;
    } else {
      this.orders = [];
    }

    if (mealId) {
      this.mealId = mealId;
      this.clearState();
    } else {
      mealId = this.mealId;
    }
    this.tableOrderMap = {};
    this.currentTableOrderMap = {};
    const ordersNum = {}
    for (const order of orders) {
      if (order.orderTables) {
        for (const t of order.orderTables) {
          const id = t.tableId;
          const status = t.orderStatus

          if (!ordersNum[status]) {
            //初始化
            ordersNum[status] = []
          }
          ordersNum[status].push({
            ...order,
            table: t,
          })



          const os = this.tableOrderMap[id];
          this.tableOrderMap[id] = os ? [...os, order] : [order];

          const cos = this.currentTableOrderMap[id];
          this.currentTableOrderMap[id] = cos ? [...cos, t] : [t];
        }
      }
    }
    store.commit('resvInfo/SET_ORDERS_NUM', ordersNum);
    const ids = Object.keys(this.tableOrderMap);

    for (const id of ids) {

      this.checkTableState(id, mealId);
    }
  }
  checkTableState(id) {
    const orders = this.tableOrderMap[id];
    const currentOrders = this.currentTableOrderMap[id] || {};
    orders.map(o =>{
      o.currentOrders = currentOrders
    })
    console.log('ordersmap', id, orders);
    return this.sendToTable(id, ...orders);
  }

  addTableListener(id, fun) {
    this.tableMap[id] = fun;
  }
  deleteTableListener(id) {
    delete this.tableMap[id];
  }
  clearState(ids) {
    // this.selectTable([]);
    if (ids) {
      for (const id of ids) {
        this.sendToTable(id, {
          status: 'idle'
        })
      }
      return;
    }
    for (const id of Object.keys(this.tableMap)) {
      this.sendToTable(id, {
        status: 'idle'
      })
    }
  }
  // 获取每个桌位的状态
  getState(arr) {

    const keys = Object.keys(this.tableMap);
    const res = [];
    for (const k of keys) {
      const table = this.tableMap[k]('getState', arr);
      if (table) {
        res.push(table);
      }
    }
    return res;


  }
  sendToTable(id, data) {
    try {
      this.tableMap[id]('state', {
        ...data,
        change: this.inTableChange
      });
    } catch (e) {
      console.log(e);
    }
  }
  // 桌位选择
  selectTable(data) {
    console.log("选中桌位", data);
    // 点击订单关联选择桌位

    //传进来是id
    if (typeof data == 'string') {

      const id = data
      console.log("传进来的是id", id);
      let idx = this.selectedTable.findIndex(v => v.id == id)
      if (idx > -1) {
        data = this.selectedTable[idx]
      } else {
        const table = this.tableMap[id]('getSelectTable');
        this.tableMap[id]('select', true);
        console.log('新选桌位', table);
        data = table
      }
    } else if (Array.isArray(data)) {

      // 清除原先的桌位，增加当前的桌位
      for (const t of this.selectedTable) {
        try {
          this.tableMap[t.id]('select', false);
        } catch (e) { console.log(e); }
      }

      console.log('传入数组', data);
      const list = data
      this.selectedTable = []
      for (const t of list) {
        try {
          console.log('传入数组', t);
          const table = this.tableMap[t]('getSelectTable');
          this.tableMap[t]('select', true);
          console.log('传入数组', table);
          data = table
          this.selectedTable.push(data)
          if (data.order && data.order.id) {
            if (['book', 'sitting'].includes(data.status)) {
              store.commit('resvInfo/SET_SIDEBAR_TYPE', 'detail-1');
            }
            else if (['lock'].includes(data.status)) {
              store.commit('resvInfo/SET_SIDEBAR_TYPE', 'lock');
            }
          } else {
            store.commit('resvInfo/SET_SIDEBAR_TYPE', 'idle');
          }


          // this.tableMap[t.id]('select', true);
        } catch (e) {
          console.log(e);
        }
      }
      store.commit('resvInfo/SET_SELECTED_TABLE', this.selectedTable);
      return


    }

    if (!this.inTableChange) {

      if (data.order && data.order.id) {
        if (['book', 'sitting'].includes(data.status)) {
          store.commit('resvInfo/SET_SIDEBAR_TYPE', 'detail-1');
        }
        else if (['lock'].includes(data.status)) {
          store.commit('resvInfo/SET_SIDEBAR_TYPE', 'lock');
        }

      } else {
        store.commit('resvInfo/SET_SIDEBAR_TYPE', 'idle');
      }

    }


    const ids = this.selectedTable.map(v => v.id);
    if (ids.includes(data.id)) {
      this.selectedTable = this.selectedTable.filter(v => v.id !== data.id);
      store.commit('resvInfo/SET_SELECTED_TABLE', this.selectedTable);
      try {
        if (this.selectedTable.length == 0 && !this.inTableChange) {
          store.commit('resvInfo/SET_SIDEBAR_TYPE', 'idle');
        }
        this.tableMap[data.id]('select', false);
      } catch (e) {
        console.log(e);
      }

      return false;
    }
    if (this.inTableChange) {
      if (data.status === 'idle') {
        return this.changeTableSelect(data)
      }
      return false
    }

    // 添加选择的桌位
    let flag = false
    if (this.selectedTable.length > 0 && this.selectedTable[0].status && data.status != this.selectedTable[0].status) {
      flag = true
    }

    const bool =
      (this.selectedTable.length === 0) ||
      ((this.selectedTable[0].status === 'idle') && (data.status === 'idle')) // 都没有当前订单
      ||
      (data.order.id && (this.selectedTable[0].order.id === data.order.id) && !flag) // 订单编号相同


    if (bool) {
      this.selectedTable.push(data);
      store.commit('resvInfo/SET_SELECTED_TABLE', this.selectedTable);
      return true;
    }
    // 清除原先的桌位，增加当前的桌位
    for (const t of this.selectedTable) {
      try {
        this.tableMap[t.id]('select', false);
      } catch (e) { console.log(e); }
    }
    this.selectedTable = [data];

    store.commit('resvInfo/SET_SELECTED_TABLE', this.selectedTable);
    return true;
  }
  changeTableSelect(data) {
    this.selectedTable.push(data);
    const l = this.selectedTable.length;
    if ((l > this.tableChangeNum) && (this.inTableChange != 'add')) {
      const t = this.selectedTable.shift();
      this.tableMap[t.id]('select', false);
    }


    store.commit('resvInfo/SET_SELECTED_TABLE', this.selectedTable);
    return true;
  }
  // 加换桌
  changeTables(type) {

    this.inTableChange = type;

    const len = this.selectedTable.length
    let order = this.selectedTable[len - 1].order || {};
    const orderTables = order.orderTables || [];
    this.tableChangeNum = this.selectedTable.length;

    if (this.inTableChange == 'add') {
      for (const t of orderTables) {
        this.tableMap[t.tableId]('orderChangeTable');
      }
    }
    if (this.inTableChange == 'change') {
      for (const t of this.selectedTable) {
        this.tableMap[t.id]('orderChangeTable');
      }
    }
    this.orderChangeTable = this.inTableChange == 'add' ?
      orderTables.map(table=>{

        if(this.selectedTable[len-1].id == table.tableId){
          table.current= true
        }
        console.log('ssss',table.current);
        return table
      }) :
      this.selectedTable.reduce((a, b) => {
        const id = b.table.id;
        const item = orderTables.find(v => v.tableId === id);
        console.log(item, orderTables);
        if (item) {
          a.push(item);
        }
        return a;
      }, []);

      console.log("12333",this.orderChangeTable);
    // this.orderAllTable = orderTables
    this.selectTable([]);
  }
  cancelChangeTable() {

    const ids = Object.keys(this.tableMap);
    for (const id of ids) {
      this.tableMap[id]('orderCancelTable');
    }
    this.inTableChange = '';
    this.selectTable([]);
  }
}
export const tableSub = new TableSub();

