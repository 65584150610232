import axios from 'axios';
import { Message, MessageBox } from 'element-ui';
import { base_url } from "@/config";
import { store } from "@/store";
import { setStorage } from "@/utils/storage";

const CancelToken = axios.CancelToken;
let source = CancelToken.source();

const urls = ['/token/v1/getTokenWithCode', '/user/v1/updatePwdByToken', '/log/v1/saveErrorLog', '/log/v1/saveLoginLog'] // 不需要弹全局错误的接口url

const instance = axios.create({
  baseURL: base_url,
  timeout: 10000
})
instance.defaults.headers.post['Content-Type'] = 'application/json';

// request拦截
instance.interceptors.request.use(function (config) {
  if (store.state.token) {
    config.headers.token = store.state.token || '';
    config.headers.clientType = 'ZERO_PAD'
    config.cancelToken = source.token;
    config.headers.orgId = store.state.userInfo.fkOrgId || ''
  }

  return config;
}, function (error) {
  return Promise.reject(error);
});

// response拦截
instance.interceptors.response.use(function (response) {
  const { code, data, msg } = response.data;
  const { url } = response.config;
  if (code == 401401 || code == 401402) {
    source.cancel()
    MessageBox.alert('登录状态失效，请重新登录。', '温馨提示', {
      showClose: false,
      confirmButtonText: '重新登录',
      callback: () => {
        source = CancelToken.source();
        setStorage('token', '');
        setStorage('oHotelId', '');
        setStorage("hotelId", '')
        setStorage('userInfo', {})
        location.reload()
      }
    })
    return Promise.reject('无权限')
  } else if (code == 300003) {
    source.cancel()
    Message.error({
      showClose: false,
      message: '登录失效',
      duration: 2000

    })
    setTimeout(() => {
      source = CancelToken.source();
      setStorage('token', '');
      setStorage('oHotelId', '');
      setStorage("hotelId", '')
      setStorage('userInfo', {})
      location.reload()
    }, 2000)
    return Promise.reject('无权限')
  } else if (!url.includes('http') && code !== 0 && code != 'SUCCESS') {

    if (urls.indexOf(url) == -1 && response.config.showMsg) {
      Message.error({
        showClose: true,
        message: msg || '请求错误'
      })
    }
    return Promise.reject(response.data)
  }
  return data;
}, function (error) {

  let msg = '网络错误，请重试';
  if (error == "Cancel") {
    return new Promise(() => { });
  }
  if ((typeof error === 'string') && (error.indexOf('timeout') > -1)) {
    msg = '请求超时，请重试!'
  }

  Message.error({
    showClose: true,
    message: error || msg
  })
  return Promise.reject(error || msg);
});

export const ajax = function ({
  baseURL,
  url = '',
  method = 'get',
  params = {},
  data = {},
  headers = {},
  timeout = 10000,
  showMsg = true
}) {
  return instance({
    baseURL,
    url,
    method: method || 'get',
    params: params || {},
    data: data || {},
    headers: headers || {},
    timeout: timeout,
    showMsg: showMsg
  })
}
