import calendar from './calendar';
import {nWeekMap} from '../const'
var dateFormat = {
  formatDate: function (date, fmt) {
    if (/(y+)/.test(fmt)) {
      fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
    }
    let o = {
      'M+': date.getMonth() + 1,
      'd+': date.getDate(),
      'h+': date.getHours(),
      'm+': date.getMinutes(),
      's+': date.getSeconds()
    }
    for (let k in o) {
      if (new RegExp(`(${k})`).test(fmt)) {
        let str = o[k] + ''
        fmt = fmt.replace(RegExp.$1, RegExp.$1.length === 1 ? str : this.padLeftZero(str))
      }
    }
    return fmt
  },
  formatBookDate: function (date,onlyLunar) {
    if (!date) {
      return ''
    }
    const dateObj = new Date(date)

    const month = dateObj.getMonth() + 1
    const day = dateObj.getDate()
    const year = dateObj.getFullYear()
    const lunar = calendar.solar2lunar(year, month, day)
    const week = nWeekMap[lunar.nWeek !== 7 ? lunar.nWeek : 0]
    if(onlyLunar){
        return ` ${lunar.IMonthCn}月${lunar.IDayCn} ${week}`
    }
    return `${date} ${lunar.IMonthCn}月${lunar.IDayCn} ${week}`

  },
  formatTimeToHalf: function (start,end) {
    let time = ''
    if(start){
        const timeArr = start.split(':')
        console.log("timeArr",timeArr);
        if (timeArr && timeArr.length > 1) {
            if (parseInt(timeArr[1]) == 0) {
                time = this.addZero(parseInt(timeArr[0])) + ':00'
            }
            else if (parseInt(timeArr[1]) <= 30 && parseInt(timeArr[1]) > 0) {
                time = this.addZero(parseInt(timeArr[0])) + ':30'
            }
            else {
                time = this.addZero(parseInt(timeArr[0]) >= 23 ? '00' : this.addZero(parseInt(timeArr[0]) + 1)) + ':00'
            }
        }
    }else if(end){
        const timeArr = end.split(':')
        if (timeArr && timeArr.length > 1) {
            if (parseInt(timeArr[1]) == 0) {
                time = this.addZero(parseInt(timeArr[0])) + ':00'
            }
            else if (parseInt(timeArr[1]) < 30 && parseInt(timeArr[1]) > 0) {
                time = this.addZero(parseInt(timeArr[0])) + ':00'
            }
            else {
                time = this.addZero(parseInt(timeArr[0])) + ':30' 
            }
        }
    }

    return time
},
addZero: function (val) {
    if (parseInt(val) < 10) {
        return '0' + parseInt(val)
    }
    return parseInt(val) + ''
},
format: (date) => {
    if (!(date instanceof Date)) {
        if (date instanceof String) date = date.replace(/-/g, '/')
        date = new Date(date)
    }
    let year = date.getFullYear()
    let month = date.getMonth()
    month += 1
    month = month < 10 ? `0${month}` : month
    let day = date.getDate()
    day = day < 10 ? `0${day}` : day
    let hours = date.getHours()
    hours = hours < 10 ? `0${hours}` : hours
    let minutes = date.getMinutes()
    minutes = minutes < 10 ? `0${minutes}` : minutes

    return {
      time:`${hours}:${minutes}`,
      timeNum:parseInt(`${hours}${minutes}`),
      timeDate:`${year}/${month}/${day} ${hours}:${minutes}`
    }
},
listBetween: (start, end, interval) => {
    if (!(start instanceof Date)) start = new Date(start)
    if (!(end instanceof Date)) end = new Date(end)
    let sDate = start
    let eDate = end
    let sMilli = sDate.getTime()
    let eMilli = eDate.getTime()
    let step = Math.floor((eMilli - sMilli) / interval)
    let res = []
    res.push(dateFormat.format(sDate))
    let pointer = sMilli
    for (let i = 0; i < step; ++i) {
        pointer += interval
        res.push(dateFormat.format(pointer))
    }
    return res
},

//将时间列表往前截断 提供一个列表 返回结果列表
cutBefore: (list, cutPoint) => {
    if (!(cutPoint instanceof Date)) cutPoint = new Date(cutPoint)
    let res = []
    let cutPointMilli = cutPoint.getTime()
    for (let i = 0; i < list.length; ++i) {
        let temp = list[i]
        let tempMilli = new Date(temp).getTime()
        if (tempMilli >= cutPointMilli) {
            res.push(dateFormat.format(tempMilli))
        }
    }
    return res
}

}

export default dateFormat
