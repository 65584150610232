<template>
	<div class="section">
		<div class="header">
			<span class="title">已选择{{ tableList.length }}桌</span>
			<i class="el-icon-arrow-right title"></i>
			<span class="ml-5 sub-title">预订</span>
			<div @click="handleCloseIdle" class="close">
				<i class="el-icon-close"></i>
			</div>
		</div>
		<div class="main">
			<div class="head">
				<div class="date">
					{{ resvDate }} | {{ mealIdMap[mealTypeId].mealTypeName }}
				</div>

				<div class="table">
					<el-tag
						v-for="tag in selectedTables"
						:key="tag.id"
						closable
						class="mr-10 tag"
						@close="closeSelectTable(tag)"
					>
						{{ tag.table.areaName }} {{ tag.table.tableName }}
					</el-tag>
				</div>
			</div>
			<div class="minConsume" v-if="minConsumeStr">
				<span class="icon-minConsume"></span>
				<div
					class="flex-1 flex"
					id="consume-container"
					:class="{ 'consume-up': arrow, 'consume-down': !arrow }"
				>
					<span id="consume-txt"> {{ '最低消费:' + minConsumeStr }}</span>
				</div>

				<i
					v-if="showArrow"
					:class="{ 'el-icon-arrow-up': arrow, 'el-icon-arrow-down': !arrow }"
					class="mr-10"
					style="margin-top: 3px"
					@click="arrow = !arrow"
				></i>
			</div>
			<div class="info">
				<div class="page-sub-title">客户信息</div>
				<div class="form-item">
					<span class="form-label"> <span class="red">*</span> 客户号码 </span>
					<el-autocomplete
						v-model.trim="form.customerPhone"
						class="form-content flex-1"
						size="small"
						:trigger-on-focus="false"
						:fetch-suggestions="mohuSelectPhone"
						@blur="handleBlur"
						@select="handleSelectPhone"
						@input="filterInput('customerPhone', 'num')"
					>
						<template slot-scope="{ item }">
							<span>{{ item.name }}</span>
							<span class="ml-10">{{ item.phone }}</span>
						</template>
						<template #suffix>{{
							form.customerPhone.length + '/' + 11
						}}</template>
					</el-autocomplete>
				</div>
				<div class="form-item">
					<span class="form-label"> <span class="red">*</span> 客户姓名 </span>
					<el-input
						class="form-content flex-1"
						v-model="form.customerName"
						size="small"
						:maxlength="6"
						@input="filterInput('customerName')"
						show-word-limit
					></el-input>
				</div>
				<div class="form-item">
					<span class="form-label"> <span class="red">*</span> 客户性别 </span>
					<div class="form-content flex-1">
						<el-radio v-model="form.customerGender" :label="1">先生</el-radio>
						<el-radio v-model="form.customerGender" :label="0">女士</el-radio>
					</div>
				</div>
				<div class="page-sub-title mt-30">订单信息</div>

				<div class="form-item">
					<span class="form-label">
						<span class="red" v-if="mustArriveTime">*</span>
						到店时间
					</span>
					<el-select
						class="form-content flex-1"
						size="small"
						placeholder="请选择到店时间"
						v-model="form.arriveTime"
						:clearable="!mustArriveTime"
					>
						<el-option
							v-for="o in arriveTimeList"
							:key="o.timeDate"
							:label="o.time"
							:value="o.time"
						></el-option>
					</el-select>
				</div>
				<div class="form-item">
					<span class="form-label"> <span class="red">*</span> 用餐人数 </span>
					<div class="form-content flex-1">
						<el-input
							v-model="form.resvNum"
							size="small"
							:maxlength="4"
							:class="{
								highlight: highLight,
							}"
							@input="filterInput('resvNum', 'num', 'handleChangeResvNum')"
							placeholder="请输入用餐人数"
						>
							<template slot="append">人</template>
						</el-input>
						<div v-if="form.resvNum && form.tables.length > 1">
							<el-input
								placeholder="用餐人数"
								class="mt-10"
								v-model="table.resvNum"
								oninput="value=value.replace(/[^\d]/g,'')"
								@change="reCalcTotalResvNum('resvNum')"
								:maxlength="4"
								size="small"
								:class="{
									highlight1:
										parseInt(table.resvNum) >
										parseInt(tableIdMap[table.tableId].maxPeopleNum),
								}"
								v-for="table in form.tables"
								:key="table.id"
							>
								<template slot="prepend">{{ table.tableName }}</template>
								<template slot="append">人</template>
							</el-input>
						</div>
					</div>
				</div>
				<div class="form-item">
					<span class="form-label">
						<span class="red mr-5">*</span>预订类型
					</span>
					<el-select
						class="form-content flex-1"
						size="small"
						placeholder="请选择预订类型"
						v-model="form.resvTypeId"
					>
						<el-option
							v-for="o in resvTypeList"
							:key="o.resvTypeId"
							:label="o.resvTypeName"
							:value="o.resvTypeId"
						></el-option>
					</el-select>
				</div>
				<div class="form-item">
					<span class="form-label">定金</span>
					<div class="form-content flex-1 flex-end width-100 flex">
						<el-switch v-model="form.depositFlag" @change="changeDepositStatus">
						</el-switch>
					</div>
				</div>
				<div v-if="form.depositFlag">
					<div class="form-item">
						<span class="form-label">
							<span class="red">*</span> 定金金额
						</span>
						<el-input
							class="form-content flex-1"
							v-model="form.depositAmount"
							size="small"
							type="number"
							onKeypress="return(/[\d.]/.test(String.fromCharCode(event.keyCode)))"
							@input="filterInput('depositAmount', 'num')"
						></el-input>
					</div>
					<div class="form-item">
						<span class="form-label">
							<span class="red">*</span>
							支付方式
						</span>
						<el-select
							class="form-content flex-1"
							size="small"
							placeholder="请选择支付方式"
							v-model="form.payTypeId"
						>
							<el-option
								v-for="o in payTypeList"
								:key="o.id"
								:label="o.payTypeName"
								:value="o.id"
							></el-option>
						</el-select>
					</div>
					<div class="form-item">
						<span class="form-label">
							<span class="red">*</span> 收据编号
						</span>
						<el-input
							class="form-content flex-1"
							v-model="form.depositNo"
							size="small"
							@input="filterInput('depositNo', 'No')"
						></el-input>
					</div>
				</div>
				<div class="form-item">
					<span class="form-label"> 销售跟进人 </span>
					<el-select
						class="form-content flex-1"
						v-model="form.salerFollowId"
						filterable
						clearable
						placeholder="请选择"
						size="small"
					>
						<el-option
							v-for="item in userList"
							:key="item.id"
							:label="item.realName"
							:value="item.id"
						>
						</el-option>
					</el-select>
				</div>
				<div class="form-item">
					<span class="form-label"> 预订短信 </span>
					<div class="form-content flex-1">
						<el-radio v-model="form.isSendSms" :label="1">发送</el-radio>
						<el-radio v-model="form.isSendSms" :label="0">不发送</el-radio>
					</div>
				</div>
				<div class="form-item">
					<span class="form-label"> 预订备注 </span>
					<div class="form-content flex-1">
						<el-input
							type="textarea"
							:rows="3"
							placeholder="请输入内容"
							v-model="form.remark"
							:maxlength="255"
							resize="none"
							show-word-limit
						>
						</el-input>
					</div>
				</div>
				<related-table :tableIds="tableIds">
					<div
						class="form-item mb-16 ml-15"
						slot="container"
						slot-scope="scope"
					>
						<span class="form-label mr-0"> 关联宴会厅</span>
						<div class="form-content flex-1">
							<div v-for="(item, index) in scope.list" :key="index">
								【{{ item.relatedMeetingMealName }}】{{
									item.relatedMeetingTableName
								}}
							</div>
						</div>
					</div>
				</related-table>
			</div>
		</div>
		<div class="footer">
			<div class="content">
				<el-button size="small" class="btn" @click="handleCloseIdle"
					>取消</el-button
				>
				<el-button size="small" class="btn" type="primary" @click="confirmOrder"
					>预订</el-button
				>
			</div>
		</div>
		<el-dialog title="重复预订" :visible.sync="repeatBookVisible" width="560px">
			<div class="repeat-book-content">
				<div class="prompt">
					<i class="el-icon-warning blue-color"></i
					>当前客户已有预订，预订信息如下
				</div>
				<div class="goods">
					<el-row class="order-goods" v-for="(item, index) in repeatOrderList" :key="index">
						<el-col :span="4" class="order-good">
							<span class="icon-order-goods mr-5"></span>
							<span>订单{{ numberToChinese(index + 1) }}</span>
						</el-col>
						<el-col class="text-b" :span="20">
							<div class="mb-5">
								<span>销售跟进人：</span>
								<span>{{ item.salerFollowName || '-' }}</span>
							</div>
							<div>
								<span>桌位：</span>
								<span>{{ item.tableName }}</span>
							</div>
						</el-col>
					</el-row>
				</div>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="handleCancelRepeatVisible">取 消</el-button>
				<el-button type="primary" @click="handleConfirmRepeatVisible"
					>继续预定</el-button
				>
			</span>
		</el-dialog>
	</div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex'
import { tableSub } from '@/config/table'
import dateFormat from '@/utils/dateFormat'
import { get_userInfoList, get_allPayTypeList } from '@/api/config'
import { confirm_order, mohu_search_phone } from '@/api/resv'
import relatedTable from '@/components/relatedTable'
import { splitMeal } from '@/utils/splitMeal'
import { numberToChinese } from '@/utils/index'
import { get_zero_order_list } from '@/api/order'

const params = {
  orderAuth: 0,
  search: "",
  orderStatusBatchSearch: [],
  salerFollowIdSearch: [],
  mealTypeIdSearch: [],
  resvTypeIdSearch: [],
  customerGenderSearch: "",
  customerSearch: "",
  sidx: "",
  sord: "",
  resvDateStart:'',
  resvDateEnd: '',
  resvDateTime: "",
  tableAreaIdSearch: [],
};

const form = {
	hotelId: '',
	customerName: '',
	customerSurname: '',
	customerPhone: '',
	customerGender: 1,
	resvNum: '',
	resvDate: '',
	mealTypeId: '',
	arriveTime: '',
	resvTypeId: '',
	remark: '',
	salerFollowId: '',
	createClient: '',
	creatorId: '',
	isSendSms: 1,
	orderStatus: 1,
	tables: [],
	depositFlag: false,
	depositAmount: '',
	depositNo: '',
	payTypeId: '',
}
import { getFirstName } from '@/utils/getFirstName'
import { validateNull, isValidateMobile } from '@/utils/validate'
export default {
	name: 'book',
	components: {
		relatedTable,
	},
	data() {
		return {
			form: this.$deepClone(form),
			timeout: null,
			userList: [],
			payTypeList: [],
			arrow: false,
			showArrow: false,
      selectPhone: {},
      repeatOrderList: [],
			repeatBookVisible: false,
		}
	},
	props: {
		minScreen: {
			type: Boolean,
			default: false,
		},
	},
	mounted() {
		this.init()
	},
	computed: {
		...mapState(['userInfo', 'createClient']),
		...mapState('resvInfo', [
			'selectedTables',
			'mealTypeDate',
			'resvTypeList',
			'mealTypeId',
			'currentTime',
		]),
		...mapGetters('resvInfo', [
			'mealIdMap',
			'tableIdMap',
			'zeroHotelOtherConfigMap',
		]),
		mustArriveTime() {
			return this.zeroHotelOtherConfigMap['ZERO_ARRIVE_TIME_CONFIG'] == 1
				? true
				: false
		},
		tableList() {
			return this.selectedTables.map((v) => v.table)
		},
		tableIds() {
			return this.selectedTables.map((item) => {
				return item.table.id + ''
			})
		},
		resvDate() {
			return dateFormat.formatBookDate(this.mealTypeDate)
		},
		arriveTimeList() {
			const list = this.mealIdMap[this.mealTypeId]
				? this.mealIdMap[this.mealTypeId].timeList
				: []
			return list.filter((v) => {
				const nowTime = this.$moment(this.currentTime).format(
					'YYYY-MM-DD HH:mm'
				)
				const mealTypeDate = this.$moment(this.mealTypeDate).format(
					'YYYY-MM-DD HH:mm'
				)
				if (this.$moment(mealTypeDate).isAfter(nowTime)) {
					return v
				} else if (this.$moment(v.timeDate).isAfter(nowTime)) {
					return v
				}
			})
		},
		minConsumeStr() {
			let str = []
			this.selectedTables.map((o) => {
				if (o.table.minConsume) {
					str.push(`【${o.table.tableName}】${o.table.minConsume}元`)
				}
			})

			return str.join(';')
		},
		highLight() {
			let flag = false
			if (this.form.tables.length == 1) {
				flag =
					this.form.resvNum &&
					parseInt(this.form.resvNum) >
						parseInt(
							this.tableIdMap[this.selectedTables[0].table.id].maxPeopleNum
						)
			} else {
				for (let i = 0; i < this.form.tables.length; i++) {
					const table = this.form.tables[i]
					if (
						parseInt(table.resvNum) >
						parseInt(this.tableIdMap[table.tableId].maxPeopleNum)
					) {
						flag = true
						break
					}
				}
			}

			return flag
		},
	},
	watch: {
		arriveTimeList: {
			handler(newValue) {
				if (newValue && newValue.length > 0) {
					this.form.mealTypeId = this.mealTypeId
					if (this.mustArriveTime) {
						this.form.arriveTime = newValue[0].time
					}
				}
			},
			immediate: true,
		},
		minConsumeStr: {
			handler(newValue) {
				if (newValue) {
					this.$nextTick(() => {
						const dom = document
							.getElementById('consume-txt')
							.getBoundingClientRect()
						if (dom && dom.width) {
							if (this.minScreen && dom.width >= 258) {
								this.showArrow = true
							} else if (!this.minScreen && dom.width >= 331) {
								this.showArrow = true
							} else {
								this.showArrow = false
							}
						} else {
							this.showArrow = false
						}
					})
				}
			},
			immediate: true,
		},
	},
	methods: {
		...mapMutations('resvInfo', ['SET_SIDEBAR_TYPE']),
    numberToChinese,
		init() {
			this.form.tables = this.tableList.map((o) => {
				return {
					tableAreaId: o.tableAreaId,
					tableAreaName: o.areaName,
					tableId: o.id,
					tableName: o.tableName,
					resvNum: o.resvNum,
				}
			})
			// this.form.resvNum = this.tableList.length
			this.form.creatorId = this.userInfo.id
			this.form.createClient = this.createClient
			this.handleChangeResvNum(this.form.resvNum)
			this.form.resvDate = this.mealTypeDate

			if (this.resvTypeList.length > 0) {
				this.form.resvTypeId = this.resvTypeList[0].resvTypeId
			}

			this.getUserList()
			this.getPayTypeList()
		},
		getUserList() {
			get_userInfoList({
				hotelId: this.userInfo.fkOrgId,
				searchKey: '',
			}).then((res) => {
				this.userList = res || []
				// this.form.salerFollowId = this.userInfo.id
				// this.form.salerFollowName = this.userInfo.realName
			})
		},

		getPayTypeList() {
			get_allPayTypeList({
				hotelId: this.userInfo.fkOrgId,
				searchKey: '',
			}).then((res) => {
				this.payTypeList = res.startList || []
				if (this.payTypeList.length > 0) {
					this.form.payTypeId = this.payTypeList[0].id
				}
			})
		},
		handleCloseIdle() {
			this.$confirm('此操作将取消预订, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
			})
				.then(() => {
					this.SET_SIDEBAR_TYPE('idle')
				})
				.catch(() => {})
		},
		filterInput(val, type, cb) {
			// console.log(val, type, this.form[val])
			if (this.form[val]) {
				this.form[val] = this.form[val].trim()
				if (type == 'num') {
					this.form[val] = this.$requireDecimalDigit(this.form[val])
				}
				if (val == 'customerPhone') {
					this.form[val] = this.form[val].replace(/\D/g, '')
					this.form[val] = this.form[val].slice(0, 11)
				}
				if (type == 'No') {
					this.form[val] = this.form[val]
						.replace(
							/[^\x00-\x7f\u3002|\uff1f|\uff01|\uff0c|\u3001|\uff1b|\uff1a|\u201c|\u201d|\u2018|\u2019|\uff08|\uff09|\u300a|\u300b|\u3008|\u3009|\u3010|\u3011|\u300e|\u300f|\u300c|\u300d|\ufe43|\ufe44|\u3014|\u3015|\u2026|\u2014|\uff5e|\ufe4f|\uffe5]/g,
							''
						)
						.replace(
							/(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|\uFE0F|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g,
							''
						)
				}
			}
			if (cb) {
				try {
					this[cb](this.form[val])
				} catch (error) {}
			}
		},
		reCalcTotalResvNum(val) {
			this.form[val] = 0
			this.form.tables.map((table) => {
				this.form[val] += parseInt(table.resvNum || 0)
			})
		},
		handleChangeResvNum() {
			const num = Number(this.form.resvNum)
			const tablesNum = this.form.tables.length
			const per = Math.floor(num / tablesNum)
			const yu = num - per * tablesNum
			for (var i = 0; i < tablesNum; i++) {
				if (i < yu) {
					this.$set(this.form.tables, i, {
						tableAreaId: this.form.tables[i].tableAreaId,
						tableAreaName: this.form.tables[i].tableAreaName,
						tableId: this.form.tables[i].tableId,
						tableName: this.form.tables[i].tableName,
						resvNum: per + 1,
					})
				} else {
					this.$set(this.form.tables, i, {
						tableAreaId: this.form.tables[i].tableAreaId,
						tableAreaName: this.form.tables[i].tableAreaName,
						tableId: this.form.tables[i].tableId,
						tableName: this.form.tables[i].tableName,

						resvNum: per,
					})
				}
			}
			console.log('vvava', this.form, this.form.tables)
		},
		mohuSelectPhone(queryString, cb) {
			let results = []
			clearTimeout(this.timeout)
			if (!this.form['customerPhone']) {
				if (cb) {
					cb([])
				}
			} else if (
				this.form['customerPhone'].length >= 6 &&
				this.form['customerPhone'].length <= 11
			) {
				this.timeout = setTimeout(() => {
					mohu_search_phone({
						hotelId: this.userInfo.fkOrgId,
						phone: queryString,
					})
						.then((res) => {
							results = res || []
							if (cb) {
								cb(results)
							}
						})
						.catch((err) => {
							if (cb) {
								cb([])
							}
						})
				}, 300)
			} else {
				if (cb) {
					cb([])
				}
			}
		},
		handleBlur(e) {
      this.handleDimCustomerList(e.target.value, 'blur')
		},
    handleDimCustomerList(val, type) {
      const searchParams = this.$deepClone(params)
      if(val.length == 11) {
        // console.log(this.form)
        get_zero_order_list({
          ...searchParams,
          customerSearch: val,
          mealTypeIdSearch: [this.mealTypeId],
          hotelId: this.userInfo.fkOrgId,
          resvDateEnd: this.form.resvDate || this.mealTypeDate,
          resvDateStart: this.form.resvDate || this.mealTypeDate,
          orderStatusBatchSearch: [1, 2],
          sidx: 'createTime',
          sord: 'asc',
          pageNo: 1,
          size: 999
        }).then(res => {
          this.repeatOrderList = res.records || []
          if(this.repeatOrderList.length > 0) {
            this.repeatBookVisible = true;
          }else {
            if(type === 'select') {
              this.handleConfirmSelectPhone(this.selectPhone)
            }
          }
        })

      }
    },
    handleCancelRepeatVisible() {
      this.repeatBookVisible = false;
      this.handleCancelSelectPhone()
    },
    handleConfirmRepeatVisible() {
      this.repeatBookVisible = false;
      this.handleConfirmSelectPhone(this.selectPhone)
    },
		handleSelectPhone(val) {
      // console.log(val)
      this.handleConfirmSelectPhone(val)
      this.handleDimCustomerList(val.phone, 'select')
      this.selectPhone = val
		},
    handleCancelSelectPhone() {
      this.form.customerPhone = ''
			this.form.customerName = ''
			this.form.customerSurname = ''
			this.form.customerGender = 1
    },
    handleConfirmSelectPhone(val) {
      this.repeatBookVisible = false
			this.form.customerPhone = val.phone || ''
			this.form.customerName = val.name || ''
			this.form.customerSurname = getFirstName(val.name) || ''
			this.form.customerGender = val.gender
    },
		closeSelectTable(t) {
			tableSub.selectTable(t.id)
			if (this.selectedTables.length > 0) {
				this.SET_SIDEBAR_TYPE('book')
			} else {
				this.SET_SIDEBAR_TYPE('idle')
			}
		},
		changeDepositStatus() {
			if (this.form.depositFlag && this.payTypeList.length > 0) {
				this.form.payTypeId = this.payTypeList[0].id
			}
		},
		confirmOrder() {
			let form = this.$deepClone(this.form)
			const mealType = splitMeal(this.mealTypeId)
			form = {
				...form,
				...mealType,
			}
			form.hotelId = this.userInfo.fkOrgId
			console.log(isValidateMobile(form.customerPhone))
			let resvNumSum = 0
			form.tables.map((t) => {
				resvNumSum += parseInt(t.resvNum || 0)
			})
			console.log('resvNum', resvNumSum, form.resvNum)
			if (!isValidateMobile(form.customerPhone).result) {
				return this.$message.warning(isValidateMobile(form.customerPhone).msg)
			} else if (validateNull(form.customerName)) {
				return this.$message.warning('请输入正确的客户姓名')
			} else if (validateNull(form.customerGender) || form.customerGender < 0) {
				return this.$message.warning('请选择正确的客户性别')
			} else if (validateNull(form.customerGender)) {
				return this.$message.warning('请选择客户性别')
			} else if (validateNull(form.arriveTime) && this.mustArriveTime) {
				return this.$message.warning('请选择到店时间')
			} else if (validateNull(form.resvNum)) {
				return this.$message.warning('请输入用餐人数')
			} else if (
				this.selectedTables.length > 1 &&
				parseInt(form.resvNum) != resvNumSum
			) {
				return this.$message.warning('用餐人数必须等于各桌数人数相加')
			} else if (isNaN(parseInt(form.resvNum))) {
				return this.$message.warning('请输入正确的用餐人数')
			} else if (validateNull(form.resvTypeId)) {
				return this.$message.warning('请选择预订类型')
			} else if (validateNull(form.tables)) {
				return this.$message.warning('请选择桌位')
			} else if (form.depositFlag) {
				if (validateNull(form.depositAmount) && !form.depositAmount) {
					return this.$message.warning('定金信息未填写完整')
				}
				if (validateNull(form.depositNo)) {
					return this.$message.warning('定金信息未填写完整')
				}
				if (validateNull(form.payTypeId)) {
					return this.$message.warning('定金信息未填写完整')
				}
			}

			if (form.depositFlag == 0) {
				form.depositAmount = '0'
				form.depositNo = ''
				form.payTypeId = ''
			}

			if (validateNull(form.salerFollowId)) {
				form.salerFollowId = -1
			}

			let flag = null
			for (let i = 0; i < form.tables.length; i++) {
				const element = form.tables[i]
				if (!element.resvNum) {
					flag = element.tableName
					break
				}
			}

			if (flag) {
				return this.$message.warning('请输入' + flag + '的用餐人数')
			}
			form.customerSurname = getFirstName(form.customerName)
			console.log(form)
			confirm_order(form)
				.then((res) => {
					this.$message.success('预订成功')

					tableSub.selectTable([])
					this.SET_SIDEBAR_TYPE('idle')
          			this.$Bus.$emit('refresh')
				})
				.catch((err) => {
					this.SET_SIDEBAR_TYPE('idle')
					tableSub.selectTable([])
					this.$Bus.$emit('refresh')
					console.log(err)
				})
		},
	},
}
</script>

<style scoped lang="scss">
.section {
	display: flex;
	flex-direction: column;
	overflow: auto;
	height: 100%;
	.close,
	.close-table {
		position: absolute;
		height: 40px;
		width: 40px;
		top: 0;
		cursor: pointer;
		right: 0;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.header {
		height: 40px;
		border-bottom: 1px solid rgba(51, 51, 51, 0.1);
		flex: 0 0 40px;
		padding: 0 18px;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		position: relative;
		font-size: 16px;
		color: #000;

		.title {
			color: #a4a4a4;
		}
	}
	.main {
		width: 100%;
		flex: 1 1 auto;
		position: relative;
		padding: 0 10px;
		box-sizing: border-box;
		display: flex;
		overflow: auto;
		flex-direction: column;
		.head {
			width: 100%;
			background: rgb(244, 249, 255);
			border-radius: 8px;
			.date {
				min-width: 65%;
				max-width: 80%;
				font-size: 15px;
				color: #fff;
				text-align: center;
				background: #409eff;
				margin: 0 auto;
				padding: 10px;
				border-bottom-left-radius: 6px;
				border-bottom-right-radius: 6px;
			}
			.table {
				margin: 10px;
				.tag {
					margin-bottom: 5px;
					background: #e6edf6;
					border-color: #e6edf6;
					color: #4077b4;
				}
				:deep(.el-tag .el-tag__close:hover) {
					color: #4077b4;
					background: rgba($color: #4077b4, $alpha: 0.1);
				}
			}
		}
		.info {
			width: 100%;
			margin-top: 20px;
			.form-item {
				margin-bottom: 16px;
				display: flex;
				justify-content: center;
				align-items: flex-start;
				.form-label {
					line-height: 32px;
					width: 81px;
					text-align: right;
					margin-right: 20px;
				}
				.form-content {
					min-height: 32px;
					line-height: 32px;
					::v-deep .el-input__suffix-inner {
						background: #fff;
						font-size: 12px;
						color: #909399;
						padding: 0 5px;
					}
				}
				:deep(.el-input-group__prepend) {
					width: 85px;
					max-width: 85px;
					text-overflow: ellipsis;
					white-space: nowrap;
					overflow: hidden;
					padding: 0px 8px;
					background: #f5f6fb;
					border-color: #ebeef5;
					border-radius: 2px;
					color: #526177;
				}
				:deep(.el-input-group__append) {
					padding: 0 10px;
					background: transparent;
				}
			}
		}
	}
	.footer {
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 20px 0;
		.content {
			width: 72%;
			display: flex;
			justify-content: space-between;
			align-items: center;
			.btn {
				width: 120px;
			}
		}
	}
}
:deep(.el-textarea__inner) {
	border-radius: 0;
	padding-bottom: 20px;
	&:hover {
		border: 1px solid #409eff;
	}
}
:deep(.el-textarea .el-input__count) {
	width: 92%;
	text-align: right;
	bottom: 1px;
	padding-bottom: 7px;
}

:deep(.highlight) {
	position: relative;
	input {
		color: red;
	}
	&::after {
		content: '\e6c9超出容纳人数';
		position: absolute;
		font-family: element-icons;
		color: red;
		right: 40px;
		top: 50%;
		font-size: 14px;
		margin-top: -10px;
	}
}
:deep(.highlight1) {
	input {
		color: red;
	}
}
.minConsume {
	display: flex;
	align-items: flex-start;
	background: #fff;
	color: #888;
	padding-left: 12px;
	padding-top: 5px;
	padding-bottom: 5px;
	background: #f7f7f7;
	margin-top: 10px;
}

.consume-down {
	flex-wrap: nowrap;
	height: 20px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	display: inline-block;
}
.consume-up {
	flex-wrap: wrap;
}
#consume-txt {
	font-size: 12px;
}
.icon-minConsume {
	margin-right: 5px;
	margin-top: 2px;
	display: inline-block;
	background: url('../../../../assets/icons/icon-minConsume.png') 100% 100%
		no-repeat;
	background-size: contain;
	width: 16px;
	height: 16px;
}
.repeat-book-content {
	.prompt {
		// margin: 16px 24px 12px 24px;
		padding: 5px 0;
		border-radius: 4px;
		text-align: center;
		border: 1px solid #e1eef9;
		background: #f4f9ff;
		.blue-color {
			color: #88a5c2;
			margin-right: 10px;
		}
	}
	.goods {
		min-height: 400px;
		max-height: 500px;
		overflow-y: auto;
	}
	.order-goods {
		padding: 12px 0;
		border-bottom: 1px solid #f1f1f1;
		&:last-child {
			border-bottom: 0;
		}
		.order-good {
			display: flex;
			align-items: center;
			color: #888;
		}
		.text-b {
			color: #333;
		}
	}
	.icon-order-goods {
		display: inline-block;
		width: 16px;
		height: 16px;
		background: url('../../../../assets/icons/icon-order-goods.png') no-repeat
			center center/100% 100%;
	}
}
</style>
