<template>
	<div
		:class="['table-item', tableChange ? 'in-change-table' : '']"
		:style="{
			borderColor: selected || tableChange ? statusColor.selectColor : '',
		}"
	>
		<div
			class="table-content"
			@click="selectTable"
			:style="{
				background: statusColor.backgroundColor,
			}"
		>
			<div
				class="table-status-border"
				v-if="table.tableName.length <= 6"
				:style="{
					background: statusColor.color,
					width: 90 * scale + 'px',
					left: '50%',
					marginLeft: -45 * scale + 'px',
				}"
			>
				{{ table.tableName }}
			</div>
			<div
				class="table-status-border"
				v-else
				:style="{
					background: statusColor.color,
					width: 120 * scale + 'px',
					left: '50%',
					marginLeft: -60 * scale + 'px',
				}"
			>
				{{ table.tableName }}
			</div>

			<div class="table-info">
				<div
					v-if="hasCustomer"
					class="table-customer text-center"
					:style="{
						'flex-wrap':
							orderData.customerName && orderData.customerName.length >= 4
								? 'wrap'
								: 'nowrap',
					}"
				>
					<div class="mute-tag" v-if="mute">
						<el-tooltip class="item" effect="dark" placement="top">
							<span slot="content">
								<div>连桌订单</div>
							</span>

							<svg
								t="1659690131041"
								class="icon"
								viewBox="0 0 1025 1024"
								version="1.1"
								xmlns="http://www.w3.org/2000/svg"
								p-id="4006"
								width="14"
								height="14"
								xmlns:xlink="http://www.w3.org/1999/xlink"
							>
								<path
									:fill="muteColor"
									d="M768.512 256l-128 0c-35.392 0-64 28.608-64 64 0 35.328 28.608 64 64 64l128 0c70.656 0 128 57.344 128 128s-57.344 128-128 128l-128 0c-35.392 0-64 28.608-64 64s28.608 64 64 64l128 0c141.376 0 256-114.624 256-256S909.888 256 768.512 256zM384.512 640l-128 0c-70.656 0-128-57.344-128-128s57.344-128 128-128l128 0c35.392 0 64-28.672 64-64 0-35.392-28.608-64-64-64l-128 0c-141.376 0-256 114.624-256 256s114.624 256 256 256l128 0c35.392 0 64-28.608 64-64S419.904 640 384.512 640zM320.512 512c0 35.392 28.608 64 64 64l256 0c35.392 0 64-28.608 64-64s-28.608-64-64-64l-256 0C349.12 448 320.512 476.608 320.512 512z"
									p-id="4007"
								></path>
							</svg>
						</el-tooltip>
					</div>

					<template v-if="orderData.customerPhone">
						<div
							class="customer-name text-ellipsis"
							:style="{
								color: statusColor.color,
							}"
						>
							{{ customerName }}
						</div>
						<div
							style="margin-left: 3px"
							:class="'icon-sex' + orderData.customerGender"
							v-if="[0, 1].includes(orderData.customerGender)"
						></div>
					</template>

					<template v-else>
						<div class="customer-name">散客</div>
					</template>
				</div>
				<div
					v-if="orderData.customerPhone"
					class="customer-phone"
					:style="{
						color: statusColor.color,
					}"
				>
					{{ ` (${orderData.customerPhone.toString().slice(-4)})` }}
				</div>
				<div class="table-people" v-if="status != 'lock'">
					<div v-if="status == 'idle'" class="font-size-16 flex flex-wrap flex-justify-center">
            <div>
						  1-{{ table.maxPeopleNum }}人
              <span v-if="(+table.maxTableNum > 0)" class="ml-5 mr-5"> / </span>
            </div>
            <div v-if="(+table.maxTableNum > 0)">
              1-{{ table.maxTableNum }}桌
            </div>
					</div>

					<div v-else class="font-size-14 flex flex-align-center">
						<span
							v-if="tableData.actualNum > 0"
							:style="{
								color:
									tableData.actualNum > table.maxPeopleNum ? 'red' : '#000',
							}"
							>{{ tableData.actualNum }}</span
						>
						<span
							v-else
							:style="{
								color: tableData.resvNum > table.maxPeopleNum ? 'red' : '#000',
							}"
							>{{ tableData.resvNum }}</span
						>
						人

						<el-tooltip class="item" effect="dark" placement="right">
							<span slot="content">
								<div>
									{{ tableData.remark }}
								</div>
							</span>
							<img
								src="../../../../assets/icons/icon-remark.png"
								alt=""
								width="17"
								class="ml-5"
								v-if="hasCustomer && tableData.remark"
							/>
						</el-tooltip>
					</div>
				</div>
				<div v-if="status === 'lock' && tableData.remark" class="lock-remark">
					<div class="txt">
						{{ tableData.remark }}
					</div>
				</div>
				<div v-if="status === 'lock'" class="lock-img">
					<img
						src="../../../../assets/icons/icon-table-lock.png"
						alt=""
						width="28"
					/>
				</div>
				<div v-if="!hasCustomer" class="table-blank"></div>
			</div>
			<div class="status-name">
        <el-tooltip
          v-if="!!tableData.confirm"
					class="item"
					effect="dark"
					placement="top"
				>
					<span slot="content">
						<div>
							餐前确认
						</div>
					</span>
					<span class="confirm-icon"></span>
				</el-tooltip>
				<el-tooltip
					class="item"
					effect="dark"
					placement="top"
					v-if="orderData.depositFlag"
				>
					<span slot="content">
						<div>
							定金
						</div>
					</span>

					<span class="deposit-icon"></span>
				</el-tooltip>

				<span :style="{ color: statusColor.darkColor }">
					{{
						tableData.salerFollowName ? tableData.salerFollowName : statusName
					}}
				</span>
				<el-tooltip
					class="item"
					effect="dark"
					placement="top"
					v-if="['book', 'sitting'].includes(status)"
				>
					<span slot="content">
						<div>
							{{
								tableData.createClient == 'ZERO_PAD' ? '预订台订单' : 'APP订单'
							}}
						</div>
					</span>

					<span class="ml-5">
						<svg
							t="1670569037637"
							class="order-from-icon"
							viewBox="0 0 1024 1024"
							version="1.1"
							xmlns="http://www.w3.org/2000/svg"
							p-id="3285"
							xmlns:xlink="http://www.w3.org/1999/xlink"
							width="13"
							height="13"
							v-if="tableData.createClient == 'ZERO_PAD'"
						>
							<path
								d="M872.8 112H151.2c-48.1 0-87.2 39.6-87.2 88.3V650c0 48.7 39.1 88.3 87.2 88.3h298.6L290 912h444L574.2 738.3h298.6c48.1 0 87.2-39.6 87.2-88.3V200.3c0-48.7-39.1-88.3-87.2-88.3z m-745.4 88.3c0-13.3 10.7-24.1 23.8-24.1h721.6c13.1 0 23.8 10.8 23.8 24.1v305.1H127.4V200.3z"
								:fill="statusColor.color"
								p-id="3286"
							></path>
						</svg>
						<svg
							version="1.1"
							v-else
							class="order-from-icon"
							xmlns="http://www.w3.org/2000/svg"
							xmlns:xlink="http://www.w3.org/1999/xlink"
							viewBox="0 0 26 26"
							width="13"
							height="13"
							xml:space="preserve"
						>
							<path
								:fill="statusColor.color"
								d="M5,2.1h16c0.8,0,1.5,0.7,1.5,1.5v0v19c0,0.8-0.7,1.5-1.5,1.5c0,0,0,0,0,0H5c-0.8,0-1.5-0.7-1.5-1.5l0,0v-19
	C3.5,2.7,4.2,2.1,5,2.1z M13,22.5c0.8,0,1.5-0.7,1.5-1.5c0-0.8-0.7-1.5-1.5-1.5c0,0,0,0,0,0c-0.8,0-1.5,0.7-1.5,1.5
	C11.5,21.8,12.2,22.5,13,22.5z M7.2,5C6.8,5,6.4,5.3,6.4,5.7v0v10.9c0,0.4,0.3,0.7,0.7,0.7h11.7c0.4,0,0.7-0.3,0.7-0.7V5.7
	c0-0.4-0.3-0.7-0.7-0.7C18.8,5,7.2,5,7.2,5z"
							/>
						</svg>
					</span>
				</el-tooltip>
			</div>
		</div>
	</div>
</template>

<script>
import { orderStatusCode, statusColor } from '../../../../const'
import { tableSub } from '../../../../config/table'
import { mapMutations, mapState, mapGetters } from 'vuex'
import { getFirstName } from '@/utils/getFirstName'
export default {
	name: 'table-item',
	props: ['table', 'id', 'scale'],
	data() {
		return {
			status: 'idle',
			orderData: {},
			tableData: {},
			selected: false,
			mute: false,
			muteColor: '',
			// 换桌
			inChangeTable: false,
		}
	},
	computed: {
		...mapGetters('resvInfo', ['zeroHotelOtherConfigMap']),
		...mapState('resvInfo', ['mealTypeDate', 'mealTypeId']),
		hiddenCustomerName() {
			return this.zeroHotelOtherConfigMap[
				'ZERO_OTHER_CONFIG_TABLE_CUSTOMER_NAME'
			] == 1
				? 0
				: 1
		},
		customerName() {
			return this.hiddenCustomerName
				? getFirstName(this.orderData.customerName) +
						(this.orderData.customerGender == 1 ? '先生' : '女士')
				: this.orderData.customerName
		},
		statusColor() {
			switch (this.status) {
				case 'book':
					return statusColor['book']
				case 'sitting':
					return statusColor['sitting']
				case 'lock':
					return statusColor['lock']
				default:
					return statusColor['default']
			}
		},
		statusName() {
			switch (this.status) {
				case 'book':
					return '已预订'
				case 'sitting':
					return '已入座'
				case 'lock':
					return '已锁台'
				default:
					return '空闲'
			}
		},
		hasCustomer() {
			const arr = ['book', 'sitting']
			return arr.includes(this.status)
		},
		tableChange() {
			const table = tableSub.orderChangeTable
			const orderChangeTable = table && table.length > 0 ? table[0] : {}
			console.log('orderChangeTable', orderChangeTable)
			return (
				this.inChangeTable &&
				orderChangeTable.resvDate == this.mealTypeDate &&
				orderChangeTable.mealTypeId == this.mealTypeId
			)
		},
	},
	methods: {
		...mapMutations('resvInfo', ['SET_TABLE_TRANS', 'SET_TABLE_CHANGE_TYPE']),
		selectTable() {
			this.handleTable()
		},
		handleTable() {
			console.log(this.tableData)
			if (this.tableChange) {
				//换桌模式   相同日期  相同餐段

				return
			}
			const select = tableSub.selectTable(this.id)

			if (this.selected !== select) {
				this.selected = select
			}
		},
		getMessage(type, data) {
			if (type === 'state') {
				return this.getStateMessage(data)
			}
			if (type === 'select') {
				return this.getSelectedMessage(data)
			}
			if (type === 'getState') {
				return data.includes(this.status)
					? {
							...this.table,
							orderData: this.orderData,
					  }
					: false
			}
			if (type === 'getStatus') {
				return this.status
			}
			if (type === 'getSelectTable') {
				const select = {
					order: {
						...this.orderData,
					},
					status: this.status,
					id: this.id,
					table: this.table,
				}
				return select
			}
			if (type === 'orderChangeTable') {
				this.inChangeTable = true
				return data
			}
			if (type === 'orderCancelTable') {
				this.inChangeTable = false
			}
		},
		getMutiColor(id) {
			const num = id.slice(-2)
			const idx = parseInt(num) % 20
			const colorArr = [
				'#5a9cff',
				'#8f7de9',
				'#de5cd6',
				'#e64287',
				'#e33d3d',
				'#f9894b',
				'#f2ba58',
				'#92d641',
				'#35d789',
				'#49d5d7',
				'#1685a9',
				'#2e4e7e',
				'#426666',
				'#815476',
				'#cca4e3',
				'#b36d61',
				'#75664d',
				'#725e82',
				'#a78e44',
				'#21a675',
			]
			return colorArr[idx]
		},
		// 处理桌位状态变更的消息
		getStateMessage(data) {
			this.orderData = {
				...data,
			}
			const tables = this.orderData.orderTables
			if (!tables || tables.length == 0) {
				this.tableData = {}
				this.status = 'idle'
				return
			} else if (tables.length > 1) {
				this.mute = true
				this.muteColor = this.getMutiColor(tables[0].orderNo)
			} else {
				this.mute = false
				this.muteColor = ''
			}

			const table = tables.find((v) => v.tableId == this.id)
			this.tableData = {
				...table,
			}
			const status = table.orderStatus

			let statusStr = this.getStatusStr(status)

			console.log('aaastatus', statusStr)
			if (this.status !== statusStr) {
				this.status = statusStr
			}
		},
		// 处理桌位选择的信息
		getSelectedMessage(bool) {
			if (this.selected !== bool) {
				this.selected = bool
			}
		},
		getStatusStr(status) {
			let statusStr = ''
			switch (status) {
				case null:
					statusStr = 'idle'
					break
				case orderStatusCode.SET_IN:
					statusStr = 'sitting'
					break
				case orderStatusCode.RESERVE:
					statusStr = 'book'
					break
				case orderStatusCode.LOCK:
					statusStr = 'lock'
					break
				default:
					statusStr = 'idle'
			}

			return statusStr
		},
	},
	mounted() {
		tableSub.addTableListener(this.id, this.getMessage)
	},
}
</script>

<style scope lang="scss">
.table-item {
	position: relative;
	box-sizing: border-box;
	border-radius: 6px;
	border: 2px solid transparent;
	width: 142px;
	height: 142px;
	cursor: pointer;
	font-size: 14px;

	&.in-change-table {
		border: 2px dashed transparent;
	}
	.table-content {
		border-radius: 6px;
		width: 100%;
		height: 100%;
		background: rgb(247, 247, 247);
		box-sizing: border-box;
		position: relative;
		pointer-events: auto;
		display: flex;
		flex-direction: column;
		.table-status-border {
			position: absolute;
			height: 24px;
			line-height: 24px;
			font-size: 13px;
			text-align: center;
			border-bottom-right-radius: 6px;
			border-bottom-left-radius: 6px;
			background: #a4a4a4;
			color: #fff;
			white-space: inherit;
		}
		.table-info {
			padding-top: 24px;
			flex: 1;
			display: flex;
			flex-direction: column;
			flex-wrap: nowrap;
			margin: 5px;
			.table-people {
				padding: 5px 0;
				color: #333;
				display: flex;
				flex-wrap: nowrap;
				justify-content: center;
				align-items: center;
			}
			.table-blank {
				flex: 1;
			}
			.mute-tag {
				overflow: hidden;
				width: 14px;
				margin-right: 5px;
				margin-top: 2px;
				display: flex;
				align-items: center;
				justify-content: center;
			}
			.table-customer {
				position: relative;
				display: flex;
				padding: 5px 0;
				flex-wrap: nowrap;
				justify-content: center;
				align-items: center;
				.customer-name {
					color: #333;
					max-width: 100%;
					font-size: 16px;
					font-weight: bold;
				}
			}
			.customer-phone {
				color: #333;
				font-size: 14px;
				margin-left: 5px;
				text-align: center;
			}
			.lock-remark {
				margin-top: 5px;
				font-size: 14px;
				color: #807778;
				background: #f4eced;
				border-radius: 4px;
				padding: 0px 3px;
				height: 38px;
				display: flex;
				justify-content: center;
				align-items: center;
				.txt {
					overflow: hidden;
					text-align: center;
					-webkit-line-clamp: 2;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-box-orient: vertical;
				}
			}
			.lock-img {
				margin: 3px auto 0 auto;
			}
		}

		.status-name {
			width: 100%;
			max-width: 100%;
			text-align: center;
			font-size: 13px;
			margin: 0 0px 15px 0px;
			color: #888888;
			display: flex;
			align-items: center;
			justify-content: center;
		}
		.deposit-icon {
			background: url('../../../../assets/icons/icon-deposit.png') 100% 100%
				no-repeat;
			width: 16px;
			height: 16px;
			margin-right: 5px;
			margin-top: 1px;
			background-size: contain;
		}
    .confirm-icon {
			background: url('../../../../assets/icons/icon-confirm.png') 100% 100%
				no-repeat;
			width: 16px;
			height: 16px;
			margin-right: 5px;
			margin-top: 1px;
			background-size: contain;
		}
		.order-from-icon {
			display: flex;
			align-items: center;
			margin-top: 2px;
		}
	}
	.icon-sex1 {
		// 男
		display: inline-block;
		width: 14px;
		height: 14px;
		background: url('../../../../assets/icons/icon-male.png') no-repeat center
			center/100% 100%;
	}
	.icon-sex0 {
		// 女
		display: inline-block;
		width: 14px;
		height: 14px;
		background: url('../../../../assets/icons/icon-female.png') no-repeat center
			center/100% 100%;
	}
}
</style>
