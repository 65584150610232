var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"statistic"},_vm._l((_vm.statisticList),function(item){return _c('div',{key:item.label,staticClass:"statistic-item"},[_c('div',{staticClass:"icon",style:({
				background: _vm.ordersNumMap[item.type].color,
				border: '1px solid',
				borderColor:
					item.type == 'all' ? '#409EFF' : _vm.ordersNumMap[item.type].color,
			})}),_c('span',[_vm._v(_vm._s(item.label)+":")]),_c('span',{staticClass:"num"},[_vm._v(_vm._s(_vm.ordersNumMap[item.type].num))]),(item.type != 'all')?_c('span',{staticClass:"per",style:({
				color: _vm.ordersNumMap[item.type].color,
				background: _vm.ordersNumMap[item.type].backgroundColor,
			})},[_vm._v(_vm._s(_vm.ordersNumMap[item.type].per)+"%")]):_vm._e()])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }