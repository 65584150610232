import { ajax } from "@/api/request";

// 获取零点配置
export const get_zero_config = function (data) {
    return ajax({
        url: '/hotelConfig/v1/getZeroHotelResvConfig',
        method: 'get',
        params: data
    })
}

// 酒店配置通用修改
export const post_updateHotelConfig = function (data) {
    return ajax({
      method: 'post',
      url: '/hotelConfig/v2/updateHotelConfig',
      data: data
    });
  }

//  获取酒店最早餐段开始时间
export const get_firstMealType = function (data) {
    return ajax({
      method: 'get',
      url: '/hotelConfig/v1/getFirstMealTypeTime',
      params: data
    });
}

//  取当前用户权限下所有用户列表
export const get_userInfoList = function (data) {
    return ajax({
        method: 'get',
        url: '/user/v1/getUserInfoList',
        params: data
    });
}

// 获取配置中心全支付方式列表
export const get_allPayTypeList = function (data) {
  return ajax({
    method: 'get',
    url: '/hotelConfig/v1/getAllPayTypeList',
    params: data
  });
}

//获取酒店通用配置
export const get_hotelConfigList = function (data) {
    return ajax({
      method: 'get',
      url: '/hotelConfig/v1/getHotelConfigList',
      params: data
    });
  }
