import { ajax } from "@/api/request";

// 未读消息数量
export const get_unread_num = function (params) {
    return ajax({
        url: '/notice/v1/getZeroPadUnReadNoticeNum',
        method: 'get',
        params
    })
}

// 消息列表
export const get_message_list = function (params) {
    return ajax({
        url: '/notice/v1/getZeroPadNoticeList',
        method: 'get',
        params
    })
}


// 已读通知
export const read_message = function (params) {
    return ajax({
        url: '/notice/v1/setZeroPadNoticeRead',
        method: 'get',
        params
    })
}

// 删除通知
export const delete_message = function (params) {
    return ajax({
        url: '/notice/v1/deleteZeroPadNotice',
        method: 'get',
        params
    })
}

