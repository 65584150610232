export const setStorage = function (key, value) {
  const type = typeof (value)

  if (type == 'object') {
    localStorage[key] = JSON.stringify({
      value: JSON.stringify(value || {}),
      type: type
    });
  } else {
    localStorage[key] = JSON.stringify({
      value: value,
      type: type
    });
  }



}

export const getStorage = function (key) {

  const val = localStorage[key];
  if (!val) {
    return ''
  }
  const v = JSON.parse(val)

  if (v.type == 'object') {
    return JSON.parse(v.value)
  } else {
    return v.value;
  }

}

export const clearStorage = function (key) {
  localStorage.removeItem(key)
  return;
}