<template>
	<div>
		<div class="btn-container" v-if="btnList.length > 0">
			<div
				class="btn-item"
				:class="[
					'btn-item-' + (btn.type ? getBtnClass : ''),
					{
						'btn-item-default': i == 0 && selectedTables.length > 0,
						'btn-item-min': minScreen,
						'btn-item-noColor':
							blackList.includes(btn.type) && selectedTables.length > 0,
					},
				]"
				v-for="(btn, i) in btnList"
				:key="btn.label"
				@click="handleClickBtn(btn.type)"
			>
				<img
					:src="btn.icon"
					alt=""
					:width="minScreen ? 22 : 26"
					:class="[
						getBtnClass,
						{
							default: i == 0 && selectedTables.length > 0,
							noColor:
								blackList.includes(btn.type) && selectedTables.length > 0,
						},
					]"
				/>
				<span class="mt-5">{{ labelMap[btn.type] }}</span>
			</div>
		</div>
		<operation-dialog
			:type.sync="type"
			:show.sync="show"
			:title="labelMap[type]"
		></operation-dialog>
	</div>
</template>

<script>
import resv from '../../../../assets/s-icons/icon-resv.svg'
import add from '../../../../assets/s-icons/icon-add.svg'
import sit from '../../../../assets/s-icons/icon-sit.svg'
import confirm from '../../../../assets/s-icons/icon-confirm.svg'
import sksit from '../../../../assets/s-icons/icon-sksit.svg'
import pay from '../../../../assets/s-icons/icon-pay.svg'
import change from '../../../../assets/s-icons/icon-change.svg'
import lock from '../../../../assets/s-icons/icon-lock.svg'
import unlock from '../../../../assets/s-icons/icon-unlock.svg'
import unorder from '../../../../assets/s-icons/icon-unorder.svg'
import sms from '../../../../assets/s-icons/icon-sms.svg'
import operationDialog from './operationDialog'
import { mapState, mapMutations, mapGetters } from 'vuex'
import { orderStatusCode } from '../../../../const'
import { tableSub } from '../../../../config/table'
import { resend_sms } from '../../../../api/resv'
import { confirm_zero_order } from '@/api/order'

export default {
	data() {
		return {
			type: '',
			show: false,
		}
	},
	components: {
		operationDialog,
	},
	props: {
		minScreen: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		...mapState('resvInfo', [
			'selectedTables',
			'sideBarType',
			'mealTypeDate',
			'mealTypeId',
			'currentTime'
		]),
		...mapState(['userInfo']),
		...mapGetters('resvInfo', [
			'mealIdMap',
			'mealTypeNameMap',
			'currentMeal',
			'tableData',
			'orderData',
		]),
		getBtnClass() {
			if (this.selectedTables.length == 0) {
				return 'home'
			} else {
				return 'detail'
			}
		},
		blackList() {
			return ['unorder', 'sms']
		},
		btnList() {
			console.log("chufff");
			const btnMap = {
				'': {
					icon: '',
					type: '',
				},
				resv: {
					icon: resv,
					type: 'resv',
				},
				sit: {
					icon: sit,
					type: 'sit',
				},
        confirm: {
					icon: confirm,
					type: 'confirm',
				},
				pay: {
					icon: pay,
					type: 'pay',
				},
				add: {
					icon: add,
					type: 'add',
				},
				change: {
					icon: change,
					type: 'change',
				},
				sksit: {
					icon: sksit,
					type: 'sksit',
				},
				lock: {
					icon: lock,
					type: 'lock',
				},
				unlock: {
					icon: unlock,
					type: 'unlock',
				},
				unorder: {
					icon: unorder,
					type: 'unorder',
				},
				sms: {
					icon: sms,
					type: 'sms',
				},
			}
			let list = [
				'resv',
				'sit',
        'confirm',
				'pay',
				'add',
				'change',
				'sksit',
				'lock',
				'unlock',
				'unorder',
			]

			const now = this.$moment(this.currentTime).format('YYYYMMDD')
			const resvDate = this.$moment(this.tableData.resvDate).format('YYYYMMDD')
			const mealTypeDate = this.$moment(this.mealTypeDate).format('YYYYMMDD')

			if (this.sideBarType == 'idle' && this.selectedTables.length > 0) {
				if (this.mealTypeId == this.currentMeal && now == mealTypeDate) {
					list = ['resv', 'sksit', 'lock']
				} else {
					list = ['resv', 'lock', '']
				}
			} else if (this.sideBarType == 'book') {
				list = []
			} else if (this.sideBarType == 'detail-1') {
				if (this.tableData.orderStatus == orderStatusCode.RESERVE) {

					if (this.tableData.resvDate) {
						const chooseMealType = this.mealIdMap[this.currentMeal]
						console.log('chooseMealType', chooseMealType)

						if (this.orderData.customerPhone) {
							list = ['add', 'change', 'pay', 'unorder', 'sms']
						} else {
							list = ['add', 'change', 'pay', 'unorder']
						}
						if (
							this.currentMeal == this.tableData.mealTypeId &&
							resvDate == now
						) {
							list.unshift('sit')
						} else {
							list.push('')
						}
					}
				} else if (this.tableData.orderStatus == orderStatusCode.SET_IN) {
					list = ['pay', 'add', 'change', 'unorder', '']
				}
				const nowDate = this.$moment(this.currentTime).format('YYYYMMDD')
				const mealDate = this.$moment(this.mealTypeDate).format('YYYYMMDD')
				if (mealDate > nowDate) {
					const idx = list.findIndex((val) => val == 'pay')
					if (idx > -1) {
						list.splice(idx, 1)
					}
				}
			} else if (this.sideBarType == 'detail-2') {
				list = []
			}
      if(this.tableData.orderId) {
        // 先判断是否有订单
        let confirmFlag = true
        const currentOrders = this.selectedTables.map(o => o.order.currentOrders[0])
        confirmFlag = currentOrders.every(item => item.confirm === 1)
        if(!confirmFlag) {
          const index = list.indexOf('sit')
          if(index !== -1) {
            list.splice(index + 1, 0, 'confirm')
          }else if(this.tableData.orderStatus !==2){
			      list.splice(1, 0, 'confirm')
		      }
        }
      }
			let arr = []
			list.map((v) => {
				arr.push(btnMap[v])
			})
			return arr
		},
		labelMap() {
			return {
				resv: '预订',
				sit: '入座',
				confirm: '确认',
				pay: '结账',
				add: '加桌',
				change: '换桌',
				sksit: '散客入座',
				lock: '锁台',
				unlock: '解锁',
				unorder: '退订',
				sms: '补发短信',
			}
		},
	},
	methods: {
		...mapMutations('resvInfo', ['SET_SIDEBAR_TYPE', 'SET_TABLE_STATUS']),
		handleClickBtn(type) {
			if (!type) {
				return
			}
			if (this.selectedTables.length < 1) {
				this.$message.warning('请先选择桌位')
				return
			}
			const check = this.checkOrderCanEdit(type)
			console.log('检查是否过去订单', check)
			if (!check) {
				return this.$message.error('您选择的用餐时间或餐别超出预订范围！')
			}
			this.type = type
			const notShowDialogList = ['add', 'confirm', 'change', 'sms']
			if (type == 'resv') {
				this.SET_SIDEBAR_TYPE('book')
			}
			if (!notShowDialogList.includes(type)) {
				this.show = true
			}
			if (type == 'add') {
				tableSub.changeTables(type)
				this.SET_SIDEBAR_TYPE('table-add')
				this.SET_TABLE_STATUS('')
			}
			if (type == 'change') {
				if (this.selectedTables.length > 1) {
					this.$message.warning(
						'单次操作仅支持单桌，请确保所选桌位数量仅有一桌'
					)
					return
				}
				tableSub.changeTables(type)
				this.SET_SIDEBAR_TYPE('table-change')
				this.SET_TABLE_STATUS('')
			}
			if (type == 'sms') {
				this.$confirm('是否给客户补发预订短信?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning',
				})
					.then(() => {
						resend_sms({
							orderNo: this.tableData.orderNo,
						}).then((res) => {
							this.$message.success(res)
						})
					})
					.catch(() => {})
			}
      if(type === 'confirm') {
        this.$confirm('是否完成餐前确认', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning',
				})
					.then(() => {
            // console.log(this.orderData.orderTables, 'tableData')
            const currentOrders = this.selectedTables.map(o => o.order.currentOrders[0])
            const paramsTables = currentOrders.map(item => ({ ...item, confirm: 1}))
            // console.log(tables)
            confirm_zero_order({
              tables: paramsTables,
				      hotelId: this.userInfo.fkOrgId,
							...this.tableData
            }).then(res => {
              console.log(res)
							this.$message.success('餐前确认成功')
              tableSub.selectTable([])
					    this.SET_SIDEBAR_TYPE('idle')
				      this.$Bus.$emit('refresh')
            })
					})
					.catch(() => {})
      }
		},
		checkOrderCanEdit(type) {
			const whiteListType = ['pay', 'sms']
			const now = this.$moment(this.currentTime).format('YYYYMMDD')
			const resvDate = this.$moment(this.tableData.resvDate).format('YYYYMMDD')
			const mealTypeDate = this.$moment(this.mealTypeDate).format('YYYYMMDD')
			if (!whiteListType.includes(type)) {
				if (this.tableData.resvDate) {
					if (resvDate < now) {
						return false
					} else if (resvDate == now) {
						const chooseMealType = this.mealIdMap[this.mealTypeId]
						console.log('chooseMealType', chooseMealType)
						const endTime = this.$moment(chooseMealType.endTime).format(
							'YYYYMMDDHHmm'
						)
						const nowTime = this.$moment(this.currentTime).format('YYYYMMDDHHmm')
						if (endTime < nowTime) {
							return false
						} else {
							return true
						}
					} else {
						return true
					}
				} else {
					if (mealTypeDate < now) {
						return false
					} else if (mealTypeDate == now) {
						const chooseMealType = this.mealIdMap[this.mealTypeId]
						console.log('chooseMealType', chooseMealType)
						const endTime = this.$moment(chooseMealType.endTime).format(
							'YYYYMMDDHHmm'
						)
						const nowTime = this.$moment(this.currentTime).format('YYYYMMDDHHmm')
						if (endTime < nowTime) {
							return false
						} else {
							return true
						}
					} else {
						return true
					}
				}
			} else if (type == 'pay') {
				if (mealTypeDate <= now) {
					return true
				} else {
					return false
				}
			} else {
				return true
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.home {
	filter: drop-shadow(rgb(140, 197, 255) 100px 0);
	transform: translateX(-100px);
	color: rgb(140, 197, 255);
}
.detail {
	filter: drop-shadow(#409eff 100px 0);
	transform: translateX(-100px);
	color: #409eff;
}
.default {
	filter: drop-shadow(#fff 100px 0) !important;
	transform: translateX(-100px) !important;
	color: #fff !important;
}
.noColor {
	filter: drop-shadow(#666 100px 0) !important;
	transform: translateX(-100px) !important;
	color: #666 !important;
}
.btn-container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 30px 20px;
	flex-wrap: wrap;
}
.btn-item {
	overflow: hidden;
	width: 90px;
	height: 90px;

	margin: 10px;
	border-radius: 4px;
	display: flex;
	cursor: pointer;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	text-align: center;
}
.btn-item-min {
	width: 76px;
	height: 76px;
	font-size: 14px;
}
.btn-item-home {
	background: #f8fbff;
	opacity: 0.8;
	border: 1px solid #9ccdff;
	color: #409eff;
}
.btn-item-detail {
	background: #fff;
	border: 1px solid #409eff;
	color: #409eff;
}
.btn-item-default {
	background: #409eff !important;
	border: 1px solid #409eff;
	color: #fff !important;
}
.btn-item-noColor {
	background: #fff !important;
	border: 1px solid #666;
	color: #666 !important;
}
.btn-item:active {
	animation: 0.2s select linear;
}
</style>
