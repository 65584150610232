<template>
	<el-popover
		placement="bottom"
		width="400"
		trigger="click"
		v-model="show"
		:disabled="disabled"
	>
		<el-button
			slot="reference"
			size="small"
			style="width: 100%; font-size: 16px; padding: 6px 15px 7px 15px;color: #000;"
		>
			<i class="el-icon-date"></i>
			<span v-if="current">
				<span class="font-bold mr-5">
					{{ current }}
				</span>
				<span v-if="dateLabel">({{ dateLabel }})</span>
			</span>
			<span v-else> 选择日期 </span>
		</el-button>
		<div class="calendar">
			<div class="year-month-box flex flex-center">
				<span class="el-icon-arrow-left" @click.stop="handlePrevMonth"></span>
				<el-cascader
					class="year-month flex-1"
					v-model="currentDate"
					separator=""
					size="small"
					:options="options"
					@change="chooseYearMonth"
				></el-cascader>
				<span class="el-icon-arrow-right" @click.stop="handleNextMonth"></span>
			</div>
			<div class="content">
				<div class="week flex flex-center">
					<span class="flex-1">一</span>
					<span class="flex-1">二</span>
					<span class="flex-1">三</span>
					<span class="flex-1">四</span>
					<span class="flex-1">五</span>
					<span class="flex-1">六</span>
					<span class="flex-1">日</span>
				</div>
				<div class="flex flex-wrap">
					<div
						class="date"
						v-for="(item, index) in dates"
						:key="index"
						:class="{ disable: item.disable }"
						@click.stop="chooseDate(item)"
					>
						<div
							class="date-content flex flex-column flex-center"
							:class="[
								{ current: randerCurrent(item), today: randerToday(item) },
							]"
						>
							<div class="day">
								{{ item.day }}
							</div>
							<div class="day-sub">
								{{ item.festival || item.lunarDisplay }}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</el-popover>
</template>

<script>
import moment from 'moment'
import { get_calendar } from '@/api/common'
import dateFormat from '@/utils/dateFormat'
export default {
	props: {
		current: {
			type: String,
			default: '',
		},
		createTime: [String, Number],
		disabled: {
			type: Boolean,
			default: false,
		},
		hotelId: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			show: false,
			dates: [],
			year: '',
			month: '',
			date: '',
			searchDate: '',
			minDate: new Date(),
			maxDate: new Date(2100, 11, 31),
			options: [],
			currentDate: [],
			weekConst: {
				1: '星期一',
				2: '星期二',
				3: '星期三',
				4: '星期四',
				5: '星期五',
				6: '星期六',
				7: '星期日',
			},
		}
	},
	watch: {
		show(val) {
			if (val) {
				if (!this.current) {
					this.year = moment().year()
					this.month = moment().month() + 1
					this.currentDate = [this.year, this.month]
					this.getData()
				}
			}
		},
		current: {
			handler() {
				if (this.current) {
					console.log(moment(this.current).year(), 11)
					this.year = moment(this.current).year()
					this.month = moment(this.current).month() + 1
					this.currentDate = [this.year, this.month]
					this.getData()
				}
			},
			immediate: true,
		},
	},
	computed: {
		dateLabel() {
			if (this.current) {
				return dateFormat.formatBookDate(this.current, true)
			} else {
				return ''
			}
		},
	},
	mounted() {
		this.year = moment().year()
		this.month = moment().month() + 1
		this.currentDate = [this.year, this.month]

		const nextYear = moment().add(1, 'years').year()
		this.maxDate = new Date(nextYear, 11, 31)
		let thisYearMonths = []
		let nextYearMonths = []
		for (var i = 1; i <= 12; i++) {
			if (i >= this.month) {
				thisYearMonths.push({
					value: i,
					label: i + '月',
				})
			}
			nextYearMonths.push({
				value: i,
				label: i + '月',
			})
		}
		this.options = [
			{
				value: this.year,
				label: this.year + '年',
				children: thisYearMonths,
			},
			{
				value: nextYear,
				label: nextYear + '年',
				children: nextYearMonths,
			},
		]

		console.log(this.options, 'option')
	},
	methods: {
		close() {
			this.year = moment().year()
			this.month = moment().month() + 1
			this.currentDate = [this.year, this.month]
			this.$emit('close')
		},
		randerCurrent(item) {
			const y = moment(this.current).year()
			const m = moment(this.current).month() + 1
			const d = moment(this.current).date()
			return y == this.year && m == this.month && d == item.day
		},
		randerToday(item) {
			const y = moment().year()
			const m = moment().month() + 1
			const d = moment().date()
			return y == this.year && m == this.month && d == item.day
		},
		handlePrevMonth() {
			const miny = moment(this.minDate).year()
			const minm = moment(this.minDate).month() + 1
			if (miny >= this.year && minm >= this.month) {
				return this.$message.warning('不可选择上一月')
			}
			this.year = moment(this.dates[7].date).subtract(1, 'months').year()
			this.month = moment(this.dates[7].date).subtract(1, 'months').month() + 1
			this.currentDate = [this.year, this.month]
			this.getData()
		},
		handleNextMonth() {
			const maxy = moment(this.maxDate).year()
			const maxm = moment(this.maxDate).month() + 1
			if (maxy <= this.year && maxm <= this.month) {
				return this.$message.warning('不可选择下一月')
			}
			this.year = moment(this.dates[7].date).add(1, 'months').year()
			this.month = moment(this.dates[7].date).add(1, 'months').month() + 1
			this.currentDate = [this.year, this.month]
			this.getData()
		},
		chooseYearMonth() {
			this.year = this.currentDate[0]
			this.month = this.currentDate[1]
			this.currentDate = [this.year, this.month]
			this.getData()
		},
		getData() {
			if (this.searchDate == `${this.year}-${dateFormat.addZero(this.month)}`) {
				return
			}
			get_calendar({
				searchDate: `${this.year}-${dateFormat.addZero(this.month)}`,
			}).then((res) => {
				if (res) {
					this.searchDate = `${this.year}-${dateFormat.addZero(this.month)}`
					this.dates = res.map((o) => {
						o.day = moment(o.date).date()
						const lunarDay = o.lunarDate.split('-')[1]
						o.lunarDisplay =
							lunarDay == 1
								? o.lunarName.split('月')[0] + '月'
								: o.lunarName.split('月')[1]
						return o
					})
					if (this.dates.length > 0) {
						const first = this.dates[0]
						let firstWeekday = first.week
						for (var i = 1; i <= 7; i++) {
							if (i == firstWeekday) {
								return
							} else {
								this.dates.unshift({
									date: '',
									lunarName: '',
								})
							}
						}
					}
				}
			})
		},
		chooseDate(item) {
			if (item.disable) return
			this.$emit('change', item)
			this.show = false
		},
	},
}
</script>

<style lang="scss" scoped>
.calendar {
	text-align: center;
	.title {
		padding: 16px;
		font-size: 20px;
		line-height: 1;
	}
	.year-month-box {
		padding: 10px 40px 30px;
		.year-month {
			margin: 0 35px;
			::v-deep .el-input__inner {
				background: #f1f1f1;
				border-radius: 32px;
				text-align: center;
				border-color: #f1f1f1;
			}
		}
	}
	.content {
		height: 376px;
	}
	.week {
		margin-bottom: 20px;
	}
	.date {
		width: calc(100% / 7);
		margin-bottom: 13px;
		cursor: pointer;
		.date-content {
			width: 45px;
			height: 45px;
			margin: auto;
			border-radius: 50%;
			position: relative;
			line-height: 1;
			.day {
				font-size: 16px;
			}
			.day-sub {
				font-size: 10px;
				color: #666;
				margin-top: 5px;
			}
		}
		&.disable .date-content {
			opacity: 0.5;
		}
		.current {
			background: #409eff !important;
			color: #ffffff !important;
			.day-sub {
				color: #fff !important;
			}
		}
		.today {
			color: #409eff;
			.day-sub {
				color: #409eff;
			}
		}
	}
	.bottom {
		padding: 10px 20px 20px;
	}
}
</style>
<style lang="scss">
.date-popover {
	height: 300px;
	overflow: auto;
	width: 100px;
	text-align: center;
	.item {
		line-height: 40px;
		font-size: 12px;
		& + .item {
			border-top: 1px solid #f0f0f0;
		}
	}
}
</style>