import moment from 'moment'
import dateFormat from '@/utils/dateFormat'

const resvInfo = {
  namespaced: true,
  state: {
    selectedTables: [],
    //区域/桌位
    tableAreaId: [],
    tableAreaList: [],
    tableList: [],
    //餐段
    mealTypeDate: '',
    mealTypeId: '',
    firstMealType: '', //跨天时间分割点
    mealTypeList: [],
    ordersNum: {},
    //预订类型
    resvTypeList: [],
    tableStatus: '',
    //订单详情
    orderDetailData: null,
    //右侧侧边栏当前显示那页
    sideBarType: 'idle',
    //未读消息数量
    UnReadMessageNum: 0,
    zeroHotelOtherConfig: [],
    currentTime: moment().format('YYYY-MM-DD HH:mm')
  },
  getters: {
    zeroHotelOtherConfigMap: (state) => {
      const obj = {}
      state.zeroHotelOtherConfig.map(item => {
        if (!obj[item.k]) {
          obj[item.k] = item.v
        }
      })
      return obj
    },
    tableAreaMap: (state) => {
      const obj = {}
      state.tableList.map(item => {
        if (!obj[item.tableAreaId]) {
          obj[item.tableAreaId] = []
        }
        obj[item.tableAreaId].push(item)
      })
      return obj
    },
    tableIdMap: (state) => {
      const obj = {}
      state.tableList.map(item => {
        obj[item.id] = item
      })
      return obj
    },
    mealTypeList(state, getters) {

      return getters.calcMealType.mealTypeList
    },
    mealTypeNameMap(state, getters) {
      return getters.calcMealType.mealTypeNameMap
    },
    currentMeal(state, getters) {
      const list = getters.calcMealType.mealTypeList
      let currentMeal = ''
      const nowDateTime = parseInt(moment(state.currentTime).format('YYYYMMDDHHmm'))
      for (let i = 0; i < list.length; i++) {
        const meal = list[i];
        if (nowDateTime <= parseInt(meal.end)) {
          currentMeal = meal.mealTypeId
          break
        }
      }
      if (!currentMeal && list.length > 0) {
        currentMeal = list[0].mealTypeId
      }

      return currentMeal
    },
    mealIdMap(state, getters) {
      return getters.calcMealType.mealIdMap
    },

    tableAreaList(state, getters) {
      let arr = []
      state.tableAreaList.map((v) => {
        const l =
          getters.tableAreaMap[v.id] && getters.tableAreaMap[v.id].length
            ? getters.tableAreaMap[v.id].length
            : 0
        if (l) {
          arr.push(v)
        }
      })

      return arr
    },
    orderData(state) {
      const len = state.selectedTables.length;
      if (len == 0) {
        return {};
      }
      return state.selectedTables[len - 1].order;
    },
    tableData(state, getters) {
      const len = state.selectedTables.length;
      if (len == 0) {
        return {};
      }
      const table = state.selectedTables[len - 1].table;
      return getters.orderData.orderTables
        ? getters.orderData.orderTables.find((v) => v.tableId == table.id)
        : {};
    },
    areaOrderMap(state, getters) {
      if (state.tableStatus > 0) {
        let obj = {}
        const list = state.ordersNum[state.tableStatus] || []
        list.map((v) => {
          if (!obj[v.table.tableAreaId]) {
            obj[v.table.tableAreaId] = []
          }
          obj[v.table.tableAreaId].push(v.table)
        })
        return obj
      } else if (state.tableStatus == -1) {
        const allStatus = [1, 2, 3, 4, 5]
        let list = []
        allStatus.map(status => {
          list = list.concat(state.ordersNum[status] || [])
        })

        list = list.map(v => { return v.table.tableId })

        const keys = Object.keys(getters.tableAreaMap)
        let _tableAreaMap = getters.tableAreaMap
        let resObj = {}
        keys.map(areaId => {
          resObj[areaId] = _tableAreaMap[areaId].filter(table => {
            return (!list.includes(table.id))
          })
        })
        return resObj


      } else {
        return getters.tableAreaMap
      }
    },
    calcMealType(state) {
      let mealIdMap = {}
      let mealTypeListFilter = []
      let mealTypeNameMap = {}
      if (state.mealTypeList.length > 0) {
        const nowDate = moment(state.currentTime).format('YYYY/MM/DD')
        let nextDate = moment(state.currentTime).add(1, 'day').format('YYYY/MM/DD')
        const firstMealTime = parseInt(moment(nextDate + ' ' + (state.firstMealType || '00:00')).format('HHmm'))
        let mealTypeList = state.mealTypeList



        let mealList = []
        mealTypeList.map(meal => {
          const dates = meal.usingDate ? meal.usingDate.split(',') : []
          if (dates.includes(state.mealTypeDate)) {
            mealList.push({
              ...meal,
              mealTypeId: meal.mealTypeIdA,
              mealTypeName: meal.mealTypeNameA,
              mealEndTime: meal.splitTime,

              parentMealTypeId: meal.parentMealTypeId || meal.mealTypeId,
              parentmealStartTime: meal.parentmealStartTime || meal.mealStartTime,
              parentMealEndTime: meal.parentMealEndTime || meal.mealEndTime,
            })
            mealList.push({
              ...meal,
              mealTypeId: meal.mealTypeIdB,
              mealTypeName: meal.mealTypeNameB,
              mealStartTime: meal.splitTime,

              parentMealTypeId: meal.parentMealTypeId || meal.mealTypeId,
              parentmealStartTime: meal.parentmealStartTime || meal.mealStartTime,
              parentMealEndTime: meal.parentMealEndTime || meal.mealEndTime,
            })

          } else {
            mealList.push({
              ...meal,

              parentMealTypeId: meal.parentMealTypeId || meal.mealTypeId,
              parentmealStartTime: meal.parentmealStartTime || meal.mealStartTime,
              parentMealEndTime: meal.parentMealEndTime || meal.mealEndTime,
            })
          }
        })

        mealTypeList = mealList


        for (let i = 0; i < mealTypeList.length; i++) {
          let isCrossDay1 = false
          let isCrossDay2 = false
          const meal = mealTypeList[i];
          mealTypeNameMap[meal.mealTypeId] = meal.mealTypeName
          let startTime = parseInt(meal.mealStartTime.replace(':', ''))
          let endTime = parseInt(meal.mealEndTime.replace(':', ''))



          if (meal.mealStartTime && meal.mealEndTime) {
            let startHalf = dateFormat.formatTimeToHalf(meal.mealStartTime, '')
            let endHalf = dateFormat.formatTimeToHalf('', meal.mealEndTime)


            //跨天
            if (startTime >= 0 && startTime < firstMealTime && startTime <= endTime) {
              //头尾都跨
              startTime = nextDate + ' ' + meal.mealStartTime
              endTime = nextDate + ' ' + meal.mealEndTime
              isCrossDay1 = true
              isCrossDay2 = true
            } else if (startTime < 2400 && startTime >= firstMealTime && startTime >= endTime) {

              //头不跨尾跨
              startTime = nowDate + ' ' + meal.mealStartTime
              endTime = nextDate + ' ' + meal.mealEndTime
              isCrossDay1 = false
              isCrossDay2 = true


            } else if (startTime < 2400 && startTime <= endTime) {
              startTime = nowDate + ' ' + meal.mealStartTime
              endTime = nowDate + ' ' + meal.mealEndTime

              isCrossDay1 = false
              isCrossDay2 = false
            }

            const start = startTime.replace(/\//g, '').replace(/:/g, '').replace(/ /g, '')
            const end = endTime.replace(/\//g, '').replace(/:/g, '').replace(/ /g, '')

            startHalf = isCrossDay1 ? nextDate + ' ' + startHalf : nowDate + ' ' + startHalf
            endHalf = isCrossDay2 ? nextDate + ' ' + endHalf : nowDate + ' ' + endHalf
            let timeList = dateFormat.listBetween(startHalf, endHalf, 30 * 60 * 1000)

            const data = {
              ...meal,
              startTime,
              endTime,
              start,
              end,
              timeList,
              isCrossDay1,
              isCrossDay2,
            }
            mealIdMap[meal.mealTypeId] = data
            mealTypeListFilter.push(data)
          }


        }

        mealTypeListFilter.sort((a, b) => {
          return (a.start - b.start) || (a.end - b.end)
        })
      }

      console.log('mealTypeListFilter', mealTypeListFilter);

      return {
        mealIdMap,
        mealTypeNameMap,
        mealTypeList: mealTypeListFilter
      }
    }
  },
  mutations: {
    SET_ZERO_HOTEL_OTHER_CONFIG(state, config) {
      state.zeroHotelOtherConfig = config
    },
    SET_MEALTYPE_DATE(state, mealTypeDate) {
      state.mealTypeDate = mealTypeDate
      if (!this.getters['resvInfo/mealIdMap'][state.mealTypeId]) {
        state.mealTypeId = state.mealTypeList[0].mealTypeId
      }

    },
    SET_FIRST_MEALTYPE(state, firstMealType) {
      state.firstMealType = firstMealType
    },
    SET_MEALTYPE_ID(state, mealTypeId) {
      state.mealTypeId = mealTypeId
    },
    SET_MEALTYPE_LIST(state, list) {

      state.mealTypeList = list
    },
    SET_RESVTYPE_LIST(state, list) {
      state.resvTypeList = list
    },
    SET_TABLEAREA_LIST(state, list) {
      state.tableAreaList = list
    },
    SET_TABLE_LIST(state, list) {
      state.tableList = list
    },
    SET_TABLEAREA_ID(state, tableAreaId) {
      state.tableAreaId = tableAreaId
    },
    SET_TABLE_STATUS(state, status) {
      state.tableStatus = status
    },
    SET_SELECTED_TABLE(state, data) {
      state.selectedTables = data;
    },
    SET_SIDEBAR_TYPE(state, data) {
      state.sideBarType = data;
    },
    SET_ORDERS_NUM(state, data) {
      state.ordersNum = data
    },
    SET_UNREAD_MESSAGE_NUM(state, data) {
      state.UnReadMessageNum = data
    },
    SET_CURRENT_TIME(state, time) {
      state.currentTime = time;
    },
  }

}
export default resvInfo