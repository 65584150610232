<template>
	<div id="app">
		<keep-alive :include="keepAlive">
			<router-view></router-view>
		</keep-alive>
		<iframe
			:src="iframeUrl"
			frameborder="0"
			v-if="iframeUrl"
			id="iframe"
			style="width: 80mm"
		></iframe>
	</div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import { get_order_list } from '@/api/resv'
import { get_unread_num } from '@/api/message'
import { tableSub } from '@/config/table'
import { splitMeal } from '@/utils/splitMeal'
export default {
	name: 'app',
	data() {
		return {
			wsInterval: '',
			timeInterval: '',
		}
	},
	computed: {
		...mapState('resvInfo', ['mealTypeDate', 'mealTypeId', 'mealTypeList']),
		...mapState('websocket', ['is_open_socket', 'ws_token']),
		...mapState('page', ['keepAlive']),
		...mapState(['userInfo', 'iframeUrl']),
		mealTypeTime() {
			let list = []
			this.mealTypeList.map((meal) => {
				const now = this.$moment().format('YYYY-MM-DD')
				list.push(
					this.$moment(now + ' ' + meal.mealStartTime || '')
						.add(1, 'minutes')
						.format('HH:mm:00')
				)
				list.push(
					this.$moment(now + ' ' + meal.mealEndTime || '')
						.add(1, 'minutes')
						.format('HH:mm:00')
				)
			})
			return list
		},
	},
	watch: {
		userInfo: {
			handler(newValue, oldValue) {
				if (
					JSON.stringify(newValue) != '{}' &&
					JSON.stringify(newValue) != JSON.stringify(oldValue)
				) {
					this.WEBSOCKET_INIT()
				}
			},
			immediate: true,
		},
	},
	methods: {
		...mapActions('websocket', [
			'WEBSOCKET_INIT',
			'WEBSOCKET_SEND',
			'GET_SOCKET_TOKEN',
			'CLOSE_WEBSOCKET',
		]),
		...mapMutations('resvInfo', ['SET_UNREAD_MESSAGE_NUM', 'SET_CURRENT_TIME']),
		...mapMutations(['SET_FULLSCREEN_LOADING']),
		initTime() {
			this.timeInterval = setInterval(() => {
				const now = this.$moment()
				// if(this.mealTypeTime.includes(now.format('HH:mm:ss'))){
				// console.log("生效");
				this.SET_CURRENT_TIME(now.format('YYYY-MM-DD HH:mm'))
				// }
			}, 1000)
		},
		receiveMessage(msg) {
			const val = JSON.parse(msg.data)
			console.log('收到消息', val)
			if (val.code == 'SUCCESS' && val.data) {
				const data = JSON.parse(val.data)
				if (data.type == 'ZERO_ORDER_CHANGE') {
					setTimeout(() => {
						this.refreshPage()
					}, 300)
				}
			} else if (val.code == 'FAILURE') {
				this.wsClose()
				this.WEBSOCKET_INIT()
			}
		},
		refreshPage() {
			this.getOrderList()
			this.getUnReadMessageNum()
		},
		getOrderList() {
			const mealType = splitMeal(this.mealTypeId)
			get_order_list({
				hotelId: this.userInfo.fkOrgId,
				resvDate: this.mealTypeDate,
				...mealType,
			})
				.then((res) => {
					res = res.map((item) => {
						return {
							...item,
							orderTables: item.orderTables.map((table) => {
								let mealTypeId = table.mealTypeId
								if (table.mealTypeIdA && table.mealTypeIdA != '-1') {
									mealTypeId = table.mealTypeIdA
								}
								if (table.mealTypeIdB && table.mealTypeIdB != '-1') {
									mealTypeId = table.mealTypeIdB
								}
								return {
									...table,
									mealTypeId: mealTypeId,
								}
							}),
						}
					})
					tableSub.orderConnectToTable(res, this.mealTypeId)
					this.SET_FULLSCREEN_LOADING(false)
				})
				.catch((err) => {
					this.SET_FULLSCREEN_LOADING(false)
				})
		},
		getUnReadMessageNum() {
			get_unread_num({
				hotelId: this.userInfo.fkOrgId,
			})
				.then((res) => {
					console.log(res)
					this.SET_UNREAD_MESSAGE_NUM(res)
				})
				.catch((err) => {})
		},
		async wsOpen() {
			await this.GET_SOCKET_TOKEN()
			console.log('连接测试')
			const data = {
				action: 1,
				jsonObjStr: JSON.stringify({
					device: 'ZERO_PAD',
					orgCode: this.userInfo.fkOrgId,
					userId: this.userInfo.id,
				}),
				token: this.ws_token,
			}
			this.WEBSOCKET_SEND(JSON.stringify(data))
			this.sendMessage({
				action: 2,
				token: this.ws_token,
			})
		},
		sendMessage(data) {
			clearInterval(this.wsInterval)
			this.wsInterval = setInterval(() => {
				// 定时查询 连接是否正常
				if (this.is_open_socket) {
					// 发送消息
					this.WEBSOCKET_SEND(JSON.stringify(data))
				}
			}, 30 * 1000)
		},
		wsClose() {
			this.CLOSE_WEBSOCKET(true)
		},
		reload() {
			location.reload()
		},
	},
	created() {
		this.$Bus.$on('receiveMessage', this.receiveMessage)
		this.$Bus.$on('reloadWebSite', this.reload)
		this.$Bus.$on('wsOpen', this.wsOpen)
		this.$Bus.$on('wsClose', this.wsClose)
		this.$Bus.$on('refresh', this.refreshPage)
		this.initTime()
	},
	mounted() {},
	beforeDestroy() {
		//页面销毁  清除定时器
		clearInterval(this.wsInterval)
		clearInterval(this.timeInterval)
	},
}
</script>

<style>
* {
	padding: 0;
	margin: 0;
}
#app {
	font-family: 'PingFang SC', 'HarmonyOS_Regular', 'Helvetica Neue',
		'Microsoft YaHei', 'sans-serif';
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: #333;
	word-break: break-all;
}
</style>
