import { getSocketToken } from '@/api/common'
import { Bus } from '@/config/bus'
const websocket = {
  namespaced: true,
  state: {
    socketTask: null,
    ch: {},
    ws_token: '',
    is_open_socket: false,
    reconnectTimes:0
  },


  actions: {
    WEBSOCKET_INIT({ state, dispatch, commit }) {
      // 创建一个this.socketTask对象【发送、接收、关闭socket都由这个对象操作】
      console.log('websocket 开始连接')
      state.socketTask = new WebSocket('wss://ws.zhidianfan.com/ws')
      // 查看当前连接是否已经准备好发送和接受数据
      state.socketTask.onopen = (res) => {
        console.log("WebSocket已经准备好发送和接受数据！");
        state.is_open_socket = true;
        Bus.$emit('wsOpen')
        // 注：只有连接正常打开中 ，才能正常收到消息
      }
      //接收消息
      state.socketTask.onmessage = (res) => {
        Bus.$emit('receiveMessage', res)
      };
      //  报错事件 
      state.socketTask.onerror = err => {
        console.log(err, 'depth-socket::error')
      }
      // 断线事件
      state.socketTask.onclose = () => {
        if(state.reconnectTimes > 3){
          Bus.$emit('reloadWebSite')
        }
        if(state.is_open_socket){
           state.is_open_socket = false;
           console.log('断线重连')
           state.reconnectTimes+= 1
           dispatch('WEBSOCKET_INIT')
        }
      }
    },
    // 发送消息
    WEBSOCKET_SEND({ state }, p) {
      let Interval = setInterval(() => {
        // 定时查询 连接是否正常
        if (state.is_open_socket && state.ws_token) {
          // 发送消息
          console.log('发送消息前ws连接状态:',state.is_open_socket,'token:', state.ws_token,'内容:',p)
          state.socketTask.send(p)
          // 连接正常移除定时器
          clearInterval(Interval)
        }
       
      }, 1000)

    },
    GET_SOCKET_TOKEN ({ state }) {
      return new Promise((resolve, reject) => {
        getSocketToken().then(response => {
          console.log('ws token', response);
          state.ws_token = response
          resolve()
        }).catch(error => {
          state.ws_token = ''
          console.log('ws token', error);
          reject(error)
        })
      })
    },
    CLOSE_WEBSOCKET({ state,bool }){
      if(bool){
        //手动切断链接
         state.is_open_socket = false;
      }
     
      state.socketTask.close()
    }
  }
}

export default websocket
