import { ajax } from "@/api/request";

// 零点订单列表
export const get_zero_order_list = function(data) {
  return ajax({
    method: 'post',
    url: '/zero/order/v2/getZeroOrderAppList',
    data
  });
}

export const confirm_zero_order = function(data) {
  return ajax({
    method: 'post',
    url: '/zero/order/v1/confirmZeroOrder',
    data
  })
}
