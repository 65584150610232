<template>
	<div class="section">
		<div class="header">
			<span class="title">消息列表</span>
			<div @click="handleCloseMessage" class="close">
				<i class="el-icon-close"></i>
			</div>
		</div>
		<div class="top-box flex flex-between flex-align-center">
			<div class="t1">
				<el-select
					:class="show ? 'select1 active' : 'select1'"
					size="small"
					v-model="isRead"
					placeholder="请选择"
					@click.native="clickSelect(1)"
					@change="getData(true)"
				>
					<el-option label="全部消息" value=""></el-option>
					<el-option label="未读消息" value="0"></el-option>
					<el-option label="已读消息" value="1"></el-option>
				</el-select>
				<el-select
					:class="show ? 'select2' : 'select2 active'"
					size="small"
					v-model="messageType"
					placeholder="请选择"
					@click.native="clickSelect(2)"
					@change="getData(true)"
				>
					<el-option label="全部类型" value=""></el-option>
					<el-option label="预订" value="CREATE_ORDER"></el-option>
					<el-option label="加桌" value="ADD_TABLE"></el-option>
					<el-option label="换桌" value="CHANGE_TABLE"></el-option>
					<el-option label="退订" value="CANCEL"></el-option>
				</el-select>
			</div>
			<div
				class="t2 flex flex-align-center font-size-14"
				@click="readAll()"
				v-if="messageList.length > 0 && isRead == 0"
			>
				<img
					src="../../../../assets/icons/icon-all-read.png"
					alt=""
					width="15"
					class="mr-5"
				/>

				<el-link :underline="false">全部已读</el-link>
			</div>
		</div>
		<div class="main">
			<div
				class="message-list"
				v-infinite-scroll="getData"
				:infinite-scroll-disabled="disabled"
			>
				<div
					class="message-item"
					v-for="(msg, index) in messageList"
					:key="msg.noticeId"
				>
					<div class="flex flex-between flex-align-center pl-5 pr-5 pt-10">
						<div
							class="font-size-16 flex ml-5 mb-5"
							:style="{ color: colorMap[msg.noticeType] }"
						>
							<img
								src="../../../../assets/icons/icon-book.png"
								alt=""
								width="23"
								v-if="msg.noticeType == 'CREATE_ORDER'"
							/>
							<img
								src="../../../../assets/icons/icon-add.png"
								alt=""
								width="23"
								v-if="msg.noticeType == 'ADD_TABLE'"
							/>
							<img
								src="../../../../assets/icons/icon-change.png"
								alt=""
								width="23"
								v-if="msg.noticeType == 'CHANGE_TABLE'"
							/>
							<img
								src="../../../../assets/icons/icon-unorder.png"
								alt=""
								width="23"
								v-if="msg.noticeType == 'CANCEL'"
							/>
							<span class="ml-10">{{ msg.noticeTypeName }}</span>
						</div>
						<div>
							<img
								src="../../../../assets/icons/icon-delete.png"
								alt=""
								width="15"
								v-if="msg.isRead"
								@click="deleteMsg(msg.noticeId, index)"
								class="cursor-point"
							/>
							<div v-else @click="readAll(msg.noticeId, index)">
								<el-link :underline="false" icon="el-icon-check"
									>标为已读</el-link
								>
							</div>
						</div>
					</div>
					<div class="content">
						<div
							class="flex flex-wrap pt-10 pb-5 pl-10 pr-10 width-100 change"
							v-if="msg.noticeType == 'CHANGE_TABLE'"
						>
							<div
								class="
									customer-info customer-phone
									flex50
									flex flex-align-center
									mb-5
								"
							>
								<img
									src="../../../../assets/icons/icon-user.png"
									alt=""
									width="14"
									class="mr-10"
								/>
								{{ msg.customerName }} {{ msg.customerPhone }}
							</div>
							<div
								class="
									customer-info customer-people
									flex50
									flex flex-align-center
									mb-5
								"
							>
								<img
									src="../../../../assets/icons/icon-people.png"
									alt=""
									width="14"
									class="mr-10"
								/>
								{{ msg.resvNum || 0 }}人
							</div>

							<div
								class="
									customer-info customer-time
									flex50
									flex flex-align-center
									mb-5
								"
							>
								<img
									src="../../../../assets/icons/icon-time.png"
									alt=""
									width="14"
									class="mr-10"
								/>
								{{ msg.oldResvDate }} {{ msg.oldMealTypeName }}
							</div>
							<div
								class="
									customer-info customer-area
									flex50
									flex flex-align-center
									mb-5
								"
							>
								<img
									src="../../../../assets/icons/icon-area.png"
									alt=""
									width="14"
									class="mr-10"
								/>
								{{ msg.oldTableAreaName ? msg.oldTableAreaName + '-' : ''
								}}{{ msg.oldTableName }}
							</div>
						</div>
						<span
							v-if="msg.noticeType == 'CHANGE_TABLE'"
							class="mt-10 ml-5 change-title"
						>
							<img
								src="../../../../assets/icons/icon-change-to.png"
								alt=""
								width="9"
								class="mr-5 ml-5"
							/>

							更换为：</span
						>
						<div class="flex flex-wrap pt-10 pb-5 pl-10 pr-10 width-100">
							<div
								class="
									customer-info customer-phone
									flex50
									flex flex-align-center
									mb-5
								"
							>
								<img
									src="../../../../assets/icons/icon-user.png"
									alt=""
									width="14"
									class="mr-10"
								/>
								{{ msg.customerName }} {{ msg.customerPhone }}
							</div>
							<div
								class="
									customer-info customer-people
									flex50
									flex flex-align-center
									mb-5
								"
							>
								<img
									src="../../../../assets/icons/icon-people.png"
									alt=""
									width="14"
									class="mr-10"
								/>
								{{ msg.resvNum || 0 }}人
							</div>

							<div
								class="
									customer-info customer-time
									flex50
									flex flex-align-center
									mb-5
								"
							>
								<img
									src="../../../../assets/icons/icon-time.png"
									alt=""
									width="14"
									class="mr-10"
								/>
								{{ msg.resvDate }} {{ msg.mealTypeName }}
							</div>
							<div
								class="
									customer-info customer-area
									flex50
									flex flex-align-center
									mb-5
								"
							>
								<img
									src="../../../../assets/icons/icon-area.png"
									alt=""
									width="14"
									class="mr-10"
								/>
								{{ msg.tableAreaName ? msg.tableAreaName + '-' : ''
								}}{{ msg.tableName }}
							</div>
						</div>
						<div class="bottom">
							<div>操作时间：{{ msg.createTime }}</div>
							<div>操作人：{{ msg.creatorName }}</div>
						</div>
					</div>
				</div>
				<p v-if="loading">加载中...</p>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { get_message_list, delete_message, read_message } from '@/api/message'
export default {
	name: 'message',
	data() {
		return {
			isRead: '0',
			show: true,
			messageType: '',
			messageList: [],
			messageListTotalLen: 0,
			page: {
				pageNo: 1,
				size: 20,
			},
			loading: false,
		}
	},
	mounted() {
		this.getData(true)
	},
	watch: {
		sideBarType(newValue) {
			if (newValue == 'message') {
				this.getData(true)
			}
		},
	},
	computed: {
		...mapState(['userInfo']),
		disabled() {
			return this.loading || this.messageList.length >= this.messageListTotalLen
		},
		colorMap() {
			return {
				CREATE_ORDER: '#627AF4',
				ADD_TABLE: '#409EFF',
				CHANGE_TABLE: '#409EFF',
				CANCEL: '#B91E1E',
			}
		},
	},
	methods: {
		...mapMutations('resvInfo', ['SET_SIDEBAR_TYPE']),
		getData(bool) {
			this.loading = true
			if (!bool) {
				this.page.pageNo += 1
			} else {
				this.page.pageNo = 1
				this.messageList = []
				this.messageListTotalLen = 0
			}

			get_message_list({
				...this.page,
				isRead: this.isRead,
				noticeType: this.messageType,
				hotelId: this.userInfo.fkOrgId,
			})
				.then((res) => {
					this.loading = false
					this.page.pageNo = res.current
					this.messageListTotalLen = res.total
					this.messageList = this.messageList.concat(res.records)
					this.$Bus.$emit('refresh')
					console.log(res)
				})
				.catch((err) => {
					this.messageList = []
					this.messageListTotalLen = 0
					this.loading = false
				})
		},
		deleteMsg(id, index) {
			this.$confirm('此操作将删除该条消息, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
			})
				.then(() => {
					delete_message({
						hotelId: this.userInfo.fkOrgId,
						noticeId: id,
					})
						.then((res) => {
							console.log(res)
							this.$message.success('删除成功')
							this.messageList.splice(index, 1)
						})
						.catch((err) => {})
				})
				.catch(() => {})
		},
		readAll(id, index) {
			let noticeId = []
			if (id) {
				noticeId = id
			} else {
				noticeId = ''
			}
			read_message({
				hotelId: this.userInfo.fkOrgId,
				noticeId: noticeId,
			})
				.then((res) => {
					console.log(res)
					this.$message.success('已读成功')
					if (id) {
						this.messageList.splice(index, 1)
					} else {
						this.getData(true)
					}
					this.$Bus.$emit('refresh')
				})
				.catch((err) => {})
		},
		handleCloseMessage() {
			this.SET_SIDEBAR_TYPE('idle')
		},
		clickSelect(val) {
			this.show = val === 1 ? true : false
		},
	},
}
</script>

<style scoped lang="scss">
.section {
	display: flex;
	flex-direction: column;
	overflow: auto;
	height: 100%;
	.close,
	.close-table {
		position: absolute;
		height: 40px;
		width: 40px;
		top: 0;
		cursor: pointer;
		right: 0;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.header {
		height: 40px;
		border-bottom: 1px solid rgba(51, 51, 51, 0.1);
		flex: 0 0 40px;
		padding: 0 18px;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		position: relative;
		font-size: 16px;
		color: #000;

		.title {
			color: #333;
		}
	}
	.top-box {
		padding: 10px 0;
		.t1 {
			flex: 0 0 60%;
			margin-left: 5px;
		}
		.t2 {
			color: #666666;
			font-size: 13px;
			cursor: pointer;
			text-align: center;
			padding: 5px 12px;
		}
		:deep(.el-select) {
			width: 50%;
			.el-input {
				input {
					border-width: 0;
				}
			}
		}
		.active {
			position: relative;
			:deep(.el-input__inner) {
				color: #409eff;
				position: relative;
			}
			:deep(.el-input .el-select__caret) {
				color: #409eff;
			}
			&:after {
				content: '';
				display: block;
				position: absolute;
				bottom: -7px;
				height: 2px;
				width: 75%;
				background: #409eff;
				left: 50%;
				transform: translateX(-50%);
			}
		}
	}
	.main {
		width: 100%;
		flex: 1 1 auto;
		position: relative;
		padding: 0 10px;
		box-sizing: border-box;
		display: flex;
		overflow: auto;
		flex-direction: column;
		.message-item {
			border-radius: 8px;
			margin: 4px 0;
			border-bottom: 1px solid #f1f1f1;
			.content {
				display: flex;
				padding: 5px;
				overflow: hidden;
				flex-wrap: nowrap;
				position: relative;
				flex-direction: column;
				color: #212121;
				.change {
					background: #f6f6f6;
					border-radius: 4px;
					color: #a4a4a4;
				}
				.change-title {
					color: #333333;
					display: flex;
					align-items: center;
				}
				.customer-name {
					font-size: 16px;
					font-weight: bold;
				}
				.multi-table {
					position: absolute;
					top: -23px;
					right: -23px;
					width: 50px;
					height: 50px;
					border-radius: 50%;
				}
				.multi-table-icon {
					position: absolute;
					top: 7px;
					right: 4px;
				}
				.customer-info {
					font-size: 14px;
				}
			}
			.bottom {
				display: flex;
				flex-wrap: nowrap;
				justify-content: space-between;
				font-size: 12px;
				margin-left: 7px;
				margin-bottom: 10px;
				margin-top: 10px;
				color: #a4a4a4;
			}
		}
	}
}
</style>
