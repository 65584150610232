import { numberMap } from '@/const'

export function deepClone(source) {
	if (!source && typeof source !== 'object') {
		throw new Error('error arguments', 'deepClone')
	}
	const targetObj = source.constructor === Array ? [] : {}
	Object.keys(source).forEach((keys) => {
		if (source[keys] && typeof source[keys] === 'object') {
			targetObj[keys] = deepClone(source[keys])
		} else {
			targetObj[keys] = source[keys]
		}
	})
	return targetObj
}

export function getUrlKey(name, url) {
	return (
		decodeURIComponent(
			(new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(url) || [
				null,
				'',
			])[1].replace(/\+/g, '%20')
		) || null
	)
}

export function convertHexToRGB(color) {
	if (color.length === 4) {
		let extendedColor = '#'
		for (let i = 1; i < color.length; i++) {
			extendedColor += color.charAt(i) + color.charAt(i)
		}
		color = extendedColor
	}
	const values = {
		r: parseInt(color.substr(1, 2), 16),
		g: parseInt(color.substr(3, 2), 16),
		b: parseInt(color.substr(5, 2), 16),
	}
	return `${values.r}, ${values.g}, ${values.b}`
}

export function debounce(fn, wait = 300) {
	if (window.timeout) clearTimeout(window.timeout)
	window.timeout = setTimeout(() => {
		fn()
	}, wait)
}

export function dataURLtoFile(dataurl, filename) {
	let arr = dataurl.split(','),
		mime = arr[0].match(/:(.*?);/)[1],
		bstr = window.atob(arr[1]),
		n = bstr.length,
		u8arr = new Uint8Array(n)
	let a = '.' + mime.split('/')[1]
	while (n--) {
		u8arr[n] = bstr.charCodeAt(n)
	}
	return new File([u8arr], filename + a, { type: mime })
}

export function requireDecimalDigit(val, len = 2) {
	// 保留小数位数
	if (val) {
	  val = val.replace(/[^\d.]/g, '')
	  const i = val.split('.')[0]
	  const d = val.split('.')[1]
	  if (d && d.length > len) {
		const l = i.length + 1 + len
		val = val.slice(0, l)
	  }
	}
	return (val && val < 1 && val != 0) ? Number(val) : val
  }

// 数字转中文
export function numberToChinese(number) {
  return numberMap[number]
}
