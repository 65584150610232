<template>
	<div class="section">
		<div class="header">
			{{ tableList.length ? '桌位详情' : '未选择桌位' }}
			<div @click="handleCloseIdle" class="close"></div>
		</div>
		<div class="main">
			<div class="table-list" v-if="tableList.length">
				<div class="table-list-item" v-for="t in tableList" :key="t.id">
					<div class="arrow-left"></div>
					<div class="arrow-middle flex ">
						<div class="flex flex-1 flex-center ">
                            <div class="dot mr-10"></div>
							<div class="table-name">
								{{ t.areaName ? t.areaName + '-' : ''}}{{ t.tableName }}
							</div>
                            <div class="dot ml-10"></div>
	
						</div>
						<div class="flex flex-align-center">
							<span @click="closeSelectTable(t)" class="close-table">
								<i class="el-icon-close"></i>
							</span>
						</div>
					</div>
					<div class="arrow-right"></div>
				</div>
			</div>
			<div v-else class="noChooseTable">
				<img src="../../../../assets/img/default.png" alt="" width="70%" />
				<span class="text-center mt-20">选择桌位后可进行如下操作</span>
			</div>
            <div class="line" v-if="tableList.length"></div>
		</div>
	</div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex'
import { tableSub } from '@/config/table'

export default {
	name: 'idle-detail',
	computed: {
		...mapState('resvInfo', ['selectedTables']),

		tableList() {
			return this.selectedTables.map((v) => v.table)
		},
	},
	methods: {
		...mapMutations('resvInfo', ['SET_SIDEBAR_TYPE']),
		handleCloseIdle() {
			tableSub.selectTable([])
			this.SET_SIDEBAR_TYPE('idle')
		},
		closeSelectTable(t) {
			tableSub.selectTable(t.id)
		},
	},
}
</script>

<style scoped lang="scss">
.section {
	display: flex;
	flex-direction: column;
	overflow: hidden;
	height: 100%;
	.close,
	.close-table {
		position: absolute;
		height: 40px;
		top: 0;
		cursor: pointer;
		right: 0;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.header {
		height: 40px;
		border-bottom: 1px solid rgba(51, 51, 51, 0.1);
		flex: 0 0 40px;
		padding: 0 18px;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		position: relative;
		color: #333;
		font-size: 16px;
	}
	.main {
		flex: 1 1 auto;
		position: relative;
		padding: 14px 0;
		box-sizing: border-box;
		display: flex;
		overflow: auto;
        flex-direction: column;
		.table-list {
			&::-webkit-scrollbar {
				display: none; /* Chrome Safari */
			}
			padding-bottom: 10px;
			flex: 1 1 auto;
			overflow: auto;

			.table-list-item {
				height: 40px;
				width: 100%;
				border-radius: 4px;
				margin-bottom: 12px;
				position: relative;
				line-height: 40px;
				color: #333;
				font-size: 13px;
				display: flex;
				.arrow-left {
					width: 0px;
					height: 0px;
					border-top: 20px solid transparent;
					border-right: 13px solid rgba(51, 51, 51, 0.05);
					border-bottom: 20px solid transparent;
					border-left: 20px solid transparent;
					position: relative;
					/* 利用滤镜filter，添加投影 */
					filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.05));
				}

				.arrow-middle {
					width: 100%;
                    
					background: rgba(51, 51, 51, 0.05);
					position: relative;
					z-index: 1;
                    color: #666666;
					text-align: center;
                    padding-left: 5px;
                    padding-right: 5px;
					.span {
						margin-right: 5px;
					}

				}

				.arrow-right {
					width: 0px;
					height: 0px;
					/* margin: 50px auto; */
					border-top: 20px solid transparent;
					border-right: 20px solid transparent;
					border-bottom: 20px solid transparent;
					border-left: 13px solid rgba(51, 51, 51, 0.05);
					/* 利用滤镜filter，添加投影 */
					filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.05));
				}
			}
		}
		.noChooseTable {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			height: 100%;
		}
        .line{
            width: 100%;
            height: 8px;
            background: #F6F6F6;
        }
	}
}
</style>
