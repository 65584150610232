<template>
	<div class="page-container">
		<div class="header" ref="header">
			<div class="left">
				<div class="logo">
					<img :src="hotelLogo" alt="" v-if="hotelInfo.hotelLogo" />
					<img src="../../assets/icons/logo.png" alt="" v-else />
				</div>
				<div class="hotel_name">
					{{ hotelName }}
				</div>
			</div>
			<div class="middle flex-1">
				<el-button
					slot="reference"
					size="small"
					class="mr-10"
					@click="changeMealDate(-1)"
				>
					<i class="el-icon-arrow-left"></i>
				</el-button>
				<calendar-picker
					style="width: 280px"
					:current="mealTypeDate"
					@change="handleChangeDate"
					:createTime="hotelInfo.createTime"
				></calendar-picker>
				<el-button
					slot="reference"
					size="small"
					class="ml-10"
					@click="changeMealDate(1)"
				>
					<i class="el-icon-arrow-right"></i>
				</el-button>
				<el-button
					slot="reference"
					size="small"
					class="ml-10"
					@click="changeMealDate(0)"
				>
					今
				</el-button>
				<div class="flex" v-if="!minScreen || (minScreen && !collapseHeader)">
					<el-select
						class="ml-5 header-select"
						:value="mealTypeId"
						size="small"
						@change="handleChangeMeal"
					>
						<el-option
							v-for="o in mealTypeList"
							:key="o.mealTypeId"
							:label="o.mealTypeName"
							:value="o.mealTypeId"
						></el-option>
					</el-select>
					<el-select
						class="ml-5 header-select"
						:value="tableAreaId"
						size="small"
						multiple
						collapse-tags
						clearable
						placeholder="全部区域"
						@change="handleChangeArea"
					>
						<el-option
							v-for="o in tableAreaList"
							:key="o.id"
							:label="o.areaName"
							:value="o.id"
						></el-option>
					</el-select>
					<el-select
						class="ml-5 header-select"
						:value="tableStatus"
						size="small"
						clearable
						placeholder="全部预订状态"
						@change="handleChangeStatus"
					>
						<el-option
							v-for="o in statusList"
							:key="o.status"
							:label="o.statusName"
							:value="o.status"
						></el-option>
					</el-select>
				</div>
				<el-button
					slot="reference"
					size="small"
					class="ml-10"
					v-if="minScreen"
					@click="showCollapse"
				>
					{{ collapseHeader ? '展开' : '收起' }}
				</el-button>
			</div>
			<div class="right" v-if="!minScreen || (minScreen && collapseHeader)">
				<el-tooltip placement="bottom" content="打印预订单">
					<div class="header-btn" @click=";(printVis = true), getHotelConfig()">
						<img src="../../assets/icons/header-print.png" alt="" width="18" />
					</div>
				</el-tooltip>
				<div
					class="header-btn"
					@click="refresh"
					v-loading.fullscreen.lock="fullscreenLoading"
				>
					<img src="../../assets/icons/header-refresh.png" alt="" width="18" />
				</div>
				<div
					class="header-btn message"
					:class="{ active: sideBarType == 'message' }"
					@click="showMessageList"
				>
					<el-badge :value="UnReadMessageNum">
						<img
							src="../../assets/icons/header-message.png"
							alt=""
							width="18"
							v-if="sideBarType != 'message'"
						/>
						<img
							src="../../assets/icons/header-message-active.png"
							alt=""
							width="18"
							v-else
						/>
					</el-badge>
				</div>
				<div
					class="header-btn order"
					:class="{ active: sideBarType == 'order' }"
					@click="showOrderList"
				>
					<img
						src="../../assets/icons/header-order.png"
						alt=""
						width="18"
						v-if="sideBarType != 'order'"
					/>
					<img
						src="../../assets/icons/header-order-active.png"
						alt=""
						width="18"
						v-else
					/>
					<span class="ml-5">订单</span>
				</div>
				<img :src="headImg" alt="" class="head-img" v-if="headImg" />
				<span v-else class="head-img">{{
					(userInfo.realName || '').slice(0, 1)
				}}</span>
				<el-dropdown @command="handleCommand" trigger="click">
					<span class="el-dropdown-link">
						{{ userInfo.realName
						}}<i class="el-icon-arrow-down el-icon--right"></i>
					</span>
					<el-dropdown-menu slot="dropdown">
						<el-dropdown-item command="logOut">退出登录</el-dropdown-item>
					</el-dropdown-menu>
				</el-dropdown>
			</div>
		</div>
		<div class="content">
			<div class="left">
				<div class="tab-container">
					<div
						class="tab-item"
						v-for="o in tableAreaFilterList"
						:key="o.id"
						@click="handleClickArea(o.id)"
						:class="{ 'tab-active': areaPosition == o.id }"
					>
						<span class="txt" :class="{ 'tab-active': areaPosition == o.id }">{{
							o.areaName
						}}</span>
					</div>
				</div>
			</div>
			<div class="middle">
				<div class="tab-statistic">
					<table-num-statistic></table-num-statistic>
				</div>
				<div class="table-container">
					<table-content
						ref="tableContent"
						@updateAreaPosition="handleUpdateAreaPosition"
						:clickArea="clickArea"
						@updateClickArea="handleUpateClickArea"
					></table-content>
				</div>
			</div>
			<div :class="{ 'right-2': minScreen, 'right-1': !minScreen }">
				<right-side-bar :minScreen="minScreen"></right-side-bar>
			</div>
		</div>
		<el-dialog title="打印预订单" :visible.sync="printVis" width="30%">
			<div class="flex flex-between">
				<span>打印客户标签信息</span>
				<el-switch
					v-model="print.customerInfo"
					active-value="1"
					inactive-value="0"
				></el-switch>
			</div>
			<div class="flex flex-between mt-20">
				<span>隐藏客户手机号码</span>
				<el-switch
					v-model="print.customerPhone"
					active-value="1"
					inactive-value="0"
				>
				</el-switch>
			</div>
			<span slot="footer" class="dialog-footer flex flex-center width-100">
				<el-button @click="printVis = false" class="mr-20">取 消</el-button>
				<el-button type="primary" @click="showPrintPreview()" class="ml-20"
					>确 定</el-button
				>
			</span>
		</el-dialog>
	</div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex'
import { setData } from '@/api/common'
import { save_login_logs } from '@/api/log'
import { setStorage } from '@/utils/storage'
import calendarPicker from '@/components/calendar'
import tableContent from './components/table/tableArea'
import rightSideBar from './components/rightSideBar'
import {
	get_zero_config,
	post_updateHotelConfig,
	get_firstMealType,
	get_hotelConfigList,
} from '@/api/config'
import { orderStatusCode } from '../../const'
import tableNumStatistic from './components/tableNumStatistics'
import { tableSub } from '../../config/table'

export default {
	name: 'home',
	data() {
		return {
			printVis: false,
			print: {
				customerInfo: '0',
				customerPhone: '0',
				hideCustomerName: '0',
			},
			minScreen: false,
			collapseHeader: false,
			statusList: [
				{
					statusName: '空闲',
					status: -1,
				},
				{
					statusName: '预订',
					status: 1,
				},
				{
					statusName: '入座',
					status: 2,
				},
				{
					statusName: '锁台',
					status: 5,
				},
			],
			areaPosition: '',
			clickArea: '0', //用来标记是点击tab导致的滚动条滚动
		}
	},
	components: {
		calendarPicker,
		tableContent,
		rightSideBar,
		tableNumStatistic,
	},
	mounted() {
		const elementResizeDetectorMaker = require('element-resize-detector')
		let erd = elementResizeDetectorMaker()
		erd.listenTo(this.$refs.header, () => {
			this.$nextTick(function () {
				const width = this.$refs.header.offsetWidth
				console.log(width)
				if (width <= 1650) {
					this.minScreen = true
				} else {
					this.minScreen = false
				}
			})
		})
	},
	created() {
		this.getConfigs()
	},
	watch: {
		currentMeal() {
			if (this.currentMeal) {
				console.log('触发')
				this.SET_MEALTYPE_ID(this.currentMeal)
				this.$Bus.$emit('refresh')
			}
		},
	},
	computed: {
		...mapState(['fullscreenLoading']),
		...mapState(['userInfo']),
		...mapState('resvInfo', [
			'mealTypeDate',
			'mealTypeId',
			'sideBarType',
			'tableAreaId',
			'tableStatus',
			'UnReadMessageNum',
			'ordersNum',
		]),
		...mapGetters('resvInfo', [
			'currentMeal',
			'mealTypeList',
			'tableAreaList',
			'areaOrderMap',
			'mealIdMap',
			'tableAreaMap',
		]),
		hotelInfo() {
			return this.userInfo.hotelInfo || {}
		},
		hotelName() {
			return this.hotelInfo.hotelShortName
				? this.hotelInfo.hotelShortName
				: this.hotelInfo.hotelName
		},
		hotelLogo() {
			return this.hotelInfo.hotelLogo
				? this.hotelInfo.hotelLogo
				: '../../assets/icons/logo.png'
		},
		headImg() {
			return this.userInfo.headImage ? this.userInfo.headImage : ''
		},
		tableAreaFilterList() {
			return this.tableAreaList.filter((o) => {
				console.log('areaOrderMap', this.areaOrderMap[o.id])
				let flag = true
				if (this.tableAreaId.length > 0 && !this.tableAreaId.includes(o.id)) {
					flag = false
				}
				if (this.tableStatus) {
					if (!this.areaOrderMap[o.id]) {
						flag = false
					}
				}
				return flag
			})
		},
	},
	methods: {
		...mapMutations('resvInfo', [
			'SET_MEALTYPE_DATE',
			'SET_FIRST_MEALTYPE',
			'SET_ZERO_HOTEL_OTHER_CONFIG',
			'SET_MEALTYPE_ID',
			'SET_MEALTYPE_LIST',
			'SET_RESVTYPE_LIST',
			'SET_TABLEAREA_LIST',
			'SET_TABLE_LIST',
			'SET_TABLEAREA_ID',
			'SET_TABLE_STATUS',
			'SET_SIDEBAR_TYPE',
		]),
		...mapMutations(['SET_FULLSCREEN_LOADING', 'SET_IFRAME_URL']),
		init() {
			this.SET_MEALTYPE_DATE(this.$moment().format('YYYY-MM-DD'))
			if (this.tableAreaList.length > 0) {
				this.areaPosition = this.tableAreaList[0].id
			}
			if (this.currentMeal) {
				this.SET_MEALTYPE_ID(this.currentMeal)
			}
			this.$Bus.$emit('refresh')
		},

		handleClickArea(id) {
			this.$nextTick(() => {
				this.clickArea = id
				const selector = '#area-' + id
				document.querySelector(selector).scrollIntoView({
					behavior: 'smooth',
					block: 'nearest',
					inline: 'start',
				})
			})
		},
		showOrderList() {
			tableSub.selectTable([])
			if (this.sideBarType == 'order') {
				this.SET_SIDEBAR_TYPE('idle')
			} else {
				this.SET_SIDEBAR_TYPE('order')
			}
		},
		showMessageList() {
			if (this.sideBarType == 'message') {
				this.SET_SIDEBAR_TYPE('idle')
			} else {
				this.SET_SIDEBAR_TYPE('message')
			}
		},
		//修改酒店通用配置
		updateHotelConfig() {
			post_updateHotelConfig({
				hotelId: this.userInfo.fkOrgId,
				hotelConfigList: [
					{
						k: 'ZERO_PRINT_CUSTOMER_INFO',
						v: this.print.customerInfo,
					},
					{
						k: 'ZERO_PRINT_CUSTOMER_PHONE',
						v: this.print.customerPhone,
					},
				],
			}).then(() => {
				this.$message({
					message: '修改成功',
					type: 'success',
				})
			})
		},
		getConfigs() {
			console.log('userinfo', this.userInfo)
			get_zero_config({
				hotelId: this.userInfo.fkOrgId,
			}).then((res) => {
				console.log('rrr', res)
				this.SET_MEALTYPE_LIST(res.mealTypes)
				this.SET_RESVTYPE_LIST(res.resvTypes)
				this.SET_TABLEAREA_LIST(res.tableAreas)
				this.SET_TABLE_LIST(res.tables)
				this.init()
			})
			get_firstMealType({
				hotelId: this.userInfo.fkOrgId,
			}).then((res) => {
				console.log(res, res.v)
				this.SET_FIRST_MEALTYPE(res.v || '00:00')
			})
			this.getHotelConfig()
		},
		getHotelConfig() {
			get_hotelConfigList({
				hotelId: this.userInfo.fkOrgId,
				configKeys:
					'ZERO_ARRIVE_TIME_CONFIG,ZERO_OTHER_CONFIG_TABLE_CUSTOMER_NAME,ZERO_PRINT_CUSTOMER_INFO,ZERO_PRINT_CUSTOMER_PHONE',
			}).then((res) => {
				console.log('获取酒店配置', res)
				const list = res || []
				list.map((item) => {
					if (item.k == 'ZERO_PRINT_CUSTOMER_INFO') {
						this.print.customerInfo = item.v
					}
					if (item.k == 'ZERO_PRINT_CUSTOMER_PHONE') {
						this.print.customerPhone = item.v
					}
					if (item.k == 'ZERO_OTHER_CONFIG_TABLE_CUSTOMER_NAME') {
						this.print.hideCustomerName = item.v
					}
				})
				this.SET_ZERO_HOTEL_OTHER_CONFIG(list)
			})
		},
		handleChangeMeal(val) {
			console.log(val)
			this.resetSideBar()
			this.SET_MEALTYPE_ID(val)
			this.$Bus.$emit('refresh')
		},
		handleChangeArea(val) {
			this.resetSideBar()
			console.log('vall', val)

			this.SET_TABLEAREA_ID(val)
		},
		handleChangeStatus(val) {
			this.resetSideBar()
			console.log(val)
			this.SET_TABLE_STATUS(val)
		},
		changeMealDate(val) {
			this.resetSideBar()
			if (val == 0) {
				this.SET_MEALTYPE_DATE(
					this.$moment().add(parseInt(val), 'd').format('YYYY-MM-DD')
				)
			} else {
				this.SET_MEALTYPE_DATE(
					this.$moment(this.mealTypeDate)
						.add(parseInt(val), 'd')
						.format('YYYY-MM-DD')
				)
			}
			this.$Bus.$emit('refresh')
		},
		handleCommand(command) {
			if (command == 'logOut') {
				this.logOut()
			}
		},
		showCollapse() {
			this.collapseHeader = !this.collapseHeader
		},
		logOut() {
			save_login_logs({
				clientType: 'ZERO_PAD',
				loginType: 2,
				hotelId: this.userInfo.fkOrgId,
				username: this.userInfo.username,
			})
			this.$emit('wsClose')
			setStorage('token', '')
			setStorage('oHotelId', '')
			setStorage('hotelId', '')
			setStorage('userInfo', {})

			this.$router.push({
				name: 'login',
			})
		},
		handleChangeDate(val) {
			console.log(val)
			this.resetSideBar()
			this.SET_MEALTYPE_DATE(val.date)
			this.$Bus.$emit('refresh')
		},
		resetSideBar() {
			console.log('sideBarType', this.sideBarType)
			if (this.sideBarType == 'table-change') {
				return
			} else if (this.sideBarType == 'table-add') {
				tableSub.cancelChangeTable()
			}
			this.SET_SIDEBAR_TYPE('idle')
		},
		refresh() {
			this.$Bus.$emit('refresh')
			this.SET_FULLSCREEN_LOADING(true)
		},
		handleUpdateAreaPosition(id) {
			this.areaPosition = id
		},
		handleUpateClickArea(val) {
			console.log('bool', val)
			this.clickArea = val
		},
		async showPrintPreview() {
			console.log('print', this.ordersNum, tableSub.orders)
			const key = 'zero-resv-ui_' + new Date().getTime()
			const url = 'https://' + location.host

			const meal = this.mealTypeList.find(
				(meal) => meal.mealTypeId == this.mealTypeId
			)
			let mealTypeName = ''
			if (meal) {
				mealTypeName = meal.mealTypeName
			}
			let orderList = []
			//订单按区域 桌位顺序进行排序
			this.tableAreaList.map((area) => {
				const tableList = this.areaOrderMap[area.id]
				tableList.map((t) => {
					tableSub.orders.map((order) => {
						if (
							area.id == order.orderTables[0].tableAreaId &&
							t.id == order.orderTables[0].tableId &&
							[orderStatusCode.RESERVE, orderStatusCode.SET_IN].includes(
								order.orderTables[0].orderStatus
							)
						) {
							orderList.push(order)
						}
					})
				})
			})

			console.log('order', orderList)
			if (!orderList || (orderList && orderList.length < 1)) {
				return this.$message.error('暂无可打印的预订单')
			}

			await this.updateHotelConfig()
      const value = JSON.stringify({
        orderList: orderList,
        mealTypeDate: this.mealTypeDate,
        mealTypeName: mealTypeName,
        ...this.print,
      })
			const res = await setData({
				key,
				value,
				timeOut: 300,
			})
			const time = new Date().getTime()
			console.log('url', `${url}/resvPrint.html?key=${key}&time=${time}`)
			this.SET_IFRAME_URL(`${url}/resvPrint.html?key=${key}&time=${time}`)
			this.printVis = false
			this.$nextTick(() => {
				const iframe = document.getElementById('iframe')
				if (iframe) {
					iframe.onload = function () {
						console.log('iframe加载完成，延迟1s让内部网页渲染完成')
						setTimeout(() => {
							iframe.contentWindow.print()
						}, 1000)
					}
				}
			})
		},
	},
}
</script>

<style lang="scss" scoped>
.page-container {
	background: rgb(245, 246, 250);
	width: 100vw;
	height: 100vh;
	overflow: hidden;
	display: flex;
	flex-direction: column;
}
.header {
	height: 60px;
	min-height: 60px;
	max-height: 60px;
	background: #fff;
	align-items: center;
	display: flex;
	justify-content: space-between;
	padding: 0 24px;
	box-shadow: 0px 0px 40px 0px rgba(26, 26, 26, 0.06);
	z-index: 2;
	.left {
		display: flex;
		align-items: center;
		min-width: 185px;
		.hotel_name {
			margin-left: 8px;
			font-size: 16px;
			font-weight: bold;
			margin-top: -2px;
		}
		.logo {
			width: 26px;
			height: 26px;
			display: flex;
			justify-content: center;
			align-items: center;

			img {
				width: 100%;
				border-radius: 5px;
			}
		}
	}
	.middle {
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 0 24px;
		.header-select {
			min-width: 90px;
			max-width: 180px;
			margin-left: 15px;
		}
	}
	.right {
		display: flex;
		align-items: center;
		min-width: 300px;
		.header-btn {
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 50%;
			background: #f4f4f4;
			margin-right: 18px;
			font-size: 12px;
			padding: 6px;
			color: #888888;
			cursor: pointer;
		}
		.message {
			:deep(.el-badge__content.is-fixed) {
				top: -2px;
				right: 5px;
			}
		}

		.active {
			background: #409eff;
			color: #fff;
		}
		.order {
			border-radius: 15px !important;
			padding: 6px 15px;
		}
		.head-img {
			width: 30px;
			height: 30px;
			border-radius: 50%;
			margin-right: 7px;
			background: #dfe8f9;
			overflow: hidden;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 12px;
			color: #487ad5;
		}
		.el-dropdown-link {
			cursor: pointer;
			font-size: 12px;
		}
		.el-icon-arrow-down {
			font-size: 12px;
		}
	}
}
.content {
	width: 100%;
	height: 100%;
	overflow: hidden;
	display: flex;
	flex: 1;

	.left {
		margin-top: 16px;
		padding: 15px 5px;
		width: 78px;
		min-width: 78px;
		max-width: 78px;

		margin-right: 24px;
		background: #fff;
		border-top-right-radius: 5px;
		border-bottom-right-radius: 5px;

		.tab-container {
			border-left: 1px solid #eee;
			.tab-item {
				width: 75px;
				height: 60px;
				display: flex;
				align-items: center;
				white-space: pre-line;
				word-break: break-all;
				line-height: 14px;
				cursor: pointer;
				.txt {
					padding: 0px 5px;
					border-left: 1px solid transparent;
				}
			}
			.tab-active {
				color: #409eff !important;
				border-color: #409eff !important;
			}
		}
	}

	.middle {
		margin-top: 16px;
		flex: 1;
		display: flex;
		flex-direction: column;
		.tab-statistic {
			width: 100%;
			// flex: 1;
			// max-height: 45px;
		}
		.table-container {
			width: 100%;
			overflow: auto;
			::-webkit-scrollbar {
				/*滚动条整体样式*/
				width: 0px !important;
				/*高宽分别对应横竖滚动条的尺寸*/
				height: 0px !important;
			}
		}
	}
	.right-1 {
		width: 398px;
		min-width: 398px;
		max-width: 398px;
		background: #fff;
		box-shadow: 0px 6px 40px 0px rgba(26, 26, 26, 0.1);
		position: relative;
	}
	.right-2 {
		width: 340px;
		min-width: 340px;
		max-width: 340px;
		background: #fff;
		box-shadow: 0px 6px 40px 0px rgba(26, 26, 26, 0.1);
		position: relative;
	}
}
</style>
