<template>
	<div class="login-page">
		<div class="login-div flex">
			<div class="login-left"></div>
			<div class="login-form flex-1">
				<div class="title" :style="{ 'font-weight': showRePwd ? '' : 'bold' }">
					宴荟佳零点预订台
				</div>
				<div v-if="!showRePwd">
					<div class="item" style="margin-top: 50px">
						<el-input v-model="username" placeholder="请输入登录账号">
							<span slot="prefix" class="icon-username"></span>
						</el-input>
					</div>
					<div class="item">
						<el-input
							:type="see[1] ? 'text' : 'password'"
							v-model="password"
							placeholder="请输入密码"
							@keyup.enter.native="handleLogin"
						>
							<span slot="prefix" class="icon-password"></span>
							<span
								slot="suffix"
								:class="see[1] ? 'icon-see' : 'icon-seeun'"
								@click="changeSee(1)"
							></span>
						</el-input>
					</div>
					<div class="item">
						<el-input
							v-model="code"
							placeholder="请输入验证码"
							@keyup.enter.native="handleLogin"
						>
							<span slot="prefix" class="icon-code"></span>
						</el-input>
						<div @click="freshCode" class="code-img">
							<img :src="codeSrc" width="100%" height="100%" alt="" />
						</div>
					</div>
					<div class="item" style="padding-left: calc(10% + 19px)">
						<el-checkbox v-model="rememberPwd">记住密码</el-checkbox>
					</div>
					<div class="item">
						<div class="login-msg" v-show="msg">
							<i class="el-icon-warning"></i> {{ msg }}
						</div>
						<el-button
							class="login-btn"
							type="primary"
							@click="handleLogin"
							:disabled="!btnValid"
							>登录</el-button
						>
					</div>
				</div>
				<!-- 首次登录 重置密码 -->
				<div v-else>
					<div class="sub-title">
						<i class="el-icon-arrow-right"></i> 重置密码
						<i class="el-icon-arrow-left"></i>
					</div>
					<div class="item" style="margin-top: 50px">
						<el-input
							:type="see[2] ? 'text' : 'password'"
							v-model="newPassword"
							placeholder="请输入新密码"
							:class="{ error: diff }"
						>
							<span slot="prefix" class="icon-password"></span>
							<span
								slot="suffix"
								:class="see[2] ? 'icon-see' : 'icon-seeun'"
								@click="changeSee(2)"
							></span>
						</el-input>
					</div>
					<div class="item">
						<el-input
							:type="see[3] ? 'text' : 'password'"
							v-model="newPassword2"
							placeholder="请确认新密码"
							@keyup.enter.native="handleUpdate"
							:class="{ error: diff }"
						>
							<span slot="prefix" class="icon-pwdcheck"></span>
							<span
								slot="suffix"
								:class="see[3] ? 'icon-see' : 'icon-seeun'"
								@click="changeSee(3)"
							></span>
						</el-input>
					</div>
					<div class="pwd-rule">
						<i class="el-icon-warning-outline"></i>
						密码需包含大写字母、小写字母、特殊符号、数字中的任意三项，密码长度为8-15位
					</div>
					<div class="item">
						<div class="login-msg" v-show="msg">
							<i class="el-icon-warning"></i> {{ msg }}
						</div>
						<div class="login-msg2" v-show="showRePwdDlg">
							<div class="success">
								<i class="el-icon-success"></i> 已成功重置密码！{{
									waitTime
								}}s后返回登录页
							</div>
						</div>
						<el-button
							class="login-btn"
							type="primary"
							@click="handleUpdate"
							:disabled="!btnValid"
							>确认</el-button
						>
					</div>
				</div>
			</div>
		</div>
		<div class="beian">
			<div>
				© 2016-2025 zhidianfan.com 版权所有
				<a href="https://beian.miit.gov.cn/" target="_blank" class="a-link"
					>浙ICP备16031560号-1</a
				>
			</div>
			<div style="margin-top: 5px">
				<img
					src="https://download-dy.oss-cn-hangzhou.aliyuncs.com/beian.png"
					style="
						width: 16px;
						margin-right: 5px;
						display: inline-block;
						vertical-align: -3px;
					"
				/><a
					href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33021202001232"
					target="_blank"
					class="a-link"
					>浙公网安备 33021202001232号</a
				>
			</div>
		</div>
	</div>
</template>

<script>
import { base_url } from '@/config'
import {
	user_login,
	update_pwd_by_token,
	login_with_hotelId,
} from '@/api/login'
import { getStorage, setStorage } from '@/utils/storage'
import { get_user_info } from '@/api/user'
import { getUrlKey } from '@/utils'
import { save_login_logs } from '@/api/log'
import { mapMutations, mapActions } from 'vuex'

export default {
	name: 'login',
	data() {
		return {
			username: '',
			password: '',
			newPassword: '',
			newPassword2: '',
			timeStamp: Date.now(),
			code: '',
			showRePwd: false,
			rememberPwd: false,
			timer: null,
			waitTime: 3,
			showRePwdDlg: false,
			msg: '',
			diff: false,
			see: {
				1: false,
				2: false,
				3: false,
			},
			oToken: '',
			oHotelId: '',
			token: '',
		}
	},
	computed: {
		codeSrc() {
			const src = base_url + '/sys/v1/getCaptcha?code_id=' + this.timeStamp
			return src
		},
		btnValid() {
			if (this.showRePwd) {
				return this.newPassword && this.newPassword2
			}
			return this.username && this.password && this.code
		},
	},
	mounted() {
		const token = getUrlKey('token', window.location.href)
		const hotelId = getUrlKey('hotelId', window.location.href)
		if (!hotelId) {
			this.SET_OHOTELID('')
		}
		if (token && hotelId) {
			this.oToken = token
			this.oHotelId = hotelId
			setStorage('hotelId', hotelId)
			this.loginWithHotelId()
		} else {
			this.username = getStorage('username') || ''
			this.password = getStorage('password') || ''
			this.rememberPwd = getStorage('rememberPwd') == 1 ? true : false
		}
		this.freshCode()
	},
	methods: {
		...mapMutations([
			'SET_OHOTELID',
			'SET_TOKEN',
			'SET_REFRESH_TOKEN',
			'SET_EXPTIME',
			'SET_USERINFO',
		]),
		...mapActions('websocket', ['WEBSOCKET_INIT']),
		changeSee(key) {
			this.see[key] = !this.see[key]
		},
		checkForm() {
			let arr = []
			if (this.showRePwd) {
				arr = [
					{
						key: 'newPassword',
						label: '新密码不能为空！',
					},
					{
						key: 'newPassword2',
						label: '确认密码不能为空！',
					},
				]
			} else {
				arr = [
					{
						key: 'username',
						label: '登录账号不能为空！',
					},
					{
						key: 'password',
						label: '登录密码不能为空！',
					},
					{
						key: 'code',
						label: '验证码不能为空！',
					},
				]
			}
			for (const v of arr) {
				const value = this[v.key]
				if (!value) {
					this.msg = v.label
					return false
				}
			}
			if (this.showRePwd) {
				if (this.newPassword !== this.newPassword2) {
					this.msg = '两次密码不一致！'
					this.diff = true
					return false
				}
			}
			return true
		},
		freshCode() {
			this.timeStamp = Date.now()
		},
		handleLogin() {
			this.msg = ''
			const res = this.checkForm()
			if (!res) {
				return
			}
			const d = {
				username: this.username,
				password: this.password,
				imgCode: this.code,
				clientType: 'ZERO_PAD',
			}
			this.SET_TOKEN('')
			user_login(d)
				.then((res) => {
					// console.log('login res', res);
					if (this.rememberPwd) {
						setStorage('username', this.username)
						setStorage('password', this.password)
						setStorage('rememberPwd', this.rememberPwd ? 1 : 0)
					} else {
						setStorage('username', '')
						setStorage('password', '')
						setStorage('rememberPwd', 0)
					}
					this.showRePwd = !res.hasChangePwdAfterFirstLogin
					this.token = res.token
					if (res.hasChangePwdAfterFirstLogin) {
						// 记录登录日志
						this.SET_TOKEN(res.token)
						this.SET_REFRESH_TOKEN(res.refreshToken)
						this.SET_EXPTIME(res.expTime)

						get_user_info().then((res) => {
							this.SET_USERINFO(res || {})
							save_login_logs({
								clientType: 'ZERO_PAD',
								loginType: 1,
								hotelId: res.fkOrgId,
								username: this.username,
							})
							
							this.$router.replace({
								name: 'home',
							})
						})
					} else {
						this.password = ''
						this.code = ''
						this.freshCode()
					}
				})
				.catch((err) => {
					// console.log('err', err);
					this.msg = err.msg || '请求错误'
					this.freshCode()
				})
		},
		handleUpdate() {
			this.msg = ''
			this.diff = false
			const res = this.checkForm()
			if (!res) {
				return
			}
			const d = {
				pwd: this.newPassword,
				clientType: 'ZERO_PAD', //宴会佳零点预订台
			}
			update_pwd_by_token(d, this.token)
				.then((res) => {
					console.log('updatePwd res', res)
					this.newPassword = ''
					this.newPassword2 = ''
					// 重置成功
					this.showRePwdDlg = true
					this.waitTime = 3
					clearInterval(this.timer)
					this.timer = null
					this.timer = setInterval(() => {
						this.waitTime = this.waitTime > 0 ? this.waitTime - 1 : 0
						// console.log(this.waitTime)
						if (this.waitTime == 0) {
							this.closeDlg()
						}
					}, 1000)
				})
				.catch((err) => {
					// console.log('err', err);
					this.msg = err.msg || '请求错误'
				})
		},
		closeDlg() {
			clearInterval(this.timer)
			this.timer = null
			this.showRePwd = false
			this.showRePwdDlg = false
			this.waitTime = 3
		},
		// 运维后台登录
		loginWithHotelId() {
			login_with_hotelId({
				token: this.oToken,
				hotelId: this.oHotelId,
			}).then((res) => {
				this.SET_OHOTELID(this.oHotelId)
				this.SET_TOKEN(res.token)
				this.SET_REFRESH_TOKEN(res.refreshToken)
				this.SET_EXPTIME(res.expTime)
				get_user_info({
					hotelId: this.oHotelId,
				}).then((res) => {
					this.SET_USERINFO(res || {})
					this.$router.replace({
						name: 'home',
					})
				})
			})
		},
	},
}
</script>

<style scoped lang="scss">
.login-page {
	height: 100vh;
	position: relative;
	background: url(../../assets/img/login-bg.png);
	background-size: cover;
	background-repeat: no-repeat;
	text-align: center;
	.login-div {
		z-index: 100;
		width: 80%;
		background: transparent;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		.login-left {
			width: 60%;
			min-width: 600px;
			height: 624px;
			background: #f8fbff url(../../assets/img/login-img.png);
			background-position: center center;
			background-size: 70%;
			background-repeat: no-repeat;
			border-radius: 20px 0 0 20px;
		}
		.login-form {
			background: #ffffff;
			border-radius: 0 20px 20px 0;
			min-width: 450px;
			.title {
				font-size: 32px;
				color: #4390ff;
				padding: 50px 0 0;
				letter-spacing: 6px;
			}
			.sub-title {
				font-size: 32px;
				font-weight: bold;
				color: #4390ff;
				padding: 28px 0 0;
				i {
					font-size: 20px;
					vertical-align: 3px;
					font-weight: bold;
				}
			}
			.item {
				margin-bottom: 20px;
				display: flex;
				align-items: center;
				min-width: 300px;
				margin: 0 auto 20px;
				padding: 0 10%;
				position: relative;
				::v-deep .error input.el-input__inner {
					border: 1px solid #f56c6c;
				}
				::v-deep .el-input__prefix {
					left: 15px;
					display: flex;
					align-items: center;
				}
				::v-deep .el-input__suffix {
					right: 15px;
					display: flex;
					align-items: center;
				}
				::v-deep input.el-input__inner {
					border: 1px solid #eeeeee;
					padding-left: 60px;
					padding-right: 60px;
					height: 50px;
					line-height: 50px;
					border-radius: 50px;
					&:focus {
						border: 1px solid #4390ff !important;
						& + .el-input__prefix .icon-username {
							background: url(../../assets/icons/username2.png) no-repeat center
								center/100% 100%;
						}
						& + .el-input__prefix .icon-password {
							background: url(../../assets/icons/password2.png) no-repeat center
								center/100% 100%;
						}
						& + .el-input__prefix .icon-code {
							background: url(../../assets/icons/code2.png) no-repeat center
								center/100% 100%;
						}
						& + .el-input__prefix .icon-pwdcheck {
							background: url(../../assets/icons/pwdcheck2.png) no-repeat center
								center/100% 100%;
						}
					}
				}
				.el-button--primary.is-disabled {
					background: #cccccc;
					border-color: #cccccc;
				}
				.code-img {
					width: 100px;
					height: 50px;
					margin-left: 35px;
					flex: 0 0 auto;
					cursor: pointer;
				}
				.login-msg {
					color: #f56c6c;
					font-size: 18px;
					position: absolute;
					width: 100%;
					top: 16px;
					left: 0;
				}
				.login-msg2 {
					color: #f56c6c;
					font-size: 18px;
					position: absolute;
					width: 100%;
					top: 8px;
					left: 0;
					.success {
						color: #67c23a;
						background-color: #f0f9eb;
						border: 1px solid #e1f3d8;
						border-radius: 4px;
						padding: 10px;
						display: inline-block;
						font-size: 16px;
					}
				}
			}
			.icon-username {
				display: block;
				width: 27px;
				height: 27px;
				background: url(../../assets/icons/username1.png) no-repeat center
					center/100% 100%;
			}
			.icon-password {
				display: block;
				width: 27px;
				height: 27px;
				background: url(../../assets/icons/password1.png) no-repeat center
					center/100% 100%;
			}
			.icon-code {
				display: block;
				width: 27px;
				height: 27px;
				background: url(../../assets/icons/code1.png) no-repeat center
					center/100% 100%;
			}
			.icon-pwdcheck {
				display: block;
				width: 27px;
				height: 27px;
				background: url(../../assets/icons/pwdcheck1.png) no-repeat center
					center/100% 100%;
			}
			.icon-see {
				display: block;
				width: 27px;
				height: 16px;
				background: url(../../assets/icons/see.png) no-repeat center center/100%
					100%;
			}
			.icon-seeun {
				display: block;
				width: 27px;
				height: 12px;
				background: url(../../assets/icons/see-un.png) no-repeat center
					center/100% 100%;
			}
		}
		.login-btn {
			width: 100%;
			height: 60px;
			border-radius: 60px;
			margin-top: 60px;
			font-size: 26px;
		}
		.pwd-rule {
			font-size: 12px;
			color: #ccc;
		}
	}
	.beian {
		font-size: 12px;
		text-align: center;
		color: #666;
		position: absolute;
		bottom: 20px;
		left: 50%;
		width: 400px;
		margin-left: -200px;
		a {
			color: #666;
			text-decoration: none;
		}
		a:hover {
			text-decoration: underline;
		}
	}
}
</style>
